<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  img.dialog-icon3(src="~@/assets/img/active/active_dialog_icon3.png")
  img.dialog-icon4(src="~@/assets/img/active/active_dialog_icon4.png")
  img.dialog-icon6(src="~@/assets/img/active/active_dialog_icon3.png")
  .dialog-small
    img.dialog-icon1(src="~@/assets/img/active/active_dialog_icon1.png")
    img.dialog-icon2(src="~@/assets/img/active/active_dialog_icon2.png")
    .dialog-icon-intro 您目前有{{surplus.small_surplus || 0}}面小红旗，可合成{{surplus.limit || 0}}面大红旗
    .dialog-icon5(@click="submitFlag")
      img(src="~@/assets/img/active/active_dialog_icon5.png")
</template>
<script>
import { flagSynthetic } from "@/api/components/active";
import _ from "lodash";
export default {
  props: ["show", "surplus"],
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function () {
      try {
        await flagSynthetic();
        this.$toast("领取成功");
        this.$emit("updataCount");
        this.$emit("close");
      } catch (err) {
        this.$emit("close");
      }
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;
  .dialog-small {
    position: relative;
    .dialog-icon1 {
      height: 250px;
      width: 273px;
      position: relative;
      z-index: 2;
      margin-left: 10px;
      animation: routeBag 10s linear infinite;
    }
    .dialog-icon2 {
      height: 85px;
      width: 281px;
      position: relative;
      z-index: 1;
      margin-top: -50px;
    }

    .dialog-icon5 {
      height: 57px;
      width: 197px;
      overflow: hidden;
      border: none;
      margin-top: 16px;
      position: relative;
      z-index: 2;
      margin-left: 40px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .dialog-icon-intro{
      color: #ffffff;
      margin-top: -40px;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: rgba(33,33,33,.3);
      border-radius: 15px;
      z-index: 2;
      text-align: center;
      position: relative;
    }
  }
  .dialog-icon3 {
    height: 67px;
    width: 67px;
    position: absolute;
    top: -20px;
    animation: bulingbuling 4s ease-in-out infinite;
  }
  .dialog-icon4 {
    height: 68px;
    width: 71px;
    position: absolute;
    right: -30px;
    animation: bulingbuling 4s ease-in-out 3s infinite;
  }
  .dialog-icon6 {
    height: 68px;
    width: 71px;
    position: absolute;
    right: 70px;
    top: -40px;
    animation: bulingbuling 4s ease-in-out 1s infinite;
  }
}
@keyframes bulingbuling {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes routeBag {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>