<template lang="pug">
.training-video-box(v-if="detail.video")
  .training-video-tab
    img(src="~@/assets/img/training/icon_3.png")
    .text 视频教学
  video(
    ref="media",
    :autoplay="autoplay",
    :controls="true",
    :src="detail.video",
    :poster="detail.pic"
    webkit-playsinline
  )
</template>
<script>
export default {
  data() {
    return {
      autoplay: false,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="less">
video {
  height: 148px;
  width: 361px;
  background: #333333;
  border-radius: 5px;
}
.training-video-tab {
  padding: 0 20px;
  line-height: 40px;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  .text {
    flex: 1;
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>