
import {
    get
} from '@/api/http'

export function memberClassDetail(params) {
    return get({
        url: "/member_class/details",
        params,
    })
}

export function memberClassRead(params) {
    return get({
        url: "/member_class/read",
        params,
    })
}

export function memberClassWirt(params) {
    return get({
        url: "/member_class/wirt",
        params,
    })
}