import a from '@/assets/img/jigsaw/a.png'
import b from '@/assets/img/jigsaw/b.png'
import c from '@/assets/img/jigsaw/c.png'
import d from '@/assets/img/jigsaw/d.png'
import e from '@/assets/img/jigsaw/e.png'
import f from '@/assets/img/jigsaw/f.png'
import g from '@/assets/img/jigsaw/g.png'
import h from '@/assets/img/jigsaw/h.png'
import i from '@/assets/img/jigsaw/i.png'
import a_ from '@/assets/img/jigsaw/a_.png'
import b_ from '@/assets/img/jigsaw/b_.png'
import c_ from '@/assets/img/jigsaw/c_.png'
import d_ from '@/assets/img/jigsaw/d_.png'
import e_ from '@/assets/img/jigsaw/e_.png'
import f_ from '@/assets/img/jigsaw/f_.png'
import g_ from '@/assets/img/jigsaw/g_.png'
import h_ from '@/assets/img/jigsaw/h_.png'
import i_ from '@/assets/img/jigsaw/i_.png'

export const jigsawList = [
    {
        name: 'a',
        icon: a,
        icon_: a_
    },
    {
        name: 'b',
        icon: b,
        icon_: b_
    },
    {
        name: 'c',
        icon: c,
        icon_: c_
    },
    {
        name: 'd',
        icon: d,
        icon_: d_
    },
    {
        name: 'e',
        icon: e,
        icon_: e_
    },
    {
        name: 'f',
        icon: f,
        icon_: f_
    },
    {
        name: 'g',
        icon: g,
        icon_: g_
    },
    {
        name: 'h',
        icon:h,
        icon_: h_
    },
    {
        name: 'i',
        icon: i,
        icon_: i_
    },
]




