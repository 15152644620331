<template>
  <div class="back_box">
    <div class="search_header">
    <mt-header title="邀请明细" >
      <router-link to="/invite_friends" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>
    </div>
    <vList :loading="loading" :isMore="isMore" :list="logList"></vList>
  </div>
</template>
<script>
import { getRecommendLog } from "@/api/components/recommend";
export default {
  data() {
    return {
      loading: false,
      isMore: "",
      logList: [],
    };
  },
  created() {
    this.handleList_search();
  },
  methods: {
    handleList_search() {
      this.loading = true;
      getRecommendLog()
        .then((res) => {
          this.loading = false;
          let { data } = res.data;
          var reg = /^(\d{3})\d*(\d{4})$/;
          this.logList = data;
          this.logList =
            this.logList &&
            this.logList.map((item) => {
              item.get_recommend_member.mobile = item.get_recommend_member.mobile.replace(
                reg,
                "$1****$2"
              );
              return item;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    vList: () => import("./list"),
  },
};
</script>

<style lang="less">
.search_header {
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  z-index: 999;
  height: 44px;
}
</style>
