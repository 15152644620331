<template lang="pug">
.intros.fadeInAnimate
  van-nav-bar(
    title="地标介绍",
    left-arrow,
    fixed,
    :border="false",
    @click-left="onClickLeft"
  )
  .intros-box
    img.image(:src="areaDatas[id].image")
    .intros-name {{ areaDatas[id].name }}
    .intros-content-rela
      .bottom-float
      .bottom-float-top
      .intros-content
        p(v-for="item in areaDatas[id].intro") {{ item }}
    van-button.btn(
      v-if="light",
      round,
      block,
      type="info",
      @click="lightArea",
      :disabled="disabled"
    ) 点亮
</template>
<script>
import { areaDatas } from "../data";
import { flagIllume } from "@/api/components/active";
import mixins from "../mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      areaDatas,
      disabled: false,
    };
  },
  computed: {
    id() {
      return Number(this.$route.query.id);
    },
    light() {
      return this.$route.query.light;
    },
  },
  methods: {
    async lightArea() {
      this.disabled = true;
      try {
        await flagIllume();
        this.$toast("点亮成功");
        this.disabled = false;
        this.$router.go(-1);
      } catch (err) {
        this.disabled = false;
      }
    },
  },
};
</script>
<style lang="less">
.intros {
  background: #f9b467;
  padding-top: 50px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  .btn {
    position: absolute;
    left: 50%;
    bottom: 30px;
    width: 130px;
    height: 40px;
    margin-left: -65px;
    background: linear-gradient(180deg, #fef5dd, #f8ce9a);
    border: none;
    color: #f15332;
    font-weight: bold;
  }
  .intros-content-rela {
    position: relative;
    .bottom-float {
      height: 60px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -1px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
      );
    }
    .bottom-float-top {
      height: 10px;
      width: 100%;
      position: absolute;
      left: 0;
      top: -1px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0)
      );
    }
  }
  .intros-content {
    height: 280px;
    padding-bottom: 20px;
    padding-top: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    overflow: scroll;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -10px;
    p {
      margin-top: 0;
    }
  }
  .intros-box {
    position: relative;
    background: url("~@/assets/img/active/active_intros_bg.png");
    background-size: 100% 100%;
    height: 670px;
    width: 362px;
    margin-left: 6.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    box-sizing: border-box;
    text-align: justify;
    .image {
      width: 100%;
      margin-bottom: 20px;
    }
    .intros-name {
      text-align: center;
      font-size: 14px;
      color: #55231d;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: #55231d;
      line-height: 20px;
    }
  }
}
</style>