import axios from "axios"
import qs from 'qs'
import {
  getToken,
  getUid
} from '@/libs/util' // get token from cookie

const service = axios.create({
  timeout: 60000,
})

import {
  Toast
} from 'mint-ui';

//请求拦截器
service.interceptors.request.use(config => {
  if (config.method == 'post') {
    config.headers['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";
    config.data = qs.stringify(config.data)
  } else {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  }
  config.headers['token'] = getToken('token');
  config.headers['vid'] = getUid("uid")
  return config;
}), err => {
  return Promise.reject(err)
}

//response 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.code == 10002) {
    return res;
  } else if (res.data.code == 10007) {
    return res;
  } else if (res.data.code == 10105) {
    return res
  } else {
    Toast({
      message: res.data.message
    })
    return Promise.reject()
    //return res;
  }
  //对响应数据做点什么
}), err => {
  return Promise.reject(err);
}
export default service