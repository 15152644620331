import {
    setToken,
    setUid,
    isIphone,
    isAndroid,
    callhandler
} from "@/libs/util";

export default {
    methods: {
        getToken() {
            return new Promise((resolve) => {
                // setToken("6ee8nyRdhRHKNPeBIQGu3tKXMEx579EmB%2B8BT14KSL0FwQ");
                // setUid("12312");
                // resolve()
                if (isIphone) {
                    callhandler("getToken", "", (data) => {
                        callhandler("getUserId", "", (uid) => {
                            this.uid = uid;
                            this.token = data;
                            setToken(this.token);
                            setUid(this.uid);
                            resolve()
                        });
                    });
                } else if (isAndroid) {
                    this.token = window.APP.getToken();
                    this.uid = window.APP.getUserId();
                    setToken(this.token);
                    setUid(this.uid);
                    resolve()
                }
            })


        },
        getDis(arr, i, base) {
            let data = {};
            if (arr[i - 1]) {
                data.dis = arr[i].task_num - arr[i - 1].task_num;
                data.task_num = base - arr[i - 1].task_num;
            } else {
                data.dis = arr[i].task_num;
                data.task_num = base;
            }
            return data;
        },
        getTaskNum(task_num, dis) {
            const r = task_num / dis;
            let data = 0;
            if (r > 0 && r < 1) {
                data = r * 100;
            } else if (r >= 1) {
                data = 100;
            }
            return data;
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}