<template lang="pug">
.tiger-detail
  .detail-box(
    v-for="(item, i) in tigerDetailData",
    :key="i",
    :class="'detail-icon-pos' + i"
  )
    img(:src="item.image")
    .name {{ item.name }}
  img.detail-pack(src="~@/assets/img/active/active_tiger_detail_icon0.png")
</template>
<script>
import { tigerDetailData } from "../../data";
export default {
  computed: {},
  data() {
    return {
      tigerDetailData,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.tiger-detail {
  position: relative;
  margin-top: 52px;
  background: url("~@/assets/img/active/active_tiger_detail.png");
  background-size: 100% 100%;
  width: 375px;
  height: 402px;
  .detail-box {
    width: 100px;
    text-align: center;
    position: absolute;
    img {
      width: 67px;
      height: 67px;
    }
    .name {
      color: #ffffff;
    }
  }

  .detail-icon-pos0 {
    left: 2px;
    bottom: 71px;
    animation: tiger-float 3s linear infinite;
  }
  .detail-icon-pos1 {
    left: 2px;
    bottom: 183px;
    animation: tiger-float 3s linear 2s infinite;
  }
  .detail-icon-pos2 {
    left: 90px;
    bottom: 256px;
    animation: tiger-float 3s linear 1s infinite;
  }
  .detail-icon-pos3 {
    right: 90px;
    bottom: 256px;
    animation: tiger-float 3s linear 1.2s infinite;
  }
  .detail-icon-pos4 {
    right: 7px;
    bottom: 183px;
    animation: tiger-float 3s linear .6s infinite;
  }
  .detail-icon-pos5 {
    right: 7px;
    bottom: 71px;
    animation: tiger-float 3s linear 1.3s infinite;
  }
  .detail-pack {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 192px;
    height: 206px;
  }
}

@keyframes tiger-float {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
