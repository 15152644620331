import {
    setToken,
    setUid,
    isIphone,
    isAndroid,
    callhandler
} from "@/libs/util";

export default {
    mounted() {
        //&nbsp;切换页面时滚动条自动滚动到顶部
        window.scrollTo(0,0);
    },
    methods: {
        getToken() {
            return new Promise((resolve) => {
                // setToken("cd1dyNkiwl5fL9wflnfEhiFUDn9eIUMDFtyww1EW%2F4lmjQ");
                // setUid("10136");
                // resolve()
                if (isIphone) {
                    callhandler("getToken", "", (data) => {
                        callhandler("getUserId", "", (uid) => {
                            this.uid = uid;
                            this.token = data;
                            setToken(this.token);
                            setUid(this.uid);
                            resolve()
                        });
                    });
                } else if (isAndroid) {
                    this.token = window.APP.getToken();
                    this.uid = window.APP.getUserId();
                    setToken(this.token);
                    setUid(this.uid);
                    resolve()
                }
            })


        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}