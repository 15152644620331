<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>


</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
@import "@/assets/less/common";
@import "@/assets/less/font";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  margin-top: 0;
}
body {
  padding: 0 0;
  margin: 0;
  width: 100vw;
  overflow: auto;
  background: #f2f2f2;
}
html,
ƒ p,
ul,
div,
h2,
h5,
li {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
</style>
