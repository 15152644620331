<template lang="pug">
.forms.fadeInAnimate
  van-nav-bar(
    title="V仔的十一旅行计划",
    left-arrow,
    fixed,
    :border="false",
    @click-left="onClickLeft"
  )
  .detail-box
    .title-box
      img.title-bg(src="~@/assets/img/active/active_rules_title.png")
      .title-name 规则信息
    div(style="margin-top: -10px")
      .detail-item.detail-item-strong 微博会员：需填写微博昵称，其他信息无需填写
      .detail-item.detail-item-strong 腾讯视频会员：需填写登录腾讯视频的QQ号，其他信息无需填写
      .detail-item.detail-item-strong 爱奇艺、芒果视频会员：需填写登录视频平台的手机账号，其他信息无需填写
      .detail-item.detail-item-strong 实物奖品：需填写收件姓名、手机号、收件地址，其他信息无需填写
      .detail-item.detail-item-strong *请确保信息填写正确，一旦确定发放不可修改更换
  van-form.forms-box(@submit="onSubmit")
    .title-box
      img.title-bg(src="~@/assets/img/active/active_rules_title.png")
      .title-name 备注信息
    van-field(
      v-model="form.name",
      label="收件姓名",
      placeholder="请输入收件姓名",
    )
    van-field(
      v-model="form.qq",
      label="QQ号",
      type="number",
      placeholder="请输入QQ号",
    )
    van-field(
      v-model="form.mobile",
      label="手机号",
      placeholder="请输入手机号",
    )
    van-field(
      v-model="form.nickname",
      label="微博昵称",
      placeholder="请输入微博昵称",
    )
    van-field(
      v-model="form.area",
      readonly,
      label="收货地址",
      is-link,
      @click="showPopup",
      placeholder="请选择收货地址"
    )
    van-field(
      v-model="form.addressContent",
      label="详细地址",
      rows="4",
      type="textarea",
      placeholder="请输入详细地址",
    )
    van-button.btn(round, block, type="info", native-type="submit") 保存
  van-popup(v-model="show", round, position="bottom")
    van-area(
      title="标题",
      :area-list="areaList",
      @cancel="close",
      @confirm="confirm"
    )
</template>
<script>
import { areaList } from "@vant/area-data";
import { flagRemarks } from "@/api/components/active";
import mixins from "../mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      areaList,
      show: false,
      form: {
        name: "",
        qq: "",
        mobile: "",
        nickname: "",
        area: "",
        addressContent: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.submitForm();
    },
    async submitForm() {
      this.form.address = this.form.area + this.form.addressContent;
      await flagRemarks(this.form);
      this.$toast("提交成功");
      this.onClickLeft()
    },
    close() {
      this.show = false;
    },
    confirm(e) {
      this.form.area = `${e[0].name}${e[1].name}${e[2].name}`;
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    validator(val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        val
      );
    },
  },
};
</script>
<style lang="less">
.forms {
  background: #f9b467;
  padding-bottom: 30px;
  padding-top: 70px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  .btn {
    position: absolute;
    left: 50%;
    bottom: 50px;
    width: 130px;
    height: 40px;
    margin-left: -65px;
    background: linear-gradient(180deg, #fef5dd, #f8ce9a);
    border: none;
    color: #f15332;
    font-weight: bold;
  }
  .van-field__error-message {
    padding-left: 5px;
  }
  textarea,
  input {
    padding-left: 5px;
  }
  textarea {
    border: 1px solid #fde5e0;
  }
  .van-field__label {
    color: #f15332;
    font-weight: bold;
  }
  .van-cell::after {
    border-bottom-color: #fde5e0;
  }
  /* CSS Working Draft */
  input::placeholder,
  textarea::placeholder {
    color: #fcd6ce;
  }
  .van-cell__right-icon {
    color: #f15332;
  }
  .detail-box {
    position: relative;
    background: url("~@/assets/img/active/active_forms_bg1.png");
    background-size: 100% 100%;
    width: 362px;
    height: 224px;
    margin-left: 6.5px;
    padding-left: 23px;
    padding-right: 23px;
    box-sizing: border-box;
    text-align: justify;
    .detail-item-strong {
      color: #55231d !important;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .detail-item {
      font-size: 14px;
      line-height: 20px;
      color: #55231d;
    }
  }
  .forms-box {
    position: relative;
    background: url("~@/assets/img/active/active_rules_gb.png");
    background-size: 100% 100%;
    height: 595px;
    width: 362px;
    margin-left: 6.5px;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    text-align: justify;
  }
  .title-box {
    position: relative;
    text-align: center;
    top: -20px;
    .title-name {
      color: #ffffff;
      font-size: 14px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      line-height: 40px;
    }
    .title-bg {
      width: 270px;
      height: 40px;
    }
  }
}
</style>