<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-small(:class="isShow?'animate':''")
    img.dialog-icon1(src="~@/assets/img/achievement/achievement_icon10.png")
    .dialog-icon5
      img(:src="icon5_big[level]")
    .dialog-icon2(@click="submitFlag")
      img(src="~@/assets/img/achievement/achievement_icon11.png")
    .dialog-icon3(@click="close")
      img(src="~@/assets/img/achievement/achievement_icon12.png")
    .dialog-icon4
      div 任务小将
      div 恭喜解锁徽章，获得{{rowData.integral || 0}}积分
</template>
<script>
import { growTaskDraw } from "@/api/components/achievement";
import {icon5_big } from "../../data";
import _ from "lodash";
export default {
  props: ["show", "rowData", 'level'],
  data() {
    return {
      icon5_big,
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function() {
      try {
        await growTaskDraw({
          id: this.rowData.id,
        });
        this.$toast("领取成功");
        this.$emit("updataCount");
        this.$emit("close");
      } catch (err) {
        this.$emit("close");
      }
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-small {
    position: relative;
    .dialog-icon1 {
      height: 401px;
      width: 375px;
      position: relative;
      z-index: 1;
    }
    .dialog-icon2 {
      height: 42px;
      width: 172px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 333px;
      margin-left: -86px;
      img {
        width: 100%;
      }
    }
    .dialog-icon3 {
      height: 30px;
      width: 30px;
      margin-top: 38px;
      margin-left: 172.5px;
      img {
        width: 100%;
      }
    }
    .dialog-icon4 {
      position: absolute;
      top: 225px;
      z-index: 2;
      width: 375px;
      text-align: center;
      div:first-child {
        font-size: 23px;
        margin-bottom: 16px;
        color: #333333;
        font-weight: bold;
      }
      div:last-child {
        font-size: 15px;
        color: #c1a985;
      }
    }
    .dialog-icon5 {
      position: absolute;
      top: 90px;
      z-index: 2;
      height: 110px;
      width: 100%;
      text-align: center;
      img {
        height: 100%;
      }
    }
  }
}
.animate {
  animation: flipInY 1s ease-in-out;
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>
