
import {
    get,
    post
} from '@/api/http'

/**
 * 推荐有奖
 * @param {*} params
 */
 export function getRecommendinfo(params) {
    return get({
        url: "/recommend/info",
        params,
    })
}


/**
 * 推荐排行
 * @param {*} params
 */
 export function getRanking(params) {
    return get({
        url: "/recommend/ranking",
        params,
    })
}



/**
 * 推荐登录
 * api/member/sms_login
 */


export function postSmsLogin(data) {
    return post({
        url: "/member/sms_login",
        data,
    })
}
/**
 * 获取验证码
 * api/member/get_sms
 */
export function postSms(data) {
    return post({
        url: "/member/get_sms",
        data,
    })
}
/**
 * 获取头像
 * api/recommend/info_by_code
 */
export function getInfoCode(params) {
    return get({
        url: "/recommend/info_by_code",
        params,
    })
}

/**
 * 推荐明细
 */


export function getRecommendLog(params) {
    return get({
        url: "/recommend/log",
        params,
    })
}





