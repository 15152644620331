import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    city_number: null
  },
  getters: {
    city_number: state => {
        return state.city_number
    }
  },
  mutations: {
    SET_CITY_NUM (state, val) {
      state.city_number = val
    }
  },
  actions: {
    updataCityNum ({ commit }, val) {
      commit('SET_CITY_NUM', val)
    }
  }
})
