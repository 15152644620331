<template lang="pug">
.training
  videoTeach.fadeInAnimate(ref="videoTeach", :detail="detail")
  textTeach.fadeInAnimate(ref="textTeach", :detail="detail")
  .count-down(v-if="count_time") 以上教程学习完后，才可进行操作（还剩{{ count_time }}秒）
  question.fadeInAnimate(
    v-if="detail.question.length",
    ref="question",
    :detail="detail",
    @submit="onSubmit"
  )
  task.fadeInAnimate(v-if="detail.task.length", ref="task", :detail="detail")
  modal(ref="modal", :detail="detail")
</template>
<script>
import {
  memberClassDetail,
  memberClassRead,
  memberClassWirt,
} from "@/api/components/training";
import {
  isIphone,
  isAndroid,
  setToken,
  setUid,
  callhandler,
} from "@/libs/util";
import task from "./modules/task.vue";
import videoTeach from "./modules/videoTeach.vue";
import textTeach from "./modules/textTeach.vue";
import question from "./modules/question.vue";
import modal from "./modules/modal.vue";
import { detail } from "./enum.js";
export default {
  name: "training",
  data() {
    return {
      id: this.$route.query.id | "",
      detail,
      count_time: 0,
    };
  },
  components: {
    task,
    videoTeach,
    textTeach,
    question,
    modal,
  },
  destroyed() {
    clearTimeout(this.times);
    clearInterval(this.times2);
  },
  created() {
    this.getTokenAndUid();
  },
  methods: {
    setBaseToken() {
      setToken("109cVLBps1yjl16kGGaIY4BcZbDhAVr9l6C%2B46kchVeo");
      setUid("2613");
      this.getData();
    },
    getTokenAndUid() {
      if (isIphone) {
        callhandler("getToken", "", (data) => {
          callhandler("getUserId", "", (uid) => {
            this.uid = uid;
            this.token = data;
            setToken(this.token);
            setUid(this.uid);
            if (!this.token || !this.uid) this.$toast("token或uid获取异常");
            else this.getData();
          });
        });
      } else if (isAndroid) {
        this.token = window.APP.getToken();
        this.uid = window.APP.getUserId();
        setToken(this.token);
        setUid(this.uid);
        if (!this.token || !this.uid) this.$toast("token或uid获取异常");
        else this.getData();
      }
    },
    async getData() {
      try {
        const res = await memberClassDetail({ id: this.id });
        const { data } = res.data;
        this.detail = Object.assign({}, this.detail, data);
        this.getDataThen();
        this.showItem();
      } catch (err) {
        console.log(err);
      }
    },
    getDataThen() {
      this.detail.status === 2 && this.startCountDown();
      (this.detail.status === 4 || this.detail.status === 1) &&
        this.setQuestion();
    },
    showItem() {
      this.$nextTick(() => {
        const name = "isShowText";
        if (this.detail.status === 2) this.$refs["videoTeach"].autoplay = true;
        if (!this.detail.video) this.$refs["textTeach"][name] = true;
        if (this.detail.status === 3) this.$refs["question"][name] = true;
        if (this.detail.status === 4) this.$refs["task"][name] = true;
      });
    },
    setQuestion() {
      const questions = this.detail.question;
      questions.forEach((item) => {
        if (item.answer.length === 1) item.submit = item.answer;
        else item.submit = item.answer.split("");
      });
    },
    startCountDown() {
      this.count_time = this.detail.study_time;
      this.times2 = setInterval(() => {
        this.count_time--;
        this.count_time === 0 && clearInterval(this.times2);
      }, 1000);
      this.times = setTimeout(async () => {
        await memberClassRead({ id: this.id });
        this.getData();
        this.$toast("阅读学习已完成，继续回答以下问题");
      }, this.detail.study_time * 1000);
    },
    async onSubmit() {
      const questions = this.detail.question;
      let params = {};
      params.id = this.id;
      params.questions = [];
      questions.forEach((item) => {
        let answer = [];
        const id = item.id;
        if (typeof item.submit === "string") answer = [item.submit];
        else answer = item.submit;
        params.questions.push({ id, answer });
      });
      params.questions = JSON.stringify(params.questions);
      await memberClassWirt(params);
      this.getData();
    },
  },
};
</script>
<style lang="less">
.training {
  background: #f5f5f5;
  min-height: 100vh;
  display: block;
  padding: 0 7px 20px;
  .count-down {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 20px;
    color: #666666;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeInAnimate {
  animation: fadeIn 0.5s;
}
</style>
