<template lang="pug">
.rules(class="fadeInAnimate")
  van-nav-bar(
    title="规则说明",
    left-arrow,
    fixed,
    :border="false",
    @click-left="onClickLeft"
  )
  .content-box
    .title-box
      img.title-bg(src="~@/assets/img/active/active_rules_title.png")
      .title-name V仔的十一旅行计划活动简介
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 活动对象
      .right-intro
        .nomal-item 所有正式小V
        .small-item （不含还未考核通过的小V）
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 活动时间
      .right-intro
        .strong-item 点亮旅行地图时间：
        .nomal-item 2021/10/01-2021/10/07
        .strong-item(style="margin-top: 10px") 神秘盲盒抽取时间：
        .nomal-item 2021/10/07-2021/10/08
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 活动介绍
      .right-intro
        .nomal-item V仔要出去旅行啦~它精心选择了全国7处旅游景点决定一一打卡，需要小V助力点亮地图帮V仔顺利完成旅行~小V每获得5面小红旗可合成1面大红旗，每一面大红旗都可点亮一个景点，7个景点全部点亮即助力成功，可抽取最终盲盒大奖！
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 奖励说明
      .right-intro
        .nomal-item 每合成一面大红旗可手动点亮一个景点，点亮同时会获得不同奖励；点亮7个景点可获得抽取盲盒大奖的机会
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 其他说明
      .right-intro
        .nomal-item 活动结束后，多余的小红旗会自动兑换为积分（5积分/旗）
    .rules-box
      .left-title
        img(src="~@/assets/img/active/active_rules_left.png")
        .left-name 红旗获取
      .right-intro
        .nomal-item 1. 完成任意任务达到15条可获1面小红旗（每日1次）
        .nomal-item 2. 完成微博直发任务达到2条可获1面小红旗（每日1次）
        .nomal-item 3. 每日签到可获得1面小红旗（每日1次）
        .nomal-item 4. 提交任务时有机会掉落小红旗（小tip：国庆期间晚间任务掉落概率翻倍！）
        .nomal-item.nomal-item-active *大红旗由小红旗合成，每5面小红旗可合成1面大红旗，大红旗兑换上限为7个
  .detail-box
    .title-box
      img.title-bg(src="~@/assets/img/active/active_rules_title.png")
      .title-name 地标奖励获得记录
    .detail-item(
      v-for="(item, index) in info",
      :key="index",
      :class="index < count ? 'detail-item-strong' : ''"
    ) {{ item }}
</template>
<script>
import mixins from '../mixins';
export default {
  mixins: [mixins],
  data() {
    return {
      count: null,
      info: [
        "恭喜点亮北京-八达岭长城，获得188积分",
        "恭喜点亮东方上海-明珠塔，获得0.6元现金红包",
        "恭喜点亮杭州-西湖，获得233积分",
        "恭喜点亮福建-土楼，获得任务加价卡-5次（0.2元/次）",
        "恭喜点亮广西-桂林，获得299积分",
        "恭喜点亮云南-玉龙雪山，获得系统封号-解封卡（适用于系统封号，不适用管理员封号）",
        "恭喜点亮西藏-布达拉宫，获得1.1元现金红包",
      ],
    };
  },
  mounted() {
    const city_number = Number(this.$route.query.city_number);
    if (city_number) this.count = city_number;
  },
  methods: {
  },
};
</script>
<style lang="less">
.rules {
  background: #f9b467;
  padding-bottom: 30px;
  padding-top: 70px;
  min-height: 100vh;
  box-sizing: border-box;
  .detail-box {
    position: relative;
    background: url("~@/assets/img/active/active_rules_gb2.png");
    background-size: 100% 100%;
    width: 362px;
    height: 389px;
    margin-left: 6.5px;
    margin-top: 30px;
    .detail-item-strong {
      color: #55231d !important;
      font-weight: bold;
    }
    .detail-item {
      font-size: 12px;
      line-height: 30px;
      margin: 10px 0;
      padding: 0 30px;
      color: #777777;
      text-align: center;
    }
  }
  .content-box {
    position: relative;
    background: url("~@/assets/img/active/active_rules_gb.png");
    background-size: 100% 100%;
    height: 814px;
    width: 362px;
    margin-left: 6.5px;
    .rules-box {
      display: flex;
      padding-left: 3px;
      margin-bottom: 35px;
      text-align: justify;
      .right-intro {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 23px;
        line-height: 20px;
        .nomal-item {
          font-size: 12px;
          color: #55231d;
        }
        .nomal-item-active {
          font-weight: bold;
        }
        .small-item {
          font-size: 10px;
          color: #55231d;
        }
        .strong-item {
          font-size: 12px;
          color: #55231d;
          font-weight: bold;
        }
      }
      .left-title {
        position: relative;
        .left-name {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          line-height: 34px;
        }
        img {
          height: 32px;
          width: 89px;
        }
      }
    }
  }
  .title-box {
    position: relative;
    text-align: center;
    top: -20px;
    .title-name {
      color: #ffffff;
      font-size: 14px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;
      line-height: 40px;
    }
    .title-bg {
      width: 270px;
      height: 40px;
    }
  }
}
</style>