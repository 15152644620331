<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-small4(:class="isShow ? 'animate' : ''")
    img.dialog-icon1(
      src="~@/assets/img/achievement2/achievement_point4_bg.png"
    )
    .dialog-icon2(@click="submitFlag")
      img(src="~@/assets/img/achievement2/achievement_point3_btn.png")
    .dialog-icon4
      .log3-title 恭喜获得
      .intro
        span {{point || 0}}
        span 积分
</template>
<script>
import _ from "lodash";
export default {
  props: ["show", "point"],
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function () {
      this.$emit("close");
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-small4 {
    position: relative;
    .dialog-icon1 {
      height: 390px;
      width: 331px;
      position: relative;
      z-index: 1;
    }
    .dialog-icon2 {
      height: 68px;
      width: 245px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 300px;
      margin-left: -122.5px;
      img {
        width: 100%;
      }
    }

    .dialog-icon4 {
      position: absolute;
      top: 150px;
      z-index: 2;
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
      .log3-title {
        margin-bottom: 45px;
      }
      .intro span:first-child {
        font-size: 42px;
      }
      .intro {
        font-size: 20px;
        span {
          margin: 0 2px;
        }
      }
    }
  }
}
.animate {
  animation: flipInY 1s ease-in-out;
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>
