<template>
  <div class="bill_box">
    <audio ref="video" class="media-audio" src="@/assets/cha.mp3"></audio>
    <!---第一个页面-->
    <div class="jiazai_box" v-if="barValue < 100">
      <div class="jiazai_block">
        <img src="~@/assets/img/annual_bill/img_jiazai_xingqiu.png" class="jiazai" />
      </div>
      <div class="jiazai_block">
        <mt-progress :value="barValue" :bar-height="5"></mt-progress>
      </div>
      <div class="div_block jiazai_block">账单生成中...({{ barValue }}%)</div>
    </div>
    <div v-if="barValue == 100">
      <div class="fixedPosition" v-if="!isHide">
        <div class="flex_center just_sp fixed_box">
          <div @click="handleBack">
            <img src="~@/assets/img/annual_bill/one/icon_back.png" class="left_wid" />
          </div>
        </div>
      </div>

      <swiper :options="swiperOptions" ref="mySwiper">
        <swiper-slide v-for="(item, index) in dataList" :key="index">
          <div style="overflow：hidden;" v-if="item.page == 1">
            <div :class="isHide ? 'page_1' : 'page_1 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
              </div>
              <div @click="play()" class="right_music">
                <img
                  src="~@/assets/img/annual_bill/one/icon_music.png"
                  :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                />
              </div>
              <div class="p1_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/one/img_dianzhui.png"
                  class="img_dianzhui"
                />
              </div>
              <div class="p1_xingqiu">
                <img
                  src="~@/assets/img/annual_bill/one/img_xingqiu.png"
                  class="img_xingqiu"
                />
              </div>
              <div class="p1_tou">
                <img src="~@/assets/img/annual_bill/one/img_tou.png" class="img_tou" />
              </div>
              <div class="p1_body">
                <img src="~@/assets/img/annual_bill/one/img_body.png" class="img_body" />
              </div>
              <div class="p1_pai">
                <img src="~@/assets/img/annual_bill/one/img_pai.png" class="img_pai" />
              </div>
              <div class="p1_qiqiu">
                <img
                  src="~@/assets/img/annual_bill/one/img_qiqiu.png"
                  class="img_qiqiu"
                />
              </div>
              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/one/img_middle.png"
                  class="img_middle"
                />
              </div>
              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div class="text">
                  <span class="font_red">{{ item.join_time }}</span
                  >你加入了通明小V,至今
                </div>
                <div class="text_bottom text">
                  我们已经携手走过了<span class="font_red">{{ item.join_day }}</span
                  >天~
                </div>
                <div
                  class="text_two"
                  style="margin: 0 auto; width: 90%; text-align: center"
                >
                  <div>
                    在2020年里，你一共成功执行了<span class="font_red">{{
                      item.success_num
                    }}</span
                    >条任务,
                  </div>
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 80%; text-align: center"
                >
                  获得过<span class="font_red">{{ item.reward_card }}</span
                  >张奖励卡券，一年搬砖辛苦了
                </div>
                <div
                  class="text_bottom text_three"
                  style="margin: 0 auto; width: 70%; text-align: center"
                >
                  接下来一起回顾下2020年你在小V之旅中做过哪些有趣的事吧！
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <div style="height: 100vh;overflow：hidden;" v-if="item.page == 7">
            <div :class="isHide ? 'page_1' : 'page_1 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>

              <div class="p1_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/one/img_dianzhui.png"
                  class="img_dianzhui"
                />
              </div>
              <div class="p1_xingqiu">
                <img
                  src="~@/assets/img/annual_bill/one/img_xingqiu.png"
                  class="img_xingqiu"
                />
              </div>
              <div class="p1_tou">
                <img src="~@/assets/img/annual_bill/one/img_tou.png" class="img_tou" />
              </div>
              <div class="p1_body">
                <img src="~@/assets/img/annual_bill/one/img_body.png" class="img_body" />
              </div>
              <div class="p1_pai">
                <img src="~@/assets/img/annual_bill/one/img_pai.png" class="img_pai" />
              </div>
              <div class="p1_qiqiu">
                <img
                  src="~@/assets/img/annual_bill/one/img_qiqiu.png"
                  class="img_qiqiu"
                />
              </div>
              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/one/img_middle.png"
                  class="img_middle"
                />
              </div>

              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>

              <div class="text_block">
                <div class="text_bottom text">
                  <span class="font_red">{{ item.join_time }}</span
                  >你加入了通明小V
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 80%; text-align: center"
                >
                  2020一整年你都没有在平台做过任务， 是你太忙碌了还是平台让你伤心难过了？
                  可以通过小V建议告诉我们哦~
                </div>
                <div
                  class="text_bottom text_three"
                  style="margin: 0 auto; width: 60%; text-align: center"
                >
                  2021，V智圈期待你的光临！
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <!---page2---->

          <div style="height: 100vh;overflow：hidden;" v-if="item.page == 2">
            <div :class="isHide ? 'page_2' : 'page_2 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <!---内容--->
              <div class="p2_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/two/dianzhui.png"
                  class="img2_dianzhui"
                />
              </div>
              <div class="p2_head">
                <img src="~@/assets/img/annual_bill/two/img_head.png" class="img2_head" />
              </div>
              <div class="p2_tel">
                <img src="~@/assets/img/annual_bill/two/img_tel.png" class="img2_tel" />
              </div>
              <div class="p2_kuang">
                <img
                  src="~@/assets/img/annual_bill/two/img_kuang.png"
                  class="img2_kuang"
                />
              </div>
              <div class="p2_xingqiu">
                <img
                  src="~@/assets/img/annual_bill/two/img_xingqiu.png"
                  class="img2_xingqiu"
                />
              </div>
              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/two/img_middle.png"
                  class="img_middle"
                />
              </div>
              <!--底部-->
              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div
                  swiper-animate-effect="fadeInUp"
                  swiper-animate-duration="0.5s"
                  swiper-animate-delay="0.3s"
                  class="text_bottom text"
                  style="margin: 0 auto; width: 70%; text-align: center"
                >
                  <span class="font_red"> {{ item.commit_task_time }}</span
                  >你提交了2020年的 第一个任务，拿到了第一笔任务收益， 开启了一
                  整年的小V之旅。
                </div>
                <div
                  class="text text_two"
                  style="margin: 0 auto; width: 62%; text-align: center"
                >
                  <span class="font_red">{{ item.most_num_day }}</span
                  >是你在这一年中做任务
                </div>
                <div
                  class="text text_two"
                  style="margin: 0 auto; width: 73%; text-align: center"
                >
                  最多的一天，那天你完成了<span class="font_red">{{ item.most_num }}</span
                  >个任务，为你的认真和努力点赞！
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <!--page3--->

          <div style="height: 100vh;overflow：hidden;" v-if="item.page == 3">
            <div :class="isHide ? 'page_3' : 'page_3 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <!---内容--->
              <div class="p3_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/three/img_dianzhui.png"
                  class="img3_dianzhui"
                />
              </div>
              <div class="p3_v">
                <img src="~@/assets/img/annual_bill/three/img_V.png" class="img3_v" />
              </div>
              <div class="p3_kuang">
                <img
                  src="~@/assets/img/annual_bill/three/img_kuang.png"
                  class="img3_kuang"
                />
              </div>
              <div class="p3_ren">
                <img src="~@/assets/img/annual_bill/three/img_ren.png" class="img3_ren" />
              </div>
              <div class="p3_san">
                <img src="~@/assets/img/annual_bill/three/img_san.png" class="img3_san" />
              </div>

              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/three/img_middle.png"
                  class="img_middle"
                />
              </div>
              <!--底部-->
              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div
                  class="text_bottom text"
                  style="margin: 0 auto; width: 69%; text-align: center"
                >
                  <span class="font_red">{{ item.fail_num_day }}</span
                  >是个乌云密布的日子，你被失败了
                  <span class="font_red">{{ item.fail_num }}</span
                  >个任务。
                </div>
                <div
                  class="text_two"
                  style="margin: 0 auto; width: 62%; text-align: center"
                >
                  也许那天你不在状态，也许你碰到
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 76%; text-align: center"
                >
                  了复杂的任务，但那天已经过去了，在后来的每一天，
                  你都做的更好，2021年，给自己设一个零错误率的小目标吧~
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <!--page4-->

          <div style="height: 100vh;overflow：hidden;" v-if="item.page == 4">
            <div :class="isHide ? 'page_4' : 'page_4 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <div class="p4_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/four/img_dianzhui.png"
                  class="img4_dianzhui"
                />
              </div>
              <div class="p4_jiangbei">
                <img
                  src="~@/assets/img/annual_bill/four/img_jiangbei.png"
                  class="img4_jiangbei"
                />
              </div>
              <div class="p4_left">
                <img
                  src="~@/assets/img/annual_bill/four/img_left.png"
                  class="img4_left"
                />
              </div>

              <div class="p4_right">
                <img
                  src="~@/assets/img/annual_bill/four/img_right.png"
                  class="img4_right"
                />
              </div>
              <div class="p4_bang">
                <img
                  src="~@/assets/img/annual_bill/four/img_bang.png"
                  class="img4_bang"
                />
              </div>
              <div class="p4_v">
                <img src="~@/assets/img/annual_bill/four/img_V.png" class="img4_v" />
              </div>

              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/four/img_middle.png"
                  class="img_middle"
                />
              </div>
              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div
                  class="text_bottom text"
                  style="margin: 0 auto; width: 86%; text-align: center"
                >
                  <span class="font_red">{{ item.first_withdraw_day }}</span
                  >你提现了2020年第一笔工资<span class="font_red">{{
                    item.first_withdraw_money
                  }}</span
                  >元，你一定特别开心吧！<span class="font_red">{{
                    item.withdraw_month
                  }}</span
                  >是你提现最多的一 个月，共提现了<span class="font_red">{{
                    item.withdraw_month_money
                  }}</span
                  >元，真的是太棒了！
                </div>

                <div
                  class="text_two"
                  style="margin: 0 auto; width: 72%; text-align: center"
                >
                  一整年下来，你也积攒起自己的小金库，
                </div>
                <div
                  class="text_two"
                  style="margin: 0 auto; width: 80%; text-align: center"
                >
                  共提现了<span class="font_red">{{ item.withdraw_money }}</span
                  >元,排名第<span class="font_red">{{ item.withdraw_rank }}</span
                  >名，
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 80%; text-align: center"
                >
                  希望你可以用这份工资好好爱自己，爱家人，做自己世界里的小超人~
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <!---page5---->

          <div style="height: 100vh" v-if="item.page == 5">
            <div :class="isHide ? 'page_5' : 'page_5 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <div class="p5_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/five/img_dianzhui.png"
                  class="img5_dianzhui"
                />
              </div>
              <div class="p5_kuang_foot">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_foot.png"
                  class="img5_kuang"
                />
              </div>

              <div class="p5_k_l">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_left.png"
                  class="img5_k_l"
                />
              </div>

              <div class="p5_k_t">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_top.png"
                  class="img5_k_t"
                />
              </div>
              <div class="p5_k_v">
                <img src="~@/assets/img/annual_bill/five/img_V.png" class="img5_k_v" />
              </div>
              <div class="p5_k_z">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_zhu.png"
                  class="img5_k_z"
                />
              </div>

              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/five/img_middle.png"
                  class="img_middle"
                />
              </div>

              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div
                  class="text_bottom text"
                  style="margin: 0 auto; width: 56%; text-align: center"
                >
                  <span class="font_red">2020年5月</span
                  >是一个特别的月份，V智圈出现了群聊功能！
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 80%; text-align: center"
                >
                  <div>
                    而你在<span class="font_red">{{ item.first_group_time }}</span
                    >加入了第一个平台群，
                  </div>
                  <div>
                    是<span class="font_red">{{ item.first_group_name }}</span
                    >群,可以在群内接取专属平台任务,可以和更多小V在V智圈中进行交流。
                  </div>
                </div>
                <div
                  class="text_bottom text_three"
                  style="margin: 0 auto; width: 60%; text-align: center"
                >
                  希望未来V智圈的每一次更新都能带给你不同的惊喜~
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <div style="height: 100vh" v-if="item.page == 8">
            <div :class="isHide ? 'page_5' : 'page_5 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <div class="p5_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/five/img_dianzhui.png"
                  class="img5_dianzhui"
                />
              </div>
              <div class="p5_kuang_foot">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_foot.png"
                  class="img5_kuang"
                />
              </div>

              <div class="p5_k_l">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_left.png"
                  class="img5_k_l"
                />
              </div>

              <div class="p5_k_t">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_top.png"
                  class="img5_k_t"
                />
              </div>
              <div class="p5_k_v">
                <img src="~@/assets/img/annual_bill/five/img_V.png" class="img5_k_v" />
              </div>
              <div class="p5_k_z">
                <img
                  src="~@/assets/img/annual_bill/five/img_kuang_zhu.png"
                  class="img5_k_z"
                />
              </div>

              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/five/img_middle.png"
                  class="img_middle"
                />
              </div>

              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_block">
                <div
                  class="text_bottom text"
                  style="margin: 0 auto; width: 60%; text-align: center"
                >
                  <span class="font_red">2020年5月</span
                  >是一个特别的月份，V智圈出现了群聊功能！
                </div>

                <div
                  class="text_two"
                  style="margin: 0 auto; width: 60%; text-align: center"
                >
                  目前你还没有加入过平台群，
                </div>

                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 70%; text-align: center"
                >
                  V智圈可是一直在变化呢，希望你可以尽快解锁V智圈的新功能哦~
                </div>
              </div>
            </div>
            <div
              :class="isHide ? 'swiper-pagination' : 'hide_top'"
              slot="pagination"
              @click="onSlideChange(index)"
            >
              <img src="~@/assets/img/annual_bill/icon_xiala.png" class="img_xiala" />
            </div>
          </div>

          <!---paage6-->

          <div style="height: 100vh" v-if="item.page == 6">
            <div :class="isHide ? 'page_6' : 'page_6 isHide'">
              <div class="p_top">
                <img src="~@/assets/img/annual_bill/one/img_1_top.png" class="img_top" />
                <div @click="play()" class="right1_music">
                  <img
                    src="~@/assets/img/annual_bill/one/icon_music.png"
                    :class="isPlay ? 'right_wid icon_play' : 'right_wid'"
                  />
                </div>
              </div>
              <div class="p6_dianzhui">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_dianzhui.png"
                  class="img6_dianzhui"
                />
              </div>
              <div class="p6_t">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_t.png"
                  class="img6_t"
                />
              </div>
              <div class="p6_h">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_h.png"
                  class="img6_h"
                />
              </div>
              <div class="p6_a">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_a.png"
                  class="img6_a"
                />
              </div>
              <div class="p6_n">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_n.png"
                  class="img6_n"
                />
              </div>
              <div class="p6_k">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_k.png"
                  class="img6_k"
                />
              </div>
              <div class="p6_q_l">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_left.png"
                  class="img6_q_l"
                />
              </div>
              <div class="p6_s">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_qiqiu_s.png"
                  class="img6_s"
                />
              </div>
              <div class="p6_v">
                <img src="~@/assets/img/annual_bill/sixe/img_V.png" class="img6_v" />
              </div>

              <div class="p_middle">
                <img
                  src="~@/assets/img/annual_bill/sixe/img_middle.png"
                  class="img_middle"
                />
              </div>
              <div class="p_footer">
                <img src="~@/assets/img/annual_bill/one/img_foot.png" class="img_foot" />
              </div>
              <div class="text_zui" @click="handleReviewbill">回顾账单</div>
              <div class="text_block">
                <div class="text" style="margin: 0 auto; width: 40%; text-align: center">
                  2020年的V智圈,因为
                </div>
                <div
                  class="text_bottom text"
                  style="margin: 0 auto; width: 70%; text-align: center"
                >
                  有你这样有趣的灵魂变得更加丰富，我们也会不断努力，不断进步。
                </div>
                <div
                  class="text_bottom text_two"
                  style="margin: 0 auto; width: 76%; text-align: center"
                >
                  <!--<span class="font_red">谢谢你的陪伴</span
                  >，-->
                  以后的每一年也都希望与你继续携手前行~
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { isIphone, isAndroid, setToken, setUid, callhandler } from "@/libs/util";
import { getAnnualBill } from "@/api/components/annual_bill";
export default {
  data() {
    return {
      isHide: false,
      isPlay: true,
      barValue: 0,
      audio: "",
      icon: "",
      dataList: [],
      swiperOptions: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //水平方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: false, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: false, //同上
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
      },
    };
  },
  created() {
    if (isAndroid) {
      this.isHide = false;
      this.token = window.APP.getToken();
      this.uid = window.APP.getUserId();
      setToken(this.token);
      setUid(this.uid);
      window.APP.hideTitle();
      if (this.token && this.uid) {
        this.handleAnnualBill();
      }
    } else {
      this.isHide = true;
      callhandler("getToken", "", (data) => {
        callhandler("getUserId", "", (uid) => {
          this.uid = uid;
          this.token = data;
          setToken(this.token);
          setUid(this.uid);
          if (this.token && this.uid) {
            this.handleAnnualBill();
          }
        });
      });
    }
    this.$nextTick(() => {
      let video = document.getElementById("video");
      video.play();
    });
    if (this.barValue < 100) {
      setInterval(() => {
        if (this.barValue != 100) this.barValue++;
      }, 20);
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    window.handlePause = this.handlePause;
    this.$nextTick(() => {
      let video = document.getElementById("video");
      video.play();
    });
  },

  methods: {
    handleReviewbill() {
      this.$refs.mySwiper.$swiper.slideTo(0, 0, true);
    },
    handleBack() {
      if (isAndroid) {
        this.$refs.video.pause();
        setTimeout(() => {
          window.APP.finish();
        }, 100);
      }
    },
    handlePause() {
      this.$refs.video.pause();
    },
    // 音乐背景
    play() {
      if (this.isPlay) {
        this.$refs.video.pause();
        this.isPlay = false;
      } else {
        this.$refs.video.play();
        this.isPlay = true;
      }
    },
    onSlideChange(index) {
      this.$refs.mySwiper.$swiper.slideTo(index + 1, 1000, true);
    },
    handleAnnualBill() {
      getAnnualBill().then((res) => {
        let { code, data } = res.data;
        if (code == 10002) {
          this.dataList = data;
        }
      });
    },
  },
};
</script>
<style lang="less">
.isHide {
  top: 80px !important;
}
.right_music {
  position: absolute;
  z-index: 40000;
  top: -13px;
  right: 100px;
}
.right1_music {
  position: absolute;
  z-index: 40000;
  top: 17px;
  right: 100px;
}
.jiazai_box {
  height: 100vh;
  width: 100vw;
  padding-top: 65%;
  .jiazai {
    width: 112.5px;
    height: 73.5px;
  }
  .jiazai_block {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 12.5px;
  }
  .mt-progress {
    height: 12px;
    width: 196px;
    border-radius: 8px;
    border: 3.5px solid #013590;
  }
  .mt-progress-runway {
    background-color: #fe7a87;
    height: 12px !important;
    border-radius: 8px;
  }
  .mt-progress-progress {
    background-color: #fee779;
    height: 12px !important;
    border-radius: 8px;
  }
  .div_block {
    color: #013590;
    font-size: 14px;
    font-weight: bold;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.text_block {
  position: absolute;
  top: 365px;
  z-index: 2000;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #013590;
  .text_bottom {
    padding-bottom: 10px;
  }

  .font_red {
    font-size: 17px;
    color: #fe5465 !important;
    font-weight: bold;
  }
}

@keyframes text_one_run {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.left_wid {
  height: 19px;
  width: 11px;
}
.right_wid {
  width: 17px;
  height: 27px;
}

.bill_box {
  background: #a5dff7;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .page_1 {
    position: relative;
    background: url(~@/assets/img/img_bg.png) no-repeat;
    background-size: 100% auto;
    top: 40px;
    width: 354px;
    height: 100%;
    text-align: center;
    margin: 0 auto;
    .p1_dianzhui {
      position: absolute;
      top: 50px;
      width: 100%;
      left: 0px;
      .img_dianzhui {
        width: 238px;
        // animation: run 7s linear infinite;
      }
    }

    .p1_xingqiu {
      position: absolute;
      left: 0;
      width: 95px;
      top: 104px;
      left: 78px;
      .img_xingqiu {
        width: 95px;
        animation: xingqiu_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
      }
    }

    @keyframes xingqiu_run {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }
    .p1_tou {
      position: absolute;
      right: 65px;
      top: 150px;
      z-index: 1000;
      .img_tou {
        width: 117px;
      }
    }
    .p1_body {
      position: absolute;
      right: 70px;
      top: 245px;
      .img_body {
        width: 141px;
      }
    }
    .p1_pai {
      position: absolute;
      top: 172px;
      left: 76px;
      .img_pai {
        width: 119px;
        animation: pai_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
      }
    }
    @keyframes pai_run {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(-6deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }

    .p1_qiqiu {
      position: absolute;
      top: 277px;
      left: 78px;
      .img_qiqiu {
        width: 54px;
        animation: img_qiqiu_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
      }
    }

    @keyframes img_qiqiu_run {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(15deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    .p1_footer {
      position: absolute;
      z-index: 2000;
      top: 313px;
      left: -1.8px;

      .img_foot {
        width: 364px;
      }
    }
  }
}

.page_2 {
  position: relative;
  background: url(~@/assets/img/img_bg.png) no-repeat;
  background-size: 100% auto;
  top: 40px;
  width: 354px;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  .p2_tel {
    position: absolute;
    width: 100%;
    top: 266px;
    left: -67px;
    .img2_tel {
      width: 59px;
    }
  }
  .p2_dianzhui {
    position: absolute;
    width: 100%;
    top: 75px;
    .img2_dianzhui {
      width: 205px;
    }
  }
  .p2_head {
    position: absolute;
    right: 70px;
    top: 75px;
    z-index: 1000;
    .img2_head {
      width: 190px;
      animation: head_sway 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }

  .p2_kuang {
    position: absolute;
    width: 100%;
    top: 220px;
    left: 32px;
    z-index: 1001;
    .img2_kuang {
      width: 60.5px;
      animation: kuang_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p2_xingqiu {
    position: absolute;
    width: 100%;
    top: 270px;
    left: 64px;
    z-index: 1001;
    .img2_xingqiu {
      width: 60.5px;
      animation: xingqiu_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p2_body {
    .img2_body {
      width: 190px;
    }
  }
  .p2_footer {
    position: absolute;
    z-index: 2000;
    left: -1.8px;

    .img_foot {
      width: 364px;
    }
  }
}
@keyframes kuang_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes xingqiu_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes head_run {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes head_sway {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/**第三个页面 */
.page_3 {
  position: relative;
  background: url(~@/assets/img/img_bg.png) no-repeat;
  background-size: 100% auto;
  top: 40px;
  width: 354px;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  .p3_dianzhui {
    position: absolute;
    width: 100%;
    top: 70px;
    .img3_dianzhui {
      width: 213.5px;
      animation: p3_dianzhuirun 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p3_kuang {
    position: absolute;
    width: 100%;
    top: 200px;
    left: 56px;
    .img3_kuang {
      width: 79.5px;
      animation: p3_kuang_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p3_v {
    position: absolute;
    top: 198px;
    right: 45px;
    width: 100%;
    .img3_v {
      width: 107px;
    }
  }
  .p3_ren {
    position: absolute;
    top: 255px;
    right: 88px;
    .img3_ren {
      width: 70px;
    }
  }
  .p3_san {
    position: absolute;
    top: 110px;
    right: 72px;
    .img3_san {
      width: 209.5px;
      animation: p3_san_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
}

@keyframes p3_dianzhuirun {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes p3_kuang_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes p3_san_run {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/**第4个页面 */
.page_4 {
  position: relative;
  background: url(~@/assets/img/img_bg.png) no-repeat;
  background-size: 100% auto;
  top: 40px;
  width: 354px;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  .p4_dianzhui {
    position: absolute;
    width: 100%;
    top: 80px;
    left: 4px;
    .img4_dianzhui {
      width: 208px;
      animation: dianzhui4_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p4_jiangbei {
    position: absolute;
    z-index: 1000;
    top: 153px;
    right: 50px;
    .img4_jiangbei {
      width: 196.5px;
    }
  }
  .p4_left {
    position: absolute;
    top: 170px;
    width: 100%;
    left: 100px;
    .img4_left {
      width: 61.5px;
      animation: left4_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p4_right {
    position: absolute;
    top: 266px;
    width: 100%;
    right: 50px;
    .img4_right {
      width: 61.5px;
      animation: left4_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p4_v {
    position: absolute;
    width: 100%;
    top: 82px;
    right: 25px;
    z-index: 2000;
    .img4_v {
      width: 127.5px;
    }
  }
  .p4_bang {
    position: absolute;
    width: 100%;
    top: 74px;
    left: 22px;
    z-index: 2001;
    .img4_bang {
      width: 50.5px;
      animation: bang4_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
}
@keyframes bang4_run {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes dianzhui4_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes left4_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -7px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.page_5 {
  position: relative;
  background: url(~@/assets/img/img_bg.png) no-repeat;
  background-size: 100% auto;
  top: 40px;
  width: 354px;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  .p5_dianzhui {
    position: absolute;
    width: 100%;
    top: 70px;
    .img5_dianzhui {
      width: 213.5px;
    }
  }
  .p5_k_l {
    position: absolute;
    top: 110px;
    right: 70px;
    width: 100%;
    .img5_k_l {
      width: 53.5px;
      animation: img5_k_l_run 1.7s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p5_k_t {
    position: absolute;
    top: 92px;
    width: 100%;
    left: 36px;
    .img5_k_t {
      width: 115px;
      animation: img5_k_t_run 1.7s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p5_k_v {
    position: absolute;
    width: 100%;
    top: 170px;
    right: 30px;
    .img5_k_v {
      width: 155px;
    }
  }
  .p5_kuang_foot {
    position: absolute;
    width: 100%;
    top: 270px;
    left: 75px;
    .img5_kuang {
      width: 68.5px;
      animation: img5_kuang_run 1.7s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p5_k_z {
    position: absolute;
    width: 100%;
    top: 160px;
    left: 54px;
    .img5_k_z {
      width: 103px;
      animation: img5_k_z_run 1.7s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
}
@keyframes img5_k_z_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img5_k_l_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes img5_k_t_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes img5_kuang_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.page_6 {
  position: relative;
  background: url(~@/assets/img/img_bg.png) no-repeat;
  background-size: 100% auto;
  top: 40px;
  width: 354px;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  .p6_dianzhui {
    position: absolute;
    width: 100%;
    top: 38px;
    .img6_dianzhui {
      width: 213.5px;
    }
  }
  .p6_t {
    position: absolute;
    top: 77px;
    left: 40px;
    .img6_t {
      width: 68px;
      animation: img6_t_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p6_h {
    position: absolute;
    z-index: 200;
    top: 113px;
    left: 82px;
    .img6_h {
      width: 68px;
      animation: img6_h_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }

  .p6_a {
    position: absolute;
    top: 106px;
    left: 130px;
    .img6_a {
      width: 53.5px;
      animation: img6_a_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }

  .p6_n {
    position: absolute;
    top: 128px;
    width: 100%;
    left: 46px;

    .img6_n {
      width: 55.5px;
      animation: img6_n_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p6_q_l {
    position: absolute;
    top: 210px;
    width: 100%;
    right: 90px;
    z-index: 999;
    .img6_q_l {
      width: 35px;
    }
  }
  .p6_k {
    position: absolute;
    top: 100px;
    width: 100%;
    left: 70px;
    z-index: 999;
    .img6_k {
      width: 63px;
      animation: img6_k_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }

  .p6_s {
    position: absolute;
    top: 139px;
    left: 106px;
    width: 100%;
    .img6_s {
      width: 47.5px;
      animation: img6_s_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
  .p6_v {
    position: absolute;
    top: 205px;
    width: 100%;

    .img6_v {
      width: 212px;
      animation: img6_v_run 2s infinite cubic-bezier(0.6, 0.4, 0.4, 0.6);
    }
  }
}

@keyframes img6_t_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_h_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_a_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_n_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_k_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_s_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes img6_v_run {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/**固定位置 */
.p_top {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: -30px;
  z-index: 2000;
  .img_top {
    width: 350px;
    height: 135px;
  }
}
.p_footer {
  position: absolute;
  z-index: 2000;
  top: 313px;
  left: -3.3px;
  .img_foot {
    width: 364px;
  }
}

.p_middle {
  z-index: 1000;
  position: absolute;
  left: 9.5px;
  .img_middle {
    width: 335px;
    margin-top: 1.4rem;
  }
}
.fixedPosition {
  width: 100%;
  top: 0;
}
.fixed_box {
  padding: 26px 15px 0px;
}
.swiper2 {
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 3000 !important;
  top: 575px;
  width: 20%;
  left: 50%;
  margin-left: -10%;
}
.hide_top {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 3000 !important;
  width: 20%;
  left: 50%;
  margin-left: -10%;
  top: 613px !important;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-wrapper {
  transition-timing-function: linear !important;
}
.img_xiala {
  width: 30px;
}
.icon_play {
  animation: icon_play 3s linear infinite;
}
@keyframes icon_play {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.swiper-slide-active {
  .text {
    opacity: 0; /*实先规定文字的状态是不显示的*/
    animation: text_one_run 4s ease 0s 1;
    animation-fill-mode: forwards;
  }
  .text_two {
    opacity: 0;
    animation: text_one_run 4s ease 1s 1;
    animation-fill-mode: forwards;
  }
  .text_three {
    opacity: 0;
    animation: text_one_run 4s ease 2s 1;
    animation-fill-mode: forwards;
  }
  .text_zui {
    animation-fill-mode: forwards;
    position: absolute;
    z-index: 2000;
    top: 500px;
    right: 25px;
    color: #013590;
    font-weight: bold;
    transform: rotate(-45deg);
  }
}

@keyframes text_zui_run {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
