<template lang="pug">
div
  .achievement-item2
    img.big-icon.icon5-float(:src="account_level[levelIndex]")
    .achievement-item-tip1(:style="'color: ' + progress_Style[levelIndex]")
      span {{ accountMedal[levelIndex].name }}
      .achievement-item-count
        .tip-rule(@click="showRules") 规则说明
        .tip-arrow
    .progress
      van-progress(color="#ffffff",:track-color="progress_Style[levelIndex]",:show-pivot="false",:percentage="(level * 100) / 3",stroke-width="6")
      .medal(v-for="(item, i) in accountMedal" :key="item.name" :style="'left: ' + item.percentage + '%'")
        //- 解锁未点亮
        .medal-icon(v-if="item.status === 1", @click="getPoint(item, 'showPoint3', 'showPoint2')")
          img(:src="account_2[i]")
        //- 解锁已点亮
        .medal-icon(v-else :class="item.status === 2 ? 'rubberBand' + (i + 1) : ''" @click="getPoint(item, 'showPoint3', 'showPoint2')"
        )
          img(:src="account_1[i]")
        div(:style="'color: ' + progress_Style[levelIndex]") {{ item.name }}
  .achievement-box2
    .achievement-list(v-for="(item, i) in dataList")
      .achievement-list-icon-active
        img(:src="acountType[item.account_type]")
      .achievement-list-content
        .list-progress2
          van-progress(color="#F37140",track-color="#EAEAEA",:percentage="item.percentage_",stroke-width="6",:show-pivot="false")
          .progress-icon(:style="'left:' + item.percentage_ + '%;'")
            .progress-icon-text {{ item.now }}
            .progress-icon-arrow
          .progress-box(v-for="(box, c) in item.list",:style="'left:' + (box.number * 100) / item.sum + '%;'")
            .progress-box-img(v-if="box.status === 1")
              img(src="~@/assets/img/achievement/achievement_icon3.png")
            .progress-box-img(v-else,:class="box.status === 2 ? 'rubberBand' + (c + 1) : ''",@click="getPoint(box, 'showPoint4')")
              img(src="~@/assets/img/achievement/achievement_icon2.png")
            .progress-box-text {{ box.number }}
        .achievement-list-round
          span 0
  dialogPoint4(:show="showPoint4", :point="point", @close="closeModal")
  dialogPoint3(:show="showPoint3",:levelInfo="accountMedal",:levelIndex="levelIndex",:point="point",@close="closeModal")
  dialogPoint2(:show="showPoint2",:levelInfo="accountMedal",:levelIndex="levelIndex",:list="dataList",:stage="stage", @close="closeModal")
  dialogRule2(:show="showRule", @close="closeModal")
</template>
<script>
import mixins from "../../mixins";
import dialogPoint4 from "./dialogPoint4.vue";
import dialogPoint3 from "./dialogPoint3.vue";
import dialogPoint2 from "./dialogPoint2.vue";
import dialogRule2 from "./dialogRule2.vue";
import { growAccountInfo, growAccountDraw } from "@/api/components/achievement";
import {
  accountMedal,
  progress_Style,
  account_2,
  account_1,
  account_level,
  acountType,
} from "../../data";
export default {
  mixins: [mixins],
  components: {
    dialogPoint4,
    dialogPoint3,
    dialogPoint2,
    dialogRule2,
  },
  computed: {
    levelIndex() {
      return this.level ? this.level - 1 : 0;
    },
  },
  data() {
    return {
      accountMedal,
      progress_Style,
      account_level,
      acountType,
      account_1,
      account_2,
      showPoint4: false,
      showPoint3: false,
      showPoint2: false,
      stage: null,
      showRule: false,
      point: 0,
      dataList: [],
      level: 0,
    };
  },
  async created() {
    this.getToken().then(() => {
      this.getData();
    });
  },
  methods: {
    closeModal() {
      this.showPoint4 = false;
      this.showPoint3 = false;
      this.showPoint2 = false;
      this.showRule = false;
    },
    getData() {
      growAccountInfo().then(({ data }) => {
        data.data.child.map((item) => {
          item.sum = item.list[item.list.length - 1].number;
          if(item.now < item.sum) item.percentage_ = (item.now * 100) / item.sum
          else item.percentage_ = 100
        });
        // 等级
        this.level = data.data.master.filter(
          (item) => item.status === 3
        ).length;
        // 列表数据
        this.dataList = data.data.child;
        // 主数据
        data.data.master.map((item, i) => {
          this.accountMedal[i] = Object.assign({}, this.accountMedal[i], item);
        });
      });
    },

    getPoint(n, modalId, modalId2) {
      if (n.status === 2) {
        growAccountDraw({ id: n.id }).then(() => {
          this.point = n.integral;

          this[modalId] = true;
          this.getData();
        });
      } else if (modalId2) {
        this.stage = n;
        this[modalId2] = true;
      }
    },
    showRules() {
      this.showRule = true;
    },
  },
};
</script>
<style lang="less">
.achievement {
  .achievement-content {
    .list-progress2 {
      position: relative;
      margin-top: 30px;
      .progress-box {
        position: absolute;
        text-align: center;
        margin-left: -12px;
        top: -8px;
        img {
          height: 22px;
          width: 24px;
        }
        .progress-box-text {
          line-height: 12px;
        }
      }
      .progress-icon {
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 25px;
        position: absolute;
        background: #ea5836;
        border-radius: 50%;
        bottom: 10px;
        font-size: 12px;
        color: #ffffff;
        margin-left: -10px;
        left: 0;
        transform: scale(0.7);
      }
      .progress-icon-arrow {
        height: 0;
        width: 0;
        margin: auto;
        margin-top: -9px;
        border: 10px solid #ea5836;
        border-top-width: 14px;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
      .van-progress__portion {
        background: linear-gradient(
          90deg,
          #ffefe8 0%,
          #fdd6c0 11%,
          #ed6735 100%
        ) !important;
      }
    }
    .achievement-box2 {
      .achievement-list {
        height: 72px;
        width: 345px;
        position: relative;
        box-shadow: 0px -1px 20px -9px rgba(82, 0, 0, 0.28);
        border: 1px solid #f5f5f5;
        border-radius: 15px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 14px;
        padding-right: 30px;
        margin-bottom: 10px;
        .achievement-list-icon {
          position: absolute;
          left: 12px;
          top: 17px;
          width: 56px;
        }
        .achievement-list-icon-active {
          position: absolute;
          left: 15px;
          text-align: center;
          top: 10px;
          img:first-child {
            width: 75px;
            display: block;
          }
          img:last-child {
            width: 48px;
            display: block;
            margin: auto;
          }
        }
        .achievement-list-content {
          padding-left: 72px;
          flex: 1;
          .achievement-list-round {
            display: flex;
            justify-content: space-between;
            line-height: 30px;
            height: 30px;
          }
        }
        .achievement-list-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #aaaaaa;
          line-height: 50px;
          .achievement-list-icon-box {
            width: 50px;
            height: 50px;
            text-align: right;
            img {
              width: 24px;
              height: 22px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .achievement-item2 {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 15px;
      box-sizing: border-box;
      margin-top: 37px;
      width: 344px;
      height: 177px;
      background-image: url("~@/assets/img/achievement2/account_3_1.png");
      background-size: 100% 100%;
      position: relative;
      .big-icon {
        width: 85px;
        position: absolute;
        left: 50%;
        margin-left: -42.5px;
        top: -25px;
      }
      .progress {
        margin-top: 22px;
        position: relative;
        .medal {
          text-align: center;
          color: #ffffff;
          position: absolute;
          top: -9px;
          margin-left: -30px;
          width: 60px;
          .medal-icon {
            width: 30px;
            height: 30px;
            display: inline-block;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .medal:last-child {
          div {
            position: relative;
            left: -12px;
          }
        }
      }
      .achievement-item-count {
        color: #ffffff;
        font-size: 30px;
        line-height: 42px;
        font-weight: bold;
        position: relative;
        display: inline-block;
        .tip-arrow {
          width: 0;
          height: 0;
          border: 3px solid #ffffff;
          border-right-width: 6px;
          position: absolute;
          right: -20px;
          top: -12px;
          border-left-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
        .tip-rule {
          position: absolute;
          right: -80px;
          width: 60px;
          text-align: center;
          top: -25px;
          font-size: 12px;
          background-color: #ffffff;
          color: #666666;
          line-height: 26px;
          border-radius: 8px;
          font-weight: normal;
        }
      }
      .achievement-item-tip1 {
        color: #ffffff;
        font-size: 18px;
        line-height: 18px;
        margin-top: 3px;
        font-weight: bold;
        text-align: center;
        margin-top: 55px;
      }
    }
  }
  .bounceInDown {
    animation: bounceInDown 3s ease-in-out forwards;
  }
  @keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px);
    }

    60% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  .rubberBand1 {
    animation: 2s rubberBand ease-in-out infinite;
  }
  .rubberBand2 {
    animation: 2s rubberBand 0.4s ease-in-out infinite;
  }
  .rubberBand3 {
    animation: 2s rubberBand 0.8s ease-in-out infinite;
  }
  .rubberBand4 {
    animation: 2s rubberBand 1.2s ease-in-out infinite;
  }
  @keyframes rubberBand {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    15% {
      -webkit-transform: scaleX(1.25) scaleY(0.75);
      -ms-transform: scaleX(1.25) scaleY(0.75);
      transform: scaleX(1.25) scaleY(0.75);
    }

    20% {
      -webkit-transform: scaleX(0.75) scaleY(1.25);
      -ms-transform: scaleX(0.75) scaleY(1.25);
      transform: scaleX(0.75) scaleY(1.25);
    }

    30% {
      -webkit-transform: scaleX(1.15) scaleY(0.85);
      -ms-transform: scaleX(1.15) scaleY(0.85);
      transform: scaleX(1.15) scaleY(0.85);
    }

    50% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }

  .icon5-float {
    animation: icon5-float 3s linear infinite;
  }
  @keyframes icon5-float {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .shake {
    animation: 1s shake ease-in-out;
  }
  @keyframes shake {
    0%,
    100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
}
</style>
