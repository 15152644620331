<template lang="pug">
.jigsaw-history(class="fadeInAnimate")
  van-nav-bar(
    title="碎片记录",
    left-arrow,
    :border="false",
    @click-left="onClickLeft"
  )
  .history-box
    van-list.list-box(
      v-model="loading",
      :finished="finished",
      finished-text="没有更多了",
      @load="onLoad"
    )
      .item(v-for="(item, index) in list", :key="index", :title="item")
        .name {{item.jigsaw_name}}
        .time {{ item.created_at }}
        .intro {{ item.source }}
</template>
<script>
import mixins from "../mixins";
import { activityJigsawLog } from "@/api/components/jigsaw";
export default {
  mixins: [mixins],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  mounted() {
    // this.onLoad();
    document.getElementsByTagName('body')[0].style.backgroundColor = '#f79e64'
  },
  methods: {
    async onLoad() {
      const res = await activityJigsawLog({ page: this.page, type: this.index });
      const data = res.data.data;
      data.data &&
        data.data.forEach((item) => {
          this.list.push(item);
        });
      this.page++;
      this.loading = false;
      if (!data.has_more) {
        this.finished = true;
      } else {
        this.finished = false;
      }
    },
  },
};
</script>
<style lang="less">
.jigsaw-history {
  background-image: url("~@/assets/img/jigsaw/rules_bg.png");
  background-size: 100% auto;
  padding-bottom: 30px;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  .van-nav-bar {
    background: none;
  }
  .history-box {
    position: relative;
     background: url("~@/assets/img/jigsaw/rules_item.png");
    background-size: 100% 100%;
    padding-top: 50px;
    height: 709px;
    width: 375px;
    box-sizing: border-box;
    text-align: justify;
    .list-box {
      height: 620px;
      padding: 0 50px;
      overflow: scroll;
    }
    .item {
      height: 58px;
      border-bottom: 1px solid #fcc594;
      padding-top: 8px;
      box-sizing: border-box;
      font-size: 12px;
      color: #55231d;
      line-height: 22px;
      position: relative;
      .name {
        color: #D22E28;
        font-weight: bold;
      }
      .intro {
        color: #E9726E;
        position: absolute;
        right: 0px;
        bottom: 5px;
      }
      .time {
        color: #E9726E;
      }
    }
  }
}
</style>
