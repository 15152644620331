<template lang="pug">
.questions-box
  .questions-intro(@click="toggle")
    img.lock(:src="iconSrc")
    .title 问题回答
    img.right-img(v-if="isShowText", src="~@/assets/img/training/arrowup.png")
    img.right-img(v-else, src="~@/assets/img/training/icon_1.png")
  van-form.questions-item.fadeInAnimate(v-if="isShowText", @submit="onSubmit")
    div(v-for="item in detail.question", :key="item.id")
      .title {{ item.name }} {{ item.model_answer.length > 1 ? '(多选)' : '(单选)' }}
      .answer(v-if="detail.status === 4")
        span(:class="item.status === 2 ? 'allow' : 'wram'") 我选：{{ item.answer }}
        span 答案：{{ item.model_answer }}
      van-field(
        name="radio",
        v-if="item.model_answer.length > 1",
        :rules="[{ required: true, message: '选择不可以空着哦' }]"
      )
        template(#input)
          van-checkbox-group(v-model="item.submit", :disabled="disable")
            van-checkbox(
              v-for="op in item.option",
              :key="op.id",
              :name="op.value"
            ) {{ op.option }}
      van-field(v-else, :rules="[{ required: true, message: '选择不可以空着哦' }]")
        template(#input)
          van-radio-group(v-model="item.submit", :disabled="disable")
            van-radio(
              v-for="op in item.option",
              :key="op.id",
              :name="op.value"
            ) {{ op.option }}
    .submit
      van-button.btn-sub(
        v-if="!disable",
        round,
        type="info",
        native-type="submit"
      ) 提交
</template>
<script>
import close from "@/assets/img/training/study_close.png";
import ing from "@/assets/img/training/study_ing.png";
import success from "@/assets/img/training/study_success.png";
export default {
  data() {
    return {
      isShowText: false,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconSrc() {
      let src = close;
      const t = this.detail.status;
      if (t === 1) src = success;
      if (t === 2) src = close;
      if (t === 3) src = ing;
      if (t === 4) src = success;
      return src;
    },
    disable() {
      let bool = false;
      if (this.detail.status === 1 || this.detail.status === 4) bool = true;
      else bool = false;
      return bool;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    toggle() {
      if (this.detail.status !== 2) this.isShowText = !this.isShowText;
      else
        this.$parent.$refs["modal"].toggleModel(
          "请先阅读超过" + this.detail.study_time + "s"
        );
    },
  },
};
</script>
<style lang="less">
.questions-box {
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  .van-checkbox__label,
  .van-radio__label {
    color: #333333 !important;
  }
  .van-checkbox__icon,
  .van-radio__icon {
    zoom: 0.8;
  }
  .van-checkbox,
  .van-radio {
    margin-bottom: 20px;
  }
  .van-field__error-message {
    margin-top: -24px;
  }
  .van-checkbox-group,
  .van-radio-group {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
    width: 100%;
  }
  .van-cell {
    padding: 0 !important;
  }
  .answer {
    margin-bottom: 10px;
    .wram {
      color: red;
    }
    .allow {
      color: green;
    }
    span {
      margin-right: 10px;
      &:last-child {
        color: green;
      }
    }
  }
  .submit {
    text-align: right;
  }
  .btn-sub {
    width: 76px;
    height: 30px;
    background: none;
    border: 1px solid #ff0000;
    color: #ff0000;
  }
  .questions-intro {
    padding-left: 16px;
    padding-right: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    align-items: center;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
    .lock {
      height: 25px;
      width: 24px;
    }
    .right-img {
      height: 14px;
      width: 14px;
    }
    .title {
      flex: 1;
      margin-left: 3px;
    }
  }
  .questions-item {
    padding: 10px 10px 10px 20px;
    .title {
      font-weight: bold;
      font-size: 15px;
      margin-top: 8px;
      line-height: 17px;
      margin-bottom: 13px;
    }
  }
}
</style>