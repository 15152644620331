import Cookies from 'js-cookie'

const TokenKey = 'tm_token'
const uidKey = 'tm_uid'

export const setupWebViewJavascriptBridge = (callback) => {

  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge)
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback)
  }
  window.WVJBCallbacks = [callback]
  let WVJBIframe = document.createElement('iframe')
  WVJBIframe.style.display = 'none'
  WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
  document.documentElement.appendChild(WVJBIframe)
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe)
  }, 0)
}

export const isWebView = () => {
  if (window.WebViewJavascriptBridge) return true
  else return false
}

/**
 * js调用原生
 */

export const callhandler = (name, data, callback) => {
  setupWebViewJavascriptBridge((bridge) => {
    bridge.callHandler(name, data, callback)
  })
}


/**
 * 原生调用js
 */

export const registerHandler = (name, data, callback) => {
  setupWebViewJavascriptBridge((bridge) => {
    bridge.registerHandler(name, data, callback)
  })
}
/**
 * js获取webview传来的参数
 */
export const getUrlData = () => {
  /**
   * tempStr 格式是http://域名/路由?key=value&key=value...
   */
  let tempStr = window.location.href;
  console.log(tempStr)
  /**
   * tempArr 是一个字符串数组 格式是["key=value", "key=value", ...]
   */
  let tempArr = tempStr.split("?")[1] ?
    tempStr.split("?")[1].split("&") : [];
  /**
   * returnArr 是要返回出去的数据对象 格式是 { key: value, key: value, ... }
   */
  let returnArr = {};
  tempArr.forEach(element => {
    returnArr[element.split("=")[0]] = element.split("=")[1];
  });
  return returnArr;
}

/**
 * array数组
 * value 元素
 * 判断元素是否存在数组中
 */
export const isIncludes = (array, value) => {
  return array.includes(value)
}

/**
 * IPHONE手机验证
 */
export const isIphone = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);

/**
 * 安卓手机验证
 */

export const isAndroid = /(Android)/i.test(navigator.userAgent);


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}




export function getUid() {
  return Cookies.get(uidKey)
}

export function setUid(uid) {
  return Cookies.set(uidKey, uid)
}

export function removeUid() {
  return Cookies.remove(uidKey)
}
