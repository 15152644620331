<template>
  <div class="back_detail" style="overflow: hidden">
    <div class="search_header">
      <mt-header title="明细">
        <!--  <router-link :to="title" slot="left">        </router-link>-->
        <mt-button slot="left" @click="handleRouter" icon="back"></mt-button>
      </mt-header>
    </div>
    <div class="activBox">
      <div class="navbar_box flex_center">
        <div
          v-for="(item, index) in orderStatus"
          :key="index"
          @click="handleTab(item.id)"
          :class="item.id == isActive ? 'nav_tab active_class' : 'nav_tab'"
        >
          {{ item.name }}
        </div>
      </div>
      <div style="position: relative">
        <vWinning
          :loading="loading"
          @more="more"
          :isMore="isMore"
          @refresh="handleBoxRecord"
          :list="boxRecordList"
        ></vWinning>
      </div>
    </div>
  </div>
</template>
<script>
const orderStatus = [
  {
    id: "1",
    name: "抽徽章记录",
  },
  {
    id: "2",
    name: "转盘记录",
  },
];
import { getDadgelog } from "@/api/components/glorious";
export default {
  data() {
    return {
      visible: false,
      isActive: 1,
      isTitle: "",
      title: "",
      isSmoke: true,
      orderStatus: orderStatus,
      boxRecordList: [],
      boxLog: [],
      isMore: "",
      page: "1",
      loading: false,
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.handleBoxRecord();
  },
  methods: {
    handleRouter() {
      this.$router.push(this.title);
    },
    handleBoxRecord() {
      this.page = 1;
      this.loading = true;
      getDadgelog({
        type: this.isActive,
        page:this.page
      }).then((res) => {
        let { code } = res.data;
        this.loading = false;
        if (code == 10002) {
          let { data } = res.data;
          this.boxRecordList = data.data;
          if (data.has_more) {
            this.isMore = true;
          } else {
            this.isMore = false;
          }
        }
      });
    },
    handleTab(tab) {
      this.isActive = tab;
      this.page = 1;
      this.handleBoxRecord();
    },
    handleRules() {
      this.$router.push({
        path: "/blindExplain",
      });
    },
    more() {
      this.page++;
      getDadgelog({ page: this.page, type: this.isActive }).then((res) => {
        let { code } = res.data;
        this.loading = false;
        if (code == 10002) {
          let { data } = res.data;
          data.data =
            data.data &&
            data.data.map((item) => {
              item.page = data.current_page;
              return item;
            });
          //console.log("data.data", data.data);
          this.boxRecordList = this.boxRecordList.concat(data.data);
          if (data.has_more) {
            this.isMore = true;
          } else {
            this.isMore = false;
          }
        }
      });
    },
    handleList() {
      this.$router.push({
        path: "/activeRecord",
      });
    },
  },
  components: {
    vWinning: () => import("./list"),
  },
};
</script>

<style lang="less">
.back_detail {
  background: #f3ce3e;
  .search_header {
    background: #f3ce3e;
  }
  .mint-header {
    background: #f3ce3e !important;
  }
  .activBox {
    padding-top: 1.17rem;
    .navbar_box {
      height: 50px;
      background: #f3ce3e !important;
    }
    .nav_tab {
      width: 50%;
      text-align: center;
      height: 50px;
      line-height: 46px;
      font-size: 0.43rem;
    }
    .active_item {
      margin-bottom: 0.13rem;
      padding: 0.32rem 0rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
    }
    .active_class {
      color: #000;
    }

    .active_class:after {
      color: #000;
      position: absolute;
      left: 50%;
      display: block;
      width: 0.8rem;
      height: 0.11rem;
      border-radius: 0.27rem;
      content: "";
      background: #000;
      transform: translateX(-50%);
    }

    .mast_content_div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  }
}

.div_dialog_content {
  padding: 20px;
}
.header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.mint-msgbox-message {
  display: none;
}
.mint-msgbox-input input {
  width: 95% !important;
  padding: 10px 5px !important;
}
.mint-msgbox-confirm {
  color: #ef1f66 !important;
}
</style>
