
import {
    get
} from '@/api/http'


export function getAnnualBill(params){
    return get({
        url: "/annual_bill/info",
        params,
    })
}