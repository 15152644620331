<template lang="pug">
.history(class="fadeInAnimate")
  van-nav-bar(
    title="历史记录",
    left-arrow,
    fixed,
    :border="false",
    @click-left="onClickLeft"
  )
  .history-box
    .tab-tabChange
      .tab-box
        .tab-item(:class="index === 1 ? 'active' : ''", @click="changeTab(1)") 小红旗
        .tab-item(:class="index === 2 ? 'active' : ''", @click="changeTab(2)") 大红旗
    van-list.list-box(
      v-model="loading",
      :finished="finished",
      finished-text="没有更多了",
      @load="onLoad"
    )
      .item(v-for="(item, index) in list", :key="index", :title="item")
        .name {{ item.content }}
        .time {{ item.created_at }}
</template>
<script>
import mixins from "../mixins";
import { flagLog } from "@/api/components/active";
export default {
  mixins: [mixins],
  data() {
    return {
      index: 1,
      list: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  mounted() {
    // this.onLoad();
  },
  methods: {
    async onLoad() {
      const res = await flagLog({ page: this.page, type: this.index });
      const data = res.data.data;
      data.data &&
        data.data.forEach((item) => {
          this.list.push(item);
        });
      this.page++;
      this.loading = false;
      if (!data.has_more) {
        this.finished = true;
      } else {
        this.finished = false
      }
    },
    changeTab(e) {
      this.list = [];
      this.index = e;
      this.page = 1;
      this.onLoad();
    },
  },
};
</script>
<style lang="less">
.history {
  background: #f9b467;
  padding-bottom: 30px;
  padding-top: 50px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  .tab-tabChange {
    text-align: center;
    width: 100%;
    padding-top: 23px;
    padding-bottom: 10px;
    .tab-box {
      margin: auto;
      display: flex;
      width: 220px;
      .tab-item {
        flex: 1;
        line-height: 34px;
        height: 34px;
        color: #f15332;
        font-size: 14px;
        border: 1px solid #f15332;
        transition: all 0.2s ease-in-out;
        &:first-child {
          border-radius: 17px 0 0 17px;
        }
        &:last-child {
          border-radius: 0 17px 17px 0;
        }
      }
      .active {
        background: #f15332;
        color: #ffffff;
      }
    }
  }
  .history-box {
    position: relative;
    background: url("~@/assets/img/active/active_intros_bg.png");
    background-size: 100% 100%;
    height: 670px;
    width: 362px;
    margin-left: 6.5px;
    box-sizing: border-box;
    text-align: justify;
    .list-box {
      height: 560px;
      padding: 0 20px;
      overflow: scroll;
    }
    .item {
      height: 58px;
      border-bottom: 1px solid #fcc594;
      padding-top: 8px;
      box-sizing: border-box;
      font-size: 12px;
      color: #55231d;
      line-height: 22px;
    }
  }
}
</style>