<template lang="pug">
div
    .achievement-item
      img.big-icon.icon5-float(v-if="level >= 0", :src="icon5_mid[level]")
      .achievement-item-count
        span {{ detail.task_num }}
        .tip-rule(@click="showRules") 规则说明
        .tip-arrow
      .achievement-item-tip1 完成任务量
      .progress
        van-progress(
          color="#FFCBC7",
          track-color="#D87A7A",
          :show-pivot="false",
          :percentage="percentage",
          stroke-width="14"
        )
        .medal(
          v-for="(item, i) in progressMedal",
          :key="item.name",
          :style="'left: ' + item.percentage + '%'"
        )
          //- 解锁未点亮
          img.medal-icon(
            v-if="!item.task_num || detail.task_num < item.task_num",
            :src="icon8[i]"
          )
          //- 解锁已点亮
          img.medal-icon(v-else, :src="icon7[i]")
          div {{ item.name }}
    .achievement-box
      .achievement-list(
        v-for="(item, i) in progressMedal",
        :class="item.task_num ? '' : 'grayscale'"
      )
        img.achievement-list-icon(
          v-if="!item.task_num",
          src="~@/assets/img/achievement/achievement_icon9.png"
        )
        .achievement-list-icon-active(v-else)
          img(:src="icon5[i]")
          img(src="~@/assets/img/achievement/achievement_taizi.png")

        .achievement-list-content
          .achievement-list-name
            span {{ item.name }}
            //- 未达标
            .achievement-list-icon-box(
              v-if="item.status === -1",
              @click="showDetail(item)"
            )
              img(src="~@/assets/img/achievement/achievement_icon3.png")
            //- 达标 未领取
            .achievement-list-icon-box(
              v-else-if="item.status === 1",
              @click="getPoint(item)"
            )
              img(
                src="~@/assets/img/achievement/achievement_icon2.png",
                :class="'rubberBand' + (i + 1)"
              )
            //- 达标 已领取
            .achievement-list-icon-box(v-else, @click="showDetail(item)")
              img(src="~@/assets/img/achievement/achievement_icon2.png")
          .list-progress
            van-progress(
              color="#F37140",
              track-color="#EAEAEA",
              :percentage="item.task_num ? item.task_num_ : 0",
              stroke-width="12",
              :show-pivot="false"
            )
            img.progress-icon(
              :style="item.task_num ? 'left:' + item.task_num_ + '%;' : ''",
              src="~@/assets/img/achievement/achievement_icon16.png"
            )
          .achievement-list-round
            span {{ item.task_num ? 0 : '' }}
            span {{ item.task_num ? item.dis : '' }}
    dialogSmall(
        :show="show",
        @close="closeModal",
        :rowData="rowData",
        :level="level",
        @updataCount="getData"
    )
    dialogPoint(:show="showPoint", :rowData="rowData", @close="closeModal")
    dialogRule(:show="showRule", @close="closeModal")
</template>
<script>
import mixins from "../../mixins";
import dialogSmall from "./dialogSmall.vue";
import dialogPoint from "./dialogPoint.vue";
import dialogRule from "./dialogRule.vue";
import { growTaskInfo } from "@/api/components/achievement";
import { progressMedal, icon7, icon8, icon5, icon5_mid } from "../../data";
export default {
  mixins: [mixins],
  components: {
    dialogSmall,
    dialogPoint,
    dialogRule,
  },
  computed: {
    level() {
      let i = -1;
      this.detail &&
        this.progressMedal &&
        this.progressMedal.forEach((item) => {
          if (item.task_num && this.detail.task_num >= item.task_num) {
            i++;
          }
        });
      return i;
    },
  },
  data() {
    return {
      icon5_mid,
      progressMedal,
      icon5,
      icon7,
      icon8,
      show: false,
      showPoint: false,
      showRule: false,
      rowData: { integral: 0 },
      percentage: 0,
      detail: {
        task_num: 0,
      },
      step: [],
    };
  },
  async created() {
    this.getToken().then(() => {
      this.getData();
    });
  },
  methods: {
    closeModal() {
      this.show = false;
      this.showPoint = false;
      this.showRule = false;
    },
    getData() {
      growTaskInfo().then(({ data }) => {
        this.step = [];
        this.percentage = 0;
        this.detail = data.data;
        this.detail.list &&
          this.progressMedal.forEach((item, i) => {
            if (this.detail.list[i]) {
              this.progressMedal[i] = Object.assign(
                {},
                this.progressMedal[i],
                this.detail.list[i]
              );
              // 阶段数量
              const radio = this.getDis(
                this.progressMedal,
                i,
                this.detail.task_num
              );
              this.progressMedal[i].dis = radio.dis;
              this.progressMedal[i].task_num_ = this.getTaskNum(
                radio.task_num,
                radio.dis
              );
              this.percentage += (this.progressMedal[i].task_num_ * 25) / 100;
              this.step.push(this.progressMedal[i].task_num);
            }
          });
        console.log(this.progressMedal);
      });
    },

    getPoint(row) {
      if (row.status === 1) {
        this.rowData = row;
        this.show = true;
      }
    },
    showRules() {
      this.showRule = true;
    },
    showDetail(row) {
      if (row.task_num) {
        this.rowData = row;
        this.showPoint = true;
      }
    },
  },
};
</script>
<style lang="less">
.achievement {
  .achievement-content {
    .list-progress {
      position: relative;
      .progress-icon {
        height: 25px;
        width: 25px;
        position: absolute;
        bottom: 0px;
        margin-left: -12.5px;
        left: 0;
      }
    }
    .achievement-box {
      .achievement-list {
        height: 100px;
        width: 345px;
        position: relative;
        box-shadow: 0px -1px 20px -9px rgba(82, 0, 0, 0.28);
        border: 1px solid #f5f5f5;
        border-radius: 15px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 14px;
        padding-right: 20px;
        margin-bottom: 10px;
        .achievement-list-icon {
          position: absolute;
          left: 12px;
          top: 17px;
          width: 56px;
        }
        .achievement-list-icon-active {
          position: absolute;
          left: 5px;
          text-align: center;
          top: 5px;
          img:first-child {
            width: 75px;
            display: block;
          }
          img:last-child {
            width: 48px;
            display: block;
            margin: auto;
          }
        }
        .achievement-list-content {
          padding-left: 72px;
          flex: 1;
          .achievement-list-round {
            display: flex;
            justify-content: space-between;
            line-height: 30px;
            height: 30px;
          }
        }
        .achievement-list-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #aaaaaa;
          line-height: 50px;
          .achievement-list-icon-box {
            width: 50px;
            height: 50px;
            text-align: right;
            img {
              width: 24px;
              height: 22px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .achievement-item {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 15px;
      box-sizing: border-box;
      margin-top: 37px;
      width: 344px;
      height: 177px;
      background-image: url("~@/assets/img/achievement/achievement_icon4.png");
      background-size: 100% 100%;
      position: relative;
      .big-icon {
        height: 78px;
        position: absolute;
        right: 12px;
        top: -20px;
      }
      .progress {
        margin-top: 30px;
        position: relative;
        .medal {
          text-align: center;
          color: #ffffff;
          position: absolute;
          top: -9px;
          margin-left: -30px;
          width: 60px;
          .medal-icon {
            width: 30px;
            height: 30px;
          }
        }
        .medal:last-child {
          div {
            position: relative;
            left: -12px;
          }
        }
      }
      .van-progress__portion {
        background: linear-gradient(90deg, #ffcbc7 0%, #ffffff 100%) !important;
      }
      .achievement-item-count {
        color: #ffffff;
        font-size: 30px;
        line-height: 42px;
        font-weight: bold;
        position: relative;
        display: inline-block;
        .tip-arrow {
          width: 0;
          height: 0;
          border: 3px solid #ffffff;
          border-right-width: 6px;
          position: absolute;
          right: -15px;
          top: 15.5px;
          border-left-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
        .tip-rule {
          position: absolute;
          right: -75px;
          width: 60px;
          text-align: center;
          top: 6px;
          font-size: 12px;
          background-color: #ffffff;
          color: #666666;
          line-height: 26px;
          border-radius: 8px;
          font-weight: normal;
        }
      }
      .achievement-item-tip1 {
        color: #ffffff;
        font-size: 12px;
        line-height: 17px;
        margin-top: 3px;
      }
    }
  }
  .grayscale {
    filter: grayscale(100%);
    background-color: #f5f5f5;
    box-shadow: none !important;
    opacity: 0.5;
  }
  .bounceInDown {
    animation: bounceInDown 3s ease-in-out forwards;
  }
  @keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px);
    }

    60% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  .rubberBand1 {
    animation: 2s rubberBand ease-in-out infinite;
  }
  .rubberBand2 {
    animation: 2s rubberBand 0.4s ease-in-out infinite;
  }
  .rubberBand3 {
    animation: 2s rubberBand 0.8s ease-in-out infinite;
  }
  .rubberBand4 {
    animation: 2s rubberBand 1.2s ease-in-out infinite;
  }
  @keyframes rubberBand {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    15% {
      -webkit-transform: scaleX(1.25) scaleY(0.75);
      -ms-transform: scaleX(1.25) scaleY(0.75);
      transform: scaleX(1.25) scaleY(0.75);
    }

    20% {
      -webkit-transform: scaleX(0.75) scaleY(1.25);
      -ms-transform: scaleX(0.75) scaleY(1.25);
      transform: scaleX(0.75) scaleY(1.25);
    }

    30% {
      -webkit-transform: scaleX(1.15) scaleY(0.85);
      -ms-transform: scaleX(1.15) scaleY(0.85);
      transform: scaleX(1.15) scaleY(0.85);
    }

    50% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }

  .icon5-float {
    animation: icon5-float 3s linear infinite;
  }
  @keyframes icon5-float {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .shake {
    animation: 1s shake ease-in-out;
  }
  @keyframes shake {
    0%,
    100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
}
</style>
