import request from '@/plugin/axios'
//正式网页地址
//https://embedded.smallv.tongmingmedia.com/
//测试打包 npm run build:stage
//正式打包 npm run build


//代码中有用到的url
export const URL = process.env.NODE_ENV == "production" ? 'https://embedded.smallv.tongmingmedia.com/': 'http://172.28.200.4:8085/'
// const APP_API = process.env.NODE_ENV == "production" ? "https://vs.tommmd.com/api" : "http://172.28.200.4:82/api"
// const APP_API = process.env.NODE_ENV == "production" ? "http://172.28.200.4:82/api" : "http://172.28.200.4:82/api"
const APP_API = process.env.VUE_APP_BASE_API
/**
 * getData
 * 获取数据
 * @param {*} params
 */
export function get({
  url,
  params,
}) {
  return request({
    baseURL: APP_API,
    url: url,
    method: 'get',
    params: params,
  })
}

/**
 * post分类
 * 提交数据
 * @param {*} params
 */
export function post({
  url,
  data
}) {
  return request({
    baseURL: APP_API,
    url: url,
    method: 'post',
    data: data
  })
}
