import Vue from 'vue'
import 'vant/lib/index.css';
import {
    CheckboxGroup,
    Checkbox,
    RadioGroup,
    Radio,
    Form,
    Field,
    Overlay,
    Loading,
    Button,
    NavBar,
    Area,
    List,
    Popup,
    PullRefresh,
    Icon,
    Progress,
    Dialog
} from 'vant'
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Loading)
Vue.use(NavBar)
Vue.use(Area)
Vue.use(Popup)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Progress)