<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-point
    img.dialog-point-icon1(
      src="~@/assets/img/achievement/achievement_icon13.png"
    )
    .dialog-point-title 任务小将
    .dialog-point-box-top
      .dialog-point-box
        .dialog-point-item.mid 达标项目
        .dialog-point-item.mid 奖励
      .dialog-point-box(v-for="(item, i) in list" :key="item.id" :class="i%2===0?'bg':''")
        .dialog-point-item {{item.task_num}}
        .dialog-point-item
          img(v-if="item.status === 2" src="~@/assets/img/achievement/achievement_icon14.png")
          img(v-else src="~@/assets/img/achievement/achievement_icon15.png")
          span {{item.integral}}积分
    .dialog-point-icon2(@click="close")
      img(src="~@/assets/img/achievement/achievement_icon11.png")
</template>
<script>
import { growTaskList } from "@/api/components/achievement";
import _ from "lodash";
export default {
  props: ["show", "rowData"],
  data() {
    return {
      isShow: false,
      list: []
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
      if (newData) {
        growTaskList({
          id: this.rowData.id,
        }).then(({data}) => {
          this.list = data.data
        })
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function () {
      this.$emit("close");
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-point {
    position: relative;
    width: 339px;
    padding-bottom: 30px;
    background-color: #fef3e5;
    border-radius: 25px;
    text-align: center;
    .dialog-point-box-top {
      padding-top: 30px;
    }
    .bg {
      background-color: #ffffff;
    }
    .dialog-point-icon2 {
      height: 42px;
      width: 172px;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: -20px;
      margin-left: -86px;
      img {
        width: 100%;
      }
    }
    .dialog-point-box {
      display: flex;
      width: 100%;
      line-height: 48px;
      align-items: center;
      justify-content: space-around;
      color: #f37140;
      font-size: 14px;
      .dialog-point-item {
        flex: 1;
        img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }
      .mid {
        font-size: 15px;
        color: #d87a7a;
        font-weight: bold;
      }
    }
    .dialog-point-icon1 {
      height: 69px;
      width: 289px;
      margin-top: -33px;
    }
    .dialog-point-title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      margin-top: -60px;
    }
  }
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>