<template lang="pug">
.training-text-box
  .training-text-tab(@click="toggle")
    img(src="~@/assets/img/training/icon_2.png")
    .text 文字教学
    img.right-img(v-if="isShowText", src="~@/assets/img/training/arrowup.png")
    img.right-img(v-else, src="~@/assets/img/training/icon_1.png")
  .training-text-content.fadeInAnimate(
    v-if="isShowText",
    @click="getImg($event)"
  )
    .htmls(v-html="detail.content")
</template>
<script>
import { ImagePreview } from "vant";
const preview = (index, url) => {
  ImagePreview({
    images: url,
    startPosition: index,
    loop: false,
    closeOnPopstate: true,
    onClose() {},
  });
};
export default {
  data() {
    return {
      isShowText: false,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getImg($event) {
      if ($event.target) {
        if ($event.target.nodeName === "IMG") {
          preview(0, [$event.target.currentSrc]);
        } else if ($event.target.children[0].nodeName === "IMG") {
          preview(0, [$event.target.children[0].currentSrc]);
        }
      }
    },
    toggle() {
      this.isShowText = !this.isShowText;
    },
  },
};
</script>
<style lang="less">
.training-text-box {
  border-radius: 6px;
  background: #ffffff;
}
.training-text-tab {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 8px;
  line-height: 40px;
  img {
    width: 16px;
    height: 16px;
  }
  .right-img {
    height: 14px;
    width: 14px;
  }
  .text {
    flex: 1;
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
  }
}
.training-text-content {
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 21px 0;
  .htmls {
    width: 100%;
    img {
      max-width: 100% !important;
    }
  }
}
</style>