<template>
  <div class="login">
    <div class="login_imgone">
      <img v-if="info.headpic" :src="info.headpic" class="l_img" />
      <img v-else src="~@/assets/img/pic.jpg" class="l_img" />
      <span class="l_span">{{ info.nickname }}</span>
    </div>
    <div class="login_text">邀请你成为V智圈用户</div>
    <div class="login_pad">
      <div class="login_top">
        <div class="login_div">
          <input
            v-model="form.phone"
            @input="handleInput"
            class="login_input"
            @blur="handleBlur()"
            maxlength="11"
            placeholder="请输入您的手机号码"
          />
          <div v-if="mshow" class="log_img" @click="handlePhone">
            <img src="~@/assets/img/close.png" class="log_img1" />
          </div>
          <span class="bar"></span>
        </div>
      </div>
      <div class="login_top">
        <div class="login_div">
          <input
            class="login_input"
            v-model="form.code"
            @input="handleInputCode"
            placeholder="请输入验证码"
            @blur="handleBlur()"
          />
          <span class="span_top" v-if="sendMsgDisabled" @click="handleCode"
            >获取验证码</span
          >
          <span v-else class="span_hui">{{ time }}s</span>
          <span class="bar"></span>
        </div>
      </div>
      <div class="login_btn">
        <button class="noActive btn_sub" @click="handleSub">登录</button>
        <div
          class="left_text container"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
          "
        >
          <input type="checkbox" style="margin-top: 12px" v-model="check" />
          <span style="display: inline-block; padding-top: 10px">登录并同意</span>
          <span
            @click="handlePrivacy"
            style="display: inline-block; padding-top: 10px"
            class="color_F01B60"
            >用户政策隐私协议</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postSmsLogin, postSms, getInfoCode } from "@/api/components/recommend";
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      img: "",
      form: {
        phone: "",
        code: "",
      },
      mshow: false,
      cshow: false,
      sendMsgDisabled: true,
      time: "",
      check: false,
      info: {},
      invitation_code: "",
      options: [
        {
          label: "",
          value: "1",
        },
      ],
      noActive: true,
    };
  },
  created() {
    this.invitation_code = this.$route.query.code;
    getInfoCode({
      code: this.invitation_code,
    }).then((res) => {
      let { data } = res.data;
      this.info = data;
    });
  },
  methods: {
    handlePrivacy() {
      this.$router.push({
        path: "/privacy_agreement",
      });
    },
    handleCode() {
      if (this.form.phone) {
        postSms({
          mobile: this.form.phone,
          type: 1,
        }).then((res) => {
          if (res.data.code == 10002) {
            this.timeout();
          } else {
            this.$toast({
              message: res.data.message,
              duration: 5000,
              className: "noticeError",
            });
          }
        });
      } else {
        this.$toast({
          message: "不能为空",
          duration: 2000,
          className: "noticeError",
        });
      }
    },
    timeout: function () {
      //倒计时
      let sec = 60;
      for (let i = 0; i <= sec; i++) {
        let _this = this; //定义
        setTimeout(function () {
          _this.time = sec;
          if (sec != 0) {
            _this.time = sec;
            _this.sendMsgDisabled = false;
            sec--;
          } else {
            sec = 60; //如果倒计时结束就让  获取验证码显示出来
            _this.sendMsgDisabled = true;
          }
        }, i * 1000);
      }
    },
    handleInput() {
      if (this.form.phone) {
        this.mshow = true;
      }
    },
    handleInputCode(val) {
      if (val) {
        this.cshow = true;
      }
    },
    handleClose() {
      this.form.code = null;
    },
    handlePhone() {
      this.form.phone = null;
    },
    handleBlur() {
      window.scroll(0, 0);
      this.noActive = !this.noActive;
    },
    handleSub() {
      if (this.check) {
        Indicator.open();
        postSmsLogin({
          mobile: this.form.phone,
          code: this.form.code,
          invitation_code: this.invitation_code,
        }).then((res) => {
          Indicator.close();
          if (res.data.code == 10002 || res.data.code == 10105) {
            this.$router.push("/invite_down");
          } else {
            this.$toast({
              message: res.data.message,
              duration: 2000,
              className: "noticeError",
            });
          }
        }).catch(()=>{
           Indicator.close();
        })
      } else {
        Indicator.close();
        this.$toast({
          message: "必须同意",
          duration: 1000,
          className: "noticeError",
        });
      }
    },
  },
};
</script>
<style scope>
input:focus ~ .bar:before,
textarea:focus ~ .bar:before {
  width: 100%;
}
.login {
  width: 100vw;
  height: 100vh;
  background: #fff;
}
.login_imgone {
  padding: 60px 20px 20px;
  display: flex;
  align-items: center;
}
.l_img {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
}
.l_span {
  padding-left: 10px;
  font-weight: 600;
}
.login_text {
  font-size: 4.8vw;
  font-weight: 500;
  padding: 0 20px 40px;
}
.login_pad {
  padding: 0 20px;
}
.login_top {
  padding-top: 20px;
}
.login_div {
  border-bottom: 1px solid #f5f7faff;
  position: relative;
  line-height: 12vw;
  display: block;
  width: 100%;
}
.one_boxdiv {
  border-bottom: 1px solid #f5f7faff;
  position: relative;
  line-height: 12vw;
  display: block;
  width: 100%;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #f01b60;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  left: 0%;
}
.span_top {
  position: absolute;
  top: 0vw;
  right: 0;
  color: #f01b60;
}
.span_hui {
  position: absolute;
  top: 0vw;
  right: 0;
  color: rgba(153, 153, 153, 1);
}
.login_input {
  border: 0;
  font-size: 3.8vw;
  width: 100%;
  outline: none;
  color: #333333ff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 10vw;
}
.login_btn {
  padding-top: 50px;
}
.btn_sub {
  background-color: #f01b60;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 3.8vw;
  border: 0;
  width: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.load {
  width: 20px;
}
.log_img {
  position: absolute;
  top: 3vw;
  right: 5px;
  width: 12px;
  z-index: 400;
}
.log_img1 {
  width: 20px;
  right: 0;
}
.p_span {
  color: rgba(170, 170, 170, 1);
}
.p_span1 {
  color: rgba(119, 119, 119, 1);
}
.login_btn .mint-cell:last-child {
  background-image: none;
}
.mint-checkbox-core {
  width: 12px !important;
  height: 12px !important;
}
.mint-checkbox-core::after {
  width: 3px !important;
  height: 6px !important;
  top: 1px !important;
  left: 3px !important;
}
.mint-checklist-label {
  display: block;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.mint-checkbox-label {
  color: #aaa;
  font-size: 12px;
  margin-left: 0px;
}
.mint-checkbox-input:checked + .mint-checkbox-core {
  background-color: #000 !important;
  border-color: #000 !important;
}
.mint-cell {
  min-height: 28px !important;
}
.color_F01B60 {
  color: #f01b60;
}
</style>
