<template lang="pug">
.tiger.fadeInAnimate
  .backs(@click="onClickLeft")
    van-icon(name="arrow-left")
  tiger
  detail
</template>
<script>
import tiger from "./modules/tiger.vue";
import detail from "./modules/detail.vue";
import mixins from "../mixins";
export default {
  mixins: [mixins],
  data() {
    return {};
  },
  components: {
    tiger,
    detail,
  },
};
</script>
<style lang="less">
.tiger {
  position: relative;
  background: url("~@/assets/img/active/active_tiger_bg.png");
  background-size: 100% 100%;
  width: 375px;
  height: 979px;
  padding-top: 125px;
  box-sizing: border-box;
  .backs {
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 20px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
  }
}
</style>