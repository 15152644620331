<template lang="pug">
    .mid-item-finish
        .mid-item-finish1.pos
            .name 活动期间您一共执行
            .count
            span {{finishInfo.task_num}}
            span 个任务
        .mid-item-finish2.pos
            .name 活动期间您一共获得
            .count
            span {{finishInfo.number}}
            span 个奖励碎片
        .mid-item-finish3.pos
            .name 活动期间获得任务金额
            .count
            span {{finishInfo.money}}
            span 元
        .mid-item-finish4 奖励明细： {{finishInfo.reward}}
</template>
<script>
export default {
  props: ["finishInfo"],
  data() {
    return {};
  },
};
</script>
<style lang="less">
.mid-item-finish {
  background: url("~@/assets/img/jigsaw/jigsaw_main_list2.png");
  background-size: 100% 100%;
  margin-left: 30px;
  margin-top: 20px;
  width: 315px;
  height: 529px;
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 60px;
  box-sizing: border-box;
  .pos {
    position: absolute;
    left: 130px;
    font-size: 15px;
    color: #90532b;
    font-weight: bold;
    letter-spacing: 1px;
    .count {
      span:first-child {
        font-size: 22px;
      }
    }
  }
  .mid-item-finish1 {
    top: 78px;
  }
  .mid-item-finish2 {
    top: 230px;
  }
  .mid-item-finish3 {
    top: 370px;
  }
  .mid-item-finish4 {
    font-size: 15px;
    color: #90532b;
    font-weight: bold;
    letter-spacing: 1px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
}
</style>
