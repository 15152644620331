<template lang="pug">
.achievement.fadeInAnimate
  .achievement-content
    .achievement-tabs
      .achievement-tab(:class="index===0? 'active' : ''" @click="index = 0")
        img(v-if="index === 0" src="~@/assets/img/achievement/achievement_icon1.png")
        .text 任务类
      .achievement-tab(:class="index===1? 'active2' : ''" @click="index = 1")
        img(v-if="index === 1" src="~@/assets/img/achievement2/achievement2_icon1.png")
        .text 账号类
      .achievement-tab
        .text 敬请期待
    tab1(v-if="index===0" class="fadeInAnimate")
    tab2(v-if="index===1" class="fadeInAnimate")
</template>
<script>
import tab1 from "./modules/tab1.vue";
import tab2 from "./modules/tab2.vue";
export default {
  components: {
    tab1,
    tab2
  },
  data() {
    return {
      index: 0
    };
  },
  methods: {

  },
};
</script>
<style lang="less">
.achievement {
  background-color: #ffffff;
  padding-bottom: 30px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 20px;
  .achievement-content {
    padding: 15px;
    .achievement-tabs {
      width: 315px;
      padding: 0 15px;
      display: flex;
      justify-content: space-around;
      transition: all .17s ease-in-out;
      .active {
        background-color: #fdf1f1;
        border-radius: 22px;
        color: #ee4f3e !important;
        width: 100px;
      }
      .active2 {
        background-color: #F5F5F5;
        border-radius: 22px;
        color: #333333 !important;
        width: 100px;
        img {
          height: 14px!important;
          width: 18px!important;
        }
      }
      .achievement-tab {
        text-align: center;
        font-size: 14px;
        line-height: 44px;
        color: #777777;
        width: 100px;
        .text {
          display: inline-block;
          vertical-align: middle;
        }
        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .grayscale {
    filter: grayscale(100%);
    background-color: #f5f5f5;
    box-shadow: none !important;
    opacity: 0.5;
  }
}
</style>
