<template>
  <div>
    <div class="search_header">
      <mt-header title="五一活动战报">
        <router-link to="/glorious" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="back">
      <div class="top">
        <img src="@/assets/img/record/1.png" />
      </div>
      <div class="bottom">
        <div class="bot1">
          你用{{ BadgeData.taskNum }}个任务获得了{{ BadgeData.randNum }}次徽章抽取机会
        </div>
        <div class="bot2">这些徽章让你拥有了{{ BadgeData.turntableNum }}次转盘次数</div>
        <div class="bot3">你的最终战况是:</div>
        <div>
          <p class="p">-任务收益：{{ BadgeData.money }}元</p>
          <p class="p">-徽章数量：{{ BadgeData.badgeNum }}个</p>
          <p class="p">-积分获得：{{ BadgeData.integral }}枚</p>
          <p class="p">-奖励卡数量：{{ BadgeData.card }}张</p>
          <p class="p">-其他奖励：{{ BadgeData.other }}</p>
        </div>
        <div style="padding-top: 65px; display: flex; justify-content: center">
          五一的假不是假，是收获满满的有钱花，你get到了吗？！
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBadgeStatistics } from "@/api/components/glorious";
export default {
  data() {
    return {
      BadgeData: {},
    };
  },
  created() {
    this.handleBadgeStatistics();
  },
  methods: {
    handleBadgeStatistics() {
      getBadgeStatistics().then((res) => {
        let { code, data } = res.data;
        if (code == 10002) {
          this.BadgeData = data;
        }
      });
    },
  },
};
</script>
<style lang="less">
.back {
  position: relative;
  background: url(~@/assets/img/rotayTable/back.png) no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 0px auto;
  margin-top: 40px;
  overflow: auto;
}
.top {
  padding-top: 20px;
  img {
    width: 350px;
  }
}
.bottom {
  background: url("~@/assets/img/record/2.png") no-repeat;
  background-size: 100% auto;
  width: 95%;
  height: 100vh;
  text-align: center;
  margin: 0px auto;
}
.bot1 {
  padding-top: 60px;
  color: #111858;
  font-size: 14px;
}
.bot2 {
  color: #111858;
  font-size: 14px;
  padding-top: 10px;
}
.bot3 {
  color: #1c1b54;
  padding-top: 35px;
  font-size: 16px;
  text-align: left;
  padding-left: 55px;
  font-weight: bold;
}
.p {
  font-size: 12px;
  text-align: left;
  padding-left: 55px;
  color: #1c1b54;
}
</style>
