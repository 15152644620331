
import {
    get
} from '@/api/http'
// 碎片纪录
export function activityJigsawLog(params) {
    return get({
        url: "/activity/jigsaw_log",
        params,
    })
}

// 填写奖品备注信息
export function activityJigsawInformation(params) {
    return get({
        url: "/activity/jigsaw_information",
        params,
    })
}

// 获取拼图信息
export function activityJigsaw(params) {
    return get({
        url: "/activity/jigsaw",
        params,
    })
}
// 获取奖品信息
export function activityJigsawReward(params) {
    return get({
        url: "/activity/jigsaw_reward",
        params,
    })
}


// 合成
export function activityJigsawSynthetic(params) {
    return get({
        url: "/activity/jigsaw_synthetic",
        params,
    })
}


// 拼图活动统计
export function activityJigsawCount(params) {
    return get({
        url: "/activity/jigsaw_count",
        params,
    })
}
