import Vue from 'vue'
import Router from 'vue-router'
// import blindBox from "@/components/blindBox"
// import activeRecord from "@/components/activeRecord"
// import blindRules from "@/components/blindBox/blindRules"
// import blindExplain from "@/components/blindBox/blindExplain"
import annual_bill from "@/components/annual_bill" /***年度账单 */
import newbie_guide from "@/components/invite/newbie_guide" //小V招新
import invite_friends from "@/components/invite/invite_friends" //邀请好友
import face_invite from "@/components/invite/face_invite" //面对面邀请
import invite_detail from "@/components/invite/invite_detail" //明细
import invite_login from "@/components/invite/invite_login"
import invite_down from "@/components/invite/invite_down"
import privacy_agreement from "@/components/invite/privacy_agreement"
import invite_rules from "@/components/invite/invite_rules"
import glorious from "@/components/may_day/glorious"
import rotaryTable from "@/components/may_day/rotaryTable"
import glorious_detail from "@/components/may_day/glorious_detail"
import record from "@/components/may_day/record"
import reflectThat from "@/components/reflectThat" //提现说明
import activity from "@/components/activity" //奖励卡说明
import active from "@/components/activity/active/index" // 活动首页
import tiger from  "@/components/activity/tiger/index"
import rules from "@/components/activity/rules/index"
import form from "@/components/activity/form/index"
import intro from "@/components/activity/intro/index"
import history from "@/components/activity/history/index"
import achievement from "@/components/achievement/center/index"
import jigsaw from "@/components/jigsaw/active/index" // 双十一活动首页
import jigsawRules from "@/components/jigsaw/rules/index"  // 双十一活动规则
import jigsawHistory from "@/components/jigsaw/history/index"  // 双十一活动规则
import jigsawForm from "@/components/jigsaw/form/index"  // 双十一活动规则
import training from "@/components/training" //培训教程
Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [{
      path: '/annual_bill',
      name: 'annual_bill',
      component: annual_bill
    }, {
      path: '/newbie_guide',
      name: 'newbie_guide',
      component: newbie_guide
    }, {
      path: '/invite_friends',
      name: 'invite_friends',
      component: invite_friends,
      meta: {
        title: '邀请好友得红包'
      }
    }, {
      path: '/face_invite',
      name: 'face_invite',
      component: face_invite,
      meta: {
        title: '邀请得红包'
      }
    }, {
      path: '/invite_detail',
      name: 'invite_detail',
      component: invite_detail,
      meta: {
        title: '邀请明细'
      }
    }, {
      path: '/invite_login',
      name: 'invite_login',
      component: invite_login,
      meta: {
        title: '登录'
      }
    }, {
      path: '/invite_down',
      name: 'invite_down',
      component: invite_down,
      meta: {
        title: '下载'
      }
    }, {
      path: '/privacy_agreement',
      name: 'privacy_agreement',
      component: privacy_agreement
    },
    {
      path: '/invite_rules',
      name: 'invite_rules',
      component: invite_rules,
      meta: {
        title: '规则说明'
      }
    }, {
      path: '/glorious',
      name: "glorious",
      component: glorious
    }, {
      path: '/rotaryTable',
      name: "rotaryTable",
      component: rotaryTable

    }, {
      path: '/glorious_detail',
      name: "glorious_detail",
      component: glorious_detail
    }, {
      path: '/record',
      name: 'record',
      component: record
    },
    {
      path: '/reflectThat',
      name: 'reflectThat',
      component: reflectThat,
    },
    {
      path: '/training',
      name: 'training',
      component: training,
      meta: {
        title: '基础训练'
      }
    },
    {
      path: '/activity',
      name: 'activity',
      component: activity
    },
    {
      path: '/activity/active',
      name: 'active',
      component: active
    },
    {
      path: '/activity/tiger',
      name: 'tiger',
      component: tiger
    },
    {
      path: '/activity/rules',
      name: 'rules',
      component: rules
    },
    {
      path: '/activity/form',
      name: 'form',
      component: form
    },
    {
      path: '/activity/intro',
      name: 'intro',
      component: intro
    },
    {
      path: '/activity/history',
      name: 'history',
      component: history
    },
    {
      path: '/jigsaw/active',
      name: 'jigsawActive',
      component: jigsaw
    },
    {
      path: '/jigsaw/rules',
      name: 'jigsawRules',
      component: jigsawRules
    },
    {
      path: '/jigsaw/history',
      name: 'jigsawHistory',
      component: jigsawHistory
    },
    {
      path: '/jigsaw/form',
      name: 'jigsawForm',
      component: jigsawForm
    },
    {
      path: '/achievement',
      name: 'achievement',
      component: achievement
    },
  ]
})