<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-rule(:class="isShow?'animate':''")
    .dialog-rule-item.bold 成就规则说明：
    .dialog-rule-item 小V成就中心上线啦，快来点亮成就徽章，获得丰厚奖励！
    .dialog-rule-item 目前已开放小V任务类成就徽章，可前往任务类栏目进行解锁点亮，之后会不断开放账号类等更多有趣的成就徽章，
    .dialog-rule-item 星星点点聚成光，小小徽章的点亮见证着小V生涯的每一次成长，期待大家一路不断打怪升级，点亮满屏小骄傲。
    .dialog-rule-item.bold.mt20 任务类成就徽章获取规则：
    .dialog-rule-item 该成就分为四个阶段（任务小将-任务大师-任务宗师-任务王者），达成每个阶段任务目标数量，即可点亮对应阶段徽章，并获得相应积分奖励，奖励不重置，每人达标每阶段只能获得一次奖励及徽章。
    .dialog-rule-item 任务小将：完成任务数量达5000，即可解锁任务小将徽章，并获得1000积分奖励（该阶段内含阶梯式奖励，共分为7个阶段，达到阶段相应任务量即可解锁对应奖励，点滴进步都可收获快乐哦！）
    .dialog-rule-item 任务大师：完成任务数量达20000，即可解锁任务大师徽章，并获得6000积分奖励
    .dialog-rule-item 任务宗师：完成任务数量达90000，即可解锁任务宗师徽章，并获得12000积分奖励
    .dialog-rule-item 任务王者：完成任务数量达200000，即可解锁任务王者徽章，并获得26000积分奖励
    .dialog-rule-item-close(@click="close")
      img(src="~@/assets/img/achievement/achievement_icon12.png")
</template>
<script>
export default {
  props: ["show", "rowData"],
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-rule {
    position: relative;
    width: 365px;
    background-color: #ffffff;
    padding: 25px 20px;
    box-sizing: border-box;
    text-align: justify;
    border-radius: 20px;
    .bold {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .mt20 {
      margin-top: 15px;
    }
    .dialog-rule-item {
      font-size: 12px;
      line-height: 20px;
    }
    .dialog-rule-item-close {
      height: 30px;
      width: 30px;
      position: absolute;
      bottom: -50px;
      margin-left: -15px;
      left: 50%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .close {
      font-size: 20px;
    }
  }
}
.animate {
  animation: flipInY 1s ease-in-out;
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>
