<template lang="pug">
van-overlay(:show="show", @click="show = false")
  .model-wrapper(@click.stop)
    .block
      .content
        img(src="~@/assets/img/training/icon_modal.png")
        .title 提示
        .intro {{intro}}
      .btn-box
        .comfirm(@click="show = false") 我知道了
</template>
<script>
export default {
  data() {
    return {
      show: false,
      intro: '请完成阅读和答题！'
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleModel(e) {
      if(e) this.intro = e
      this.show = !this.show;
    },
  },
};
</script>
<style lang="less" scoped>
.van-overlay {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.model-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .btn-box {
    text-align: center;
    .comfirm {
      width: 120px;
      height: 34px;
      background: #d8d8d8 linear-gradient(161deg, #ff5858 0%, #f857a6 100%);
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.3);
      border-radius: 17px;
      line-height: 35px;
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 2px;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .cancel {
      color: #777777;
      font-size: 14px;
      padding: 6px 30px;
    }
  }
  .block {
    height: 245px;
    width: 226px;
    background: #ffffff;
    border-radius: 14px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    .content {
      height: 179px;
      width: 100%;
      text-align: center;
      background: #f9f9f9;
      padding-top: 30px;
      box-sizing: border-box;
      .title {
        font-size: 16px;
        color: #333333;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .intro {}
      color: #777777;
      img {
        height: 40px;
        width: 40px;
      }
    }
  }
}
</style>