<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .scratchable
    .scratchable-bg(@touchstart="updatePoints")
      vue-scratchable.gua-box(:hideOptions="hideOptions" )
        .cir1
        .cir2
        .gua-item
          img(src="~@/assets/img/jigsaw/gua_item.png")
          .gua-name {{name}}
    .tip 刮开有奖
    .btn(@click="submitFlag") 完成
</template>
<script>
import VueScratchable from "vue-scratchable";
import { activityJigsawSynthetic } from "@/api/components/jigsaw";
import _ from "lodash";
export default {
  props: ["show"],
  components: {
    VueScratchable,
  },
  data() {
    return {
      isShow: false,
      isTouch: false,
      name: "",
      hideOptions: {
        type: "color",
        value: "#5d2419",
      },
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async updatePoints() {
      if(!this.isTouch) {
        const { data } = await activityJigsawSynthetic()
        this.name = data.data.name
        this.isTouch = true
        this.$emit("updata")
      }
    },
    submitFlag: _.debounce(async function() {
      this.$emit("close");
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;
  .scratchable {
    position: relative;
    background-image: url("~@/assets/img/jigsaw/gua_bg.png");
    background-size: 100%;
    height: 318px;
    width: 276px;
    .tip {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 26px;
      color: #5d2419;
      text-align: center;
      font-size: 15px;
    }
    .btn {
      width: 114px;
      height: 41px;
      background: linear-gradient(180deg, #ff6c20 0%, #ff2001 100%);
      border-radius: 25px;
      border: 3px solid #ffffff;
      font-size: 16px;
      line-height: 41px;
      color: #ffffff;
      position: absolute;
      bottom: -53px;
      left: 50%;
      transform: translateX(-50%);
    }
    .scratchable-bg {
      width: 200px;
      height: 147px;
      background: #ffebd8;
      box-shadow: 0px 2px 8px 0px rgba(255, 165, 75, 0.42);
      border-radius: 9px;
      margin: auto;
      position: relative;
      padding-top: 10px;
      box-sizing: border-box;
      top: 115px;
      .cir1 {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #ffebd8;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
      .cir2 {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #ffebd8;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
      .gua-box {
        width: 192px;
        height: 129px;
        margin: auto;
        border-radius: 8px;
        overflow: hidden;
      }
      .gua-item {
        width: 192px;
        height: 129px;
        position: relative;
        .gua-name {
          color: #ffffff;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 150px;
          margin-left: -75px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 22px;
        }
        img {
          width: 192px;
          height: 129px;
        }
      }
    }
  }
}
</style>
