<template lang="pug">
.tiger-item(:class="hasChou ? '' : 'disabled-tiger'")
  .overall
    .cj-box
      .cj-block(v-for="item in 3", :key="item")
        ul.gundong
          li(
            v-for="(i, index) in tigerData",
            :key="index",
            :style="`transform: rotateX(${(360 / tigerData.length) * index}deg)  translateZ(${13.6 * tigerData.length}px)`"
          )
            img(:src="i.image")
    .chou(@click="start")
  img.tiger-icon.tiger-icon0-float(
    src="~@/assets/img/active/active_tiger_icon.png"
  )
  img.tiger-tip(
    v-if="!loading",
    src="~@/assets/img/active/active_tiger_tip.png"
  )
  img.tiger-tip2(v-else, src="~@/assets/img/active/active_tiger_tip.png")
  .box-info-text
    .box-info-title 中奖信息
    .box-info-flex
      .left-text(v-if="boxInfo.content") {{ boxInfo.content || '' }}
      .right-text(v-if="boxInfo.isRemarks === 1", @click="goForm") 备注信息
  dialogTiger(:show="show", @close="closeModal", :info="boxInfo.content")
</template>
<script>
import { tigerData } from "../../data";
import { flagRand, flagBoxInfo } from "@/api/components/active";
import dialogTiger from "./dialogTiger.vue";
export default {
  components: {
    dialogTiger,
  },
  data() {
    return {
      tigerData,
      winner: 0, //指定的奖品 null时为不中奖
      loading: false, //抽奖执行状态，防止用户多次点击
      gundongElement: null, //储存获取到的滚动容器的标签
      animationClass: [], //3个抽奖模块对应的动画属性,方便后来对应添加和移除该class样式
      show: false,
      boxInfo: {
        content: null,
        param: null,
        isRemarks: 1,
      },
    };
  },
  computed: {
    hasChou() {
      return this.loading || this.boxInfo.content;
    },
  },
  mounted() {
    //通过获取奖品个数，来改变css样式中每个奖品动画的旋转角度
    // var(--nums) 实现css动画根据奖品个数，动态改变
    let root = document.querySelector(":root");
    root.style.setProperty("--nums", this.tigerData.length);
    this.updataBox();
  },
  methods: {
    //开始抽奖
    start() {
      if (!this.loading && !this.boxInfo.content) {
        this.gundongElement = document.querySelectorAll(".gundong");
        this.gundongElement.forEach((element, index) => {
          if (this.animationClass[index]) {
            element.classList.remove(this.animationClass[index].class);
          }
        });
        //此处可指定后端返回的指定奖品------------------------------------------------
        // this.winner = this.winner
        this.winCallback();
        this.loading = true;
      }
    },
    //中奖返回方法
    winCallback() {
      setTimeout(() => {
        /* 此处是为了解决当下次抽中的奖励与这次相同，动画不重新执行的 */
        /* 添加一个定时器，是为了解决动画属性的替换效果，实现动画的重新执行 */
        this.gundongElement.forEach((element, index) => {
          element.classList.add(this.getAnimationClass(this.winner, index));
          setTimeout(() => {
            element.style.transform = `rotateX(${this.animationClass[index].rotate}deg)`;
          }, 2000);
        });
      }, 0);
      // 因为动画时间为 3s ，所以这里4s后获取结果，其实结果早就定下了，只是何时显示，告诉用户
      setTimeout(async () => {
        try {
          await flagRand();
          await this.updataBox();
          this.show = true;
          this.loading = false;
        } catch (err) {
          this.loading = false;
        }
      }, 6000);
    },
    //获取对应的class样式，并储存起来，方便后来的class移除
    getAnimationClass(winner, index) {
      //如果是后端指定奖品
      if (winner !== null) {
        this.animationClass[index] = {
          num: winner,
          class: `wr${winner}`,
          rotate: (360 / this.tigerData.length) * -winner,
        };
        //对应css样式中定义的class属性值,如果有更多的话可以继续添加  case 8:   return 'wr8'
        return `wr${winner}`;
      } else {
        /* 此处是当不中奖时，那么3个轮盘会自动随机 */
        let ran = this.random(0, this.tigerData.length - 1);
        /* 此处是为了避免随机时的3个数字一样，因为不中奖的时候3个数字是不可能一样的 */
        if (this.animationClass[0]) {
          while (this.animationClass[0].num === ran) {
            ran = this.random(0, this.tigerData.length - 1);
          }
        }
        this.animationClass[index] = {
          num: ran,
          class: `wr${ran}`,
          rotate: (360 / this.tigerData.length) * -ran,
        };
        //对应css样式中定义的class属性值,如果有更多的话可以继续添加  case 8:   return 'wr8'
        return `wr${ran}`;
      }
    },
    //随机一个整数的方法
    random(min, max) {
      return parseInt(Math.random() * (max - min + 1) + min);
    },
    goForm() {
      this.$router.push("/activity/form");
    },
    async updataBox() {
      const { data } = await flagBoxInfo();
      this.boxInfo = data.data;
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.disabled-tiger {
  background: url("~@/assets/img/active/active_tiger_box.png") !important;
  background-size: 100% 100% !important;
}
.tiger-item {
  background: url("~@/assets/img/active/active_tiger_box_.png");
  background-size: 100% 100%;
  width: 314px;
  margin-left: 30.5px;
  height: 355px;
  box-sizing: border-box;
  padding-top: 89px;
  position: relative;
  .box-info-text {
    color: #ffffff;
    width: 226px;
    padding-left: 43px;
    .box-info-title {
      text-align: center;
      line-height: 30px;
      color: #ffffff;
    }
    .box-info-flex {
      display: flex;
      line-height: 35px;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
    }
    .right-text {
      text-align: right;
    }
  }
  .tiger-icon {
    width: 34px;
    height: 33px;
    position: absolute;
    bottom: 20px;
    left: -12px;
  }
  .tiger-tip {
    width: 27px;
    height: 89px;
    position: absolute;
    top: 40px;
    right: -21px;
  }
  .tiger-tip2 {
    transform: rotateX(180deg);
    width: 27px;
    height: 89px;
    position: absolute;
    top: 121px;
    right: -21px;
  }
  .overall {
    perspective: 3000px;
  }
  /* 抽奖按钮 */
  .chou {
    user-select: none;
    cursor: pointer;
    height: 63px;
    margin: 36px auto 20px;
    width: 100px;
  }
  .cj-box {
    height: 71px;
    display: flex;
    justify-content: space-between;
    padding: 0 58px;
    .cj-block {
      width: 40px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 0 1px;
      .gundong {
        display: inline-block;
        transform-style: preserve-3d;
        width: 40px;
        height: 100px;
        list-style: none;
        padding: 0;
        position: relative;
        text-align: center;
        perspective: 3000px;
        /* 每个奖品格子的样式 */
        li {
          transform-style: preserve-3d;
          // transform-origin: 50% 0%;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          img {
            padding-top: 16px;
            height: 36px;
            width: 36px;
            margin-left: 2px;
          }
        }
      }
    }
  }
  /* 给每个滚动添加一个延迟动画效果 */
  .cj-block:nth-child(1) ul {
    animation-delay: 0.2s;
    li {
      background: #fffaed;
    }
  }
  .cj-block:nth-child(2) ul {
    animation-delay: 0.5s;
    li {
      background: #fffaed;
    }
  }
  .cj-block:nth-child(3) ul {
    animation-delay: 0.8s;
    li {
      background: #fffaed;
    }
  }
  /* 下面的css样式为每个奖品的旋转动画，这里写了对应8个奖品的动画，如果想要更多的话，可以添加 */
  /* 例如： .wr8  @keyframes play8 */
  .wr0,
  .wr1,
  .wr2,
  .wr3,
  .wr4,
  .wr5,
  .wr6,
  .wr7 {
    /* 每个转动的时间 */
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }
  .wr0 {
    animation-name: play0;
  }
  .wr1 {
    animation-name: play1;
  }
  .wr2 {
    animation-name: play2;
  }
  .wr3 {
    animation-name: play3;
  }
  .wr4 {
    animation-name: play4;
  }
  .wr5 {
    animation-name: play5;
  }
  .wr6 {
    animation-name: play6;
  }
  .wr7 {
    animation-name: play7;
  }
}

@keyframes play0 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * 0));
  }
}
@keyframes play1 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -1));
  }
}
@keyframes play2 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -2));
  }
}
@keyframes play3 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -3));
  }
}
@keyframes play4 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -4));
  }
}
@keyframes play5 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -5));
  }
}
@keyframes play6 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -6));
  }
}
@keyframes play7 {
  to {
    transform: rotateX(calc(5 * 360deg + 360deg / var(--nums) * -7));
  }
}
.tiger-icon0-float {
  animation: tiger-icon0-float 3s linear infinite;
}
@keyframes tiger-icon0-float {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
