<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-rule2(:class="isShow?'animate':''")
    .dialog-rule-scroll
      .dialog-rule-item.bold 账号类成就徽章获取规则：
      .dialog-rule-item 账号成就徽章闪亮登场！
      .dialog-rule-item 目前成就系统已经开放了【微博】【小红书】【抖音】【豆瓣】四个平台账号的徽章探索：
      .dialog-rule-item 账号徽章成就共分为三个阶段（账号大师-账号宗师-账号王者）。达成每个阶段账号绑定数量目标，即可解锁对应阶段奖励宝箱，升级徽章专属颜色。四个平台都解锁该阶段的目标宝箱奖励后，即可点亮该阶段的徽章称号，获得等多惊喜奖励！所有奖励不重置，每人达标每阶段只能获得一次奖励及徽章。
      .dialog-rule-item.bold.mt20 【微博】宝箱开启条件：
      .dialog-rule-item 第一阶段：成功绑定10个微博账号
      .dialog-rule-item 第二阶段：成功绑定20个微博账号
      .dialog-rule-item 第三阶段：成功绑定30个微博账号
      .dialog-rule-item.bold.mt20 【小红书】【抖音】【豆瓣】宝箱开启条件：
      .dialog-rule-item 第一阶段：成功绑定3个对应账号
      .dialog-rule-item 第二阶段：成功绑定5个对应账号
      .dialog-rule-item 第三阶段：成功绑定10个对应账号
      .dialog-rule-item.bold.mt20 成就系统账号绑定规则：
      .dialog-rule-item 账号库每绑定对应平台账号，并使用该账号成功执行一个任务后，即视为成功绑定该账号，反之则不计入数量统计。
    .dialog-rule-item-close(@click="close")
      img(src="~@/assets/img/achievement/achievement_icon12.png")
</template>
<script>
export default {
  props: ["show", "rowData"],
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-rule2 {
    position: relative;
    width: 365px;
    background-color: #ffffff;
    padding: 25px 20px;
    box-sizing: border-box;
    text-align: justify;
    border-radius: 20px;
    .bold {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .mt20 {
      margin-top: 15px;
    }
    .dialog-rule-item {
      font-size: 12px;
      line-height: 20px;
    }
    .dialog-rule-item-close {
      height: 30px;
      width: 30px;
      position: absolute;
      bottom: -50px;
      margin-left: -15px;
      left: 50%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .close {
      font-size: 20px;
    }
  }
}
.animate {
  animation: flipInY 1s ease-in-out;
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>
