<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .tiger-dialog-box
    img.dialog-icon7(src="~@/assets/img/active/dialog_icon7.png")
    .dialog-icon8
      .dialog-icon8-item
        img(src="~@/assets/img/active/dialog_icon8.png")
        .name {{ info }}
    .dialog-icon9(@click="close")
      img(src="~@/assets/img/active/dialog_icon9.png")
    .dialog-icon10(@click="close")
      img(src="~@/assets/img/active/dialog_icon10.png")
</template>
<script>
import { flagSynthetic } from "@/api/components/active";
import _ from "lodash";
export default {
  props: ["show", "info"],
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function () {
      try {
        await flagSynthetic();
        this.$toast("领取成功");
        this.$emit("updataCount");
        this.$emit("close");
      } catch (err) {
        this.$emit("close");
      }
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;
  .tiger-dialog-box {
    position: relative;
  }
  .dialog-icon7 {
    height: 414px;
    width: 319px;
  }
  .dialog-icon8 {
    height: 99px;
    width: 230px;
    position: absolute;
    left: 45.5px;
    top: 235px;
    overflow: hidden;
    .dialog-icon8-item {
      position: relative;
      animation: pushCard 4s linear forwards;
    }
    img {
      height: 100%;
      width: 100%;
    }
    .name {
      line-height: 99px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      color: #df4608;
      text-align: center;
    }
  }
  .dialog-icon9 {
    height: 27px;
    width: 27px;
    position: absolute;
    right: 0;
    top: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .dialog-icon10 {
    height: 45px;
    width: 150px;
    position: absolute;
    bottom: 18px;
    left: 87px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
@keyframes pushCard {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}
</style>