<template lang="pug">
.task-box
  .training-task-tab(@click="toggle")
    img.lock(:src="iconSrc")
    .text 实战练习
    img.right-img(v-if="isShowText", src="~@/assets/img/training/arrowup.png")
    img.right-img(v-else, src="~@/assets/img/training/icon_1.png")
  .fadeInAnimate(v-if="isShowText")
    .training-task-group
      .training-task-item(
        v-for="item in detail.task",
        :key="item.task_id",
        @click="goTask(item.param)"
      )
        img(:src="item.icon")
        .flex1
          .name {{ item.name }}
        div
          .intro {{ item.category }}
          .time {{ task_status[item.status] }}
</template>
<script>
import { task_status } from "../enum.js";
import { callhandler, isIphone, isAndroid } from "@/libs/util";
import close from "@/assets/img/training/study_close.png";
import ing from "@/assets/img/training/study_ing.png";
import success from "@/assets/img/training/study_success.png";
export default {
  data() {
    return {
      task_status,
      isShowText: false,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconSrc() {
      let src = close;
      const t = this.detail.status;
      if (t === 1) src = success;
      if (t === 2) src = close;
      if (t === 3) src = close;
      if (t === 4) src = ing;
      return src;
    },
  },
  methods: {
    goTask(params) {
      if (this.detail.status === 4 || this.detail.status === 1) {
        params = JSON.stringify(params);
        if (isIphone) callhandler("goTaskDetail", params);
        if (isAndroid) window.APP.goTaskDetail(params);
      } else {
        this.$parent.$refs["modal"].toggleModel();
      }
    },
    toggle() {
      if (this.detail.status === 4 || this.detail.status === 1)
        this.isShowText = !this.isShowText;
      else this.$parent.$refs["modal"].toggleModel();
    },
  },
};
</script>
<style lang="less">
.task-box {
  border-radius: 6px;
  background: #ffffff;
  .training-task-group {
    padding: 0 11px
  }
  .training-task-item {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    img {
      height: 44px;
      width: 44px;
    }
    .flex1 {
      flex: 1;
      padding-left: 15px;
    }
    .name {
      font-size: 14px;
      color: #333333;
    }
    .intro {
      font-size: 14px;
      color: #333333;
      text-align: right;
    }
    .count,
    .time {
      font-size: 10px;
      color: #777777;
      text-align: right;
    }
  }
  .training-task-tab {
    padding-left: 16px;
    padding-right: 20px;
    line-height: 50px;
    display: flex;
    align-items: center;
    line-height: 40px;
    margin: 10px 0 0;
    border-bottom: 1px solid #f5f5f5;
    .lock {
      width: 24px;
      height: 25px;
    }
    .right-img {
      height: 14px;
      width: 14px;
    }
    .text {
      flex: 1;
      margin-left: 2px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>