<template>
  <div class="glorious">
    <div class="search_header">
      <mt-header title="劳动最光荣">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="handleBack"></mt-button>
        </router-link>
        <div slot="right" @click="handleDetail">
          <span class="lan">明细</span>
        </div>
      </mt-header>
    </div>
    <div style="margin-top: 40px; padding-top: 20px">
      <div style="display: flex; justify-content: center">
        <img src="@/assets/img/glorious/top.png" class="img_ass" />
      </div>
      <div class="wei_div">
        <div v-for="(item, index) in dataList" :key="index">
          <div v-if="item.id == 1">
            <img
              v-if="item.num == 0"
              src="@/assets/img/glorious/hui1.png"
              class="wei_img wei1"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/wei1.png"
              class="wei_img wei1"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/tiao1.png"
              class="tiao_1"
              :class="item > 0 ? 'zhanshi' : ''"
            />
            <span
              class="bor1"
              :class="item.num > 0 ? 'zhanshi1' : ''"
              v-if="Number(item.num) > 0"
              >{{ item.num }}</span
            >
          </div>

          <div v-if="item.id == 2">
            <img
              v-if="item.num == 0"
              src="@/assets/img/glorious/hui2.png"
              class="wei_img wei2"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/wei2.png"
              class="wei_img wei2"
            />
            <img
              v-if="item.num > 0"
              :class="item.num > 0 ? 'zhanshi' : ''"
              src="@/assets/img/glorious/tiao2.png"
              class="tiao_2"
            />
            <span
              class="bor2"
              :class="item.num > 0 ? 'zhanshi1' : ''"
              v-if="Number(item.num) > 0"
              >{{ item.num }}</span
            >
          </div>
          <div v-if="item.id == 3">
            <img
              v-if="item.num == 0"
              src="@/assets/img/glorious/hui3.png"
              class="wei_img wei3"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/wei3.png"
              class="wei_img wei3"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/tiao3.png"
              :class="item.num > 0 ? 'zhanshi' : ''"
              class="tiao_3"
            />
            <span
              class="bor3"
              :class="item.num > 0 ? 'zhanshi1' : ''"
              v-if="item.num > 0"
              >{{ item.num }}</span
            >
          </div>

          <div v-if="item.id == 4">
            <img
              v-if="item.num == 0"
              src="@/assets/img/glorious/hui4.png"
              class="wei_img wei4"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/wei4.png"
              class="wei_img wei4"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/tiao4.png"
              :class="item.num > 0 ? 'zhanshi' : ''"
              class="tiao_4"
            />
            <span
              class="bor4"
              :class="item.num > 0 ? 'zhanshi1' : ''"
              v-if="item.num > 0"
              >{{ item.num }}</span
            >
          </div>
          <div v-if="item.id == 5">
            <img
              v-if="item.num == 0"
              src="@/assets/img/glorious/hui5.png"
              class="wei_img wei5"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/wei5.png"
              class="wei_img wei5"
            />
            <img
              v-if="item.num > 0"
              src="@/assets/img/glorious/tiao5.png"
              :class="item.num > 0 ? 'zhanshi' : ''"
              class="tiao_5"
            />
            <span
              class="bor5"
              :class="item.num > 0 ? 'zhanshi1' : ''"
              v-if="item.num > 0"
              >{{ item.num }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="div_chou">
      <div class="btn_text" v-if="time < 1620230340">
        <div style="position: relative" @click="handleChou">
          <img
            src="@/assets/img/glorious/btn.png"
            style="position: relative; width: 238px"
          />
          <div class="btn_chou">抽徽章x{{ infoData.number }}</div>
        </div>
      </div>
      <div class="btn_text" v-else>
        <div style="position: relative" @click="handleRecord">
          <img
            src="@/assets/img/glorious/btn.png"
            style="position: relative; width: 238px"
          />
          <div class="btn_chou">战绩</div>
        </div>
      </div>

      <div class="bac_img">
        <div @click="handlRotary" v-if="infoData.draw" class="draw">
          <img class="draw_img" src="@/assets/img/glorious/zhuan.png" />
        </div>
        <div v-if="!infoData.draw" @click="handlhuiRotary" class="draw">
          <img class="draw_img" src="@/assets/img/glorious/huipan.png" />
        </div>
        <div class="act_div">
          <div class="top_span">
            活动期间内，每执行10个平台任意任务可获得一次抽取<span class="span_FB462B"
              >劳动节专属徽章</span
            >的机会，抽到徽章的概率随机，每1枚徽章都会获得相应奖励及五一转盘的机会，转盘100%中奖，一枚徽章，3份惊喜！
          </div>
          <div class="top_span1">活动时间：2021/5/1 00:00 —2021/5/5 23:59</div>
          <div style="position: relative">
            <img
              src="@/assets/img/glorious/btn1.png"
              style="position: relative; width: 238px"
            />
            <div class="btn_jiang">各类徽章获取规则及奖励</div>
          </div>
          <div>
            <div class="pad_top">
              <span class="span_title">【勤学苦练】:</span>
              <span class="span_content"> 在抽取徽章的机会中随机获得 </span>
              <div class="span_content1 span_content">（每天获得次数无上限）</div>
              <div class="span_bot">随机大额积分奖励+2次转盘机会</div>
            </div>

            <div class="pad_top">
              <span class="span_title">【废寝忘食】:</span>
              <span class="span_content"> 在抽取徽章的机会中随机获得 </span>
              <div class="span_content1 span_content">（每天获得次数无上限）</div>
              <div class="span_bot">随机大额积分奖励+2次转盘机会</div>
            </div>

            <div class="pad_top">
              <span class="span_title">【乐此不疲】:</span>
              <span class="span_content"> 在抽取徽章的机会中随机获得 </span>
              <div class="span_content1 span_content">（每天获得次数无上限）</div>
              <div class="span_bot">随机大额积分奖励+2次转盘机会</div>
            </div>

            <div class="pad_top">
              <span class="span_title">【青出于蓝】:</span>
              <span class="span_content"> 在抽取徽章的机会中随机获得 </span>
              <div class="span_content1 span_content">（每天获得次数无上限）</div>
              <div class="span_bot">随机金额奖励卡+2次转盘机会</div>
            </div>

            <div class="pad_top">
              <span class="span_title">【V仔守护】:</span>
              <span class="span_content">
                当日21:00-次日9:00 在抽取徽 章的机会中随机获得
              </span>
              <div class="span_content1 span_content">（每天获得次数无上限）</div>
              <div class="span_bot">随机金额现金红包+2次转盘机会</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 0">
      <div class="mast" v-if="isShow" @touchmove.prevent></div>
      <div class="mast_all" v-if="isShow" @touchmove.prevent>
        <div class="mast_content" style="display: flex; justify-content: center">
          <img
            v-if="prize.type == 3"
            src="@/assets/img/rotayTable/k1.png"
            class="toast-picture"
          />
          <img
            v-if="prize.type == 1"
            src="@/assets/img/rotayTable/k2.png"
            class="toast-picture"
          />
          <img
            v-if="prize.type == 4"
            src="@/assets/img/rotayTable/k3.png"
            class="toast-picture"
          />
          <img
            v-if="prize.type == 2"
            src="@/assets/img/rotayTable/k4.png"
            class="toast-picture"
          />
          <img
            v-if="prize.type == 0"
            src="@/assets/img/rotayTable/k6.png"
            class="toast-picture"
          />
          <img
            v-if="prize.type == 5"
            src="@/assets/img/rotayTable/k5.png"
            class="toast-picture"
          />

          <div class="spanjiang">中奖啦</div>
          <div class="span">{{ prize.name }}</div>
        </div>
        <div @click="closeToast" class="btn">
          <Button class="toast-cancel">收下</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let iconList = {
  1: "@/assets/img/rotayTable/k1.png",
  2: "@/assets/img/rotayTable/k1.png",
  3: "@/assets/img/rotayTable/k1.png",
  4: "@/assets/img/rotayTable/k1.png",
  5: "@/assets/img/rotayTable/k1.png",
};
import { isIphone, isAndroid, callhandler, setToken, setUid } from "@/libs/util";
import { getBageInfo, getBadgeRand } from "@/api/components/glorious";
export default {
  data() {
    return {
      isShow: false,
      infoData: {},
      dataList: [],
      time: "",
      token: "",
      uid: "",
      prize: {
        icon: "",
      },
    };
  },
  created() {
   // this.handleBageInfo();
    // this.handleBageInfo();
    this.time = parseInt(new Date().getTime() / 1000);
    if (isAndroid) {
      this.token = window.APP.getToken();
      this.uid = window.APP.getUserId();
      setToken(this.token);
      setUid(this.uid);
      this.handleBageInfo();
      if (this.token && this.uid) {
        this.handleBageInfo();
      }
    } else {
      callhandler("getToken", "", (data) => {
        callhandler("getUserId", "", (uid) => {
          this.uid = uid;
          // this.$toast({ message: this.uid });
          this.token = data;
          //this.$toast({ message: this.token });
          setToken(this.token);
          setUid(this.uid);
          if (this.token && this.uid) {
            this.handleBageInfo();
          }
        });
      });
    }
  },
  methods: {
    handleRecord() {
      this.$router.push({
        path: "/record",
        query: {
          title: "/glorious",
        },
      });
    },
    handlhuiRotary() {
      this.$toast({
        message: "要抽到徽章才能解锁转盘哦!",
      });
    },
    closeToast() {
      this.isShow = false;
      this.handleBageInfo();
    },
    handleDetail() {
      this.$router.push({
        path: "/glorious_detail",
        query: {
          title: "/glorious",
        },
      });
    },
    handleChou() {
      getBadgeRand().then((res) => {
        let { code, data } = res.data;
        if (code == 10002) {
          this.isShow = true;
          this.prize = data;
          this.prize.icon = iconList[data.type];
        }
      });
    },
    handleBageInfo() {
      getBageInfo().then((res) => {
        let { data } = res.data;
        this.infoData = data;
        this.dataList = [];
        for (let i in this.infoData.list) {
          console.log("i ",i)
          this.dataList.push({
            id: i,
            num: this.infoData.list[i],
          });
        }
        console.log("this.dataList", this.dataList);
      });
    },
    handlRotary() {
      console.log("==handlRotary");
      this.$router.push("/rotaryTable");
    },
    handleBack() {
      if (isIphone) {
        callhandler("finish", function () {});
      } else if (isAndroid) {
        window.APP.finish();
      }
    },
  },
};
</script>
<style lang="less">
.glorious {
  background: #ffea73;
  width: 100%;
  padding-bottom: 30px;
  .img_ass {
    width: 350px;
    height: 325px;
  }
  .search_header {
    background: #ffea73;
  }
  .lan {
    color: #0b36ff;
  }
  .mint-header {
    background: #ffea73 !important;
  }
  .wei_div {
    position: relative;
    .wei_img {
      width: 55px;
      display: block;
    }
    .wei1 {
      position: absolute;
      top: -45px;
      left: 35px;
      z-index: 700;
    }
    .tiao_1 {
      position: absolute;
      top: -10px;
      left: 35px;
      width: 52px;
    }
    .wei2 {
      position: absolute;
      top: -42px;
      left: 95px;
      z-index: 700;
    }
    .tiao_2 {
      position: absolute;
      top: -10px;
      left: 96px;
      width: 52px;
    }
    .wei3 {
      position: absolute;
      top: -42px;
      left: 160px;
      z-index: 700;
    }
    .tiao_3 {
      position: absolute;
      top: -10px;
      left: 162px;
      width: 52px;
    }
    .wei4 {
      position: absolute;
      top: -42px;
      left: 225px;
      z-index: 700;
    }
    .tiao_4 {
      position: absolute;
      top: -10px;
      left: 226px;
      width: 52px;
    }
    .wei5 {
      position: absolute;
      top: -42px;
      left: 290px;
      z-index: 700;
    }
    .tiao_5 {
      position: absolute;
      top: -10px;
      left: 290px;
      width: 52px;
    }
    .zhanshi {
      animation: move 1.2s;
    }
    .zhanshi1 {
      animation: move1 1.5s;
    }
    @-webkit-keyframes move1 {
      0% {
        top: 0px;
        opacity: 0;
      }
      25% {
        top: 0px;
        opacity: 0;
      }
      50% {
        top: 40px;
        opacity: 0;
      }
      100% {
        top: 80px;
      }
    }
    @-webkit-keyframes move {
      0% {
        top: -100px;
        opacity: 0;
      }
      50% {
        top: -5px;
      }
      100% {
        top: -10px;
      }
    }

    .bor1 {
      border: 1px solid #551d10;
      border-radius: 50%;
      text-align: center;
      padding: 2px;
      height: 15px;
      width: 15px;
      position: absolute;
      top: 80px;
      left: 48px;
    }

    .bor2 {
      border: 1px solid #551d10;
      border-radius: 50%;
      padding: 2px;
      height: 15px;
      text-align: center;
      width: 15px;
      position: absolute;
      top: 80px;
      left: 110px;
    }
    .bor3 {
      border: 1px solid #551d10;
      border-radius: 50%;
      text-align: center;
      padding: 2px;
      height: 15px;
      width: 15px;
      position: absolute;
      top: 80px;
      left: 178px;
    }
    .bor4 {
      border: 1px solid #551d10;
      border-radius: 50%;
      text-align: center;
      padding: 2px;
      height: 15px;
      width: 15px;
      position: absolute;
      top: 80px;
      left: 242px;
    }
    .bor5 {
      border: 1px solid #551d10;
      border-radius: 50%;
      text-align: center;
      padding: 2px;
      height: 15px;
      width: 15px;
      position: absolute;
      top: 80px;
      left: 305px;
    }
  }
  .btn_text {
    text-align: center;
  }
  .div_chou {
    padding-top: 120px;
    .btn_chou {
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 10px;
    }
  }

  .bac_img {
    position: relative;
    background: url("~@/assets/img/glorious/kuang.png");
    position: relative;
    background-size: 100% 100%;
    width: 332px;
    text-align: center;
    margin: 15px auto;
    .draw_img {
      width: 116px;
    }
    .act_div {
      padding: 60px 35px;
      .top_span {
        color: #551d10;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }
      .span_FB462B {
        color: #fb462b;
      }
      .top_span1 {
        color: #551d10;
        font-size: 12px;
        padding: 10px 0;
      }
      .btn_jiang {
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        font-family: "Regular";
        position: absolute;
        width: 100%;
        top: 12px;
      }
      .span_title {
        color: #551d10;
        font-size: 13px;
        font-weight: bold;
      }
      .span_content {
        font-size: 12px;
        color: #551d10;
        font-weight: 400;
      }
      .span_content1 {
        padding-left: 35px;
      }
      .span_bot {
        color: #eb4438;
        font-size: 10px;
        padding-left: 83px;
      }
    }
  }
}
.draw {
  position: absolute;
  z-index: 600;
  top: -52px;
  width: 116px;
  right: -16px;
}
.pad_top {
  padding-top: 10px;
}
.mast {
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}
.mast_all {
  width: 100vw;
  position: fixed;
  top: 20%;
  z-index: 1001;
}
.toast-picture {
  width: 329px;
  height: 432px;
}
.toast-cancel {
  background: #ff8856;
  border: none;
  width: 160px;
  padding: 15px;
  border-radius: 30px;
  font-size: 18px;
  color: #fff;
}
.btn {
  margin-top: -57px;
  display: flex;
  justify-content: center;
}
.span {
  position: absolute;
  padding-top: 30px;
  color: #fff;
  width: 100%;
  top: 57%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.spanjiang {
  position: absolute;
  padding-top: -0.2rem;
  color: #ff8856;
  font-size: 28px;
  top: 45%;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  filter: 100px;
  z-index: 10000;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 100%;
  margin-left: -165px;
}
.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}
.toast-picture {
  width: 329px;
  height: 432px;
}
.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}
.toast-title {
  padding: 2.8125rem 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}
.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
}
</style>
<style>
@font-face {
  font-family: "jiangxi";
  src: url("~@/assets/less/jiangxi.ttf");
}
.btn_chou {
  font-family: "jiangxi";
}
</style>
