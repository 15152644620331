<template>
  <div class="rotayTable">
    <div class="search_header">
      <mt-header title="快乐大转盘">
        <router-link to="/glorious" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
        <div slot="right" @click="handleDetail">
          <span class="lan">明细</span>
        </div>
      </mt-header>
    </div>
    <div class="rotay_body">
      <div class="rotay_1">
        <img class="rotay1_img" src="@/assets/img/rotayTable/1.png" />
        <div class="totay_ab">
          <Button class="totay_btn">剩余抽奖:{{ drawInfo.number }}</Button>
        </div>
      </div>

      <div id="app">
        <LuckDraw @handleInfo="handleInfo" :drawInfo="drawInfo.number"></LuckDraw>
      </div>
    </div>
  </div>
</template>
<script>
import { getDrawInfo } from "@/api/components/glorious";
export default {
  data() {
    return {
      drawInfo: {
        number: 0,
      },
    };
  },
  created() {
    this.handleInfo();
  },
  methods: {
    handleDetail() {
      this.$router.push({
        path: "/glorious_detail",
        query: {
          title: "/rotaryTable",
        },
      });
    },
    handleInfo() {
      getDrawInfo().then((res) => {
        let { code, data } = res.data;
        if (code == 10002) {
          this.drawInfo = data;
        }
      });
    },
  },
  components: {
    LuckDraw: () => import("./luckDraw"),
  },
};
</script>
<style lang="less">
.lan {
  color: #0b36ff;
}
.rotayTable {
  background: #ffea73;
  height: 100vh;
  overflow: hidden;
  .search_header {
    background: #ffea73;
  }
  .mint-header {
    background: #ffea73 !important;
  }

  .rotay_body {
    overflow: auto;
    position: relative;
    background: url(~@/assets/img/rotayTable/back.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0px auto;
    margin-top: 44px;
    .rotay_1 {
      padding-top: 20px;
      position: relative;
      .rotay1_img {
        width: 375px;
      }
      .totay_ab {
        position: absolute;
        bottom: 50px;
        left: 50%;
        width: 166px;
        margin-left: -88px;
      }
      .totay_btn {
        background: #f84d2e;
        padding: 10px 20px;
        border-radius: 20px;
        border: none;
        width: 166px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
</style>
