<template lang="pug">
.activity.fadeInAnimate
  .top-bg
  .backs(@click="goBackApp")
    van-icon(name="arrow-left")
  .top-content
    //- 地标
    .top-item
      .city-box
        .img-box(v-for="(item, i) in areaDatas", @click="goIntro(i)")
          img(:src="item.icon", :class="num < i + 1 ? 'grayscale' : ''")
          .city {{ item.city }}
      .img-air(v-if="num", :class="'air-pos' + num", @click="goIntro(id)")
        img.air-animate(src="~@/assets/img/active/air.png")
      .rule-tag(@click="goRule") 活动规则
    //- 旗子
    .top-item2(v-if="isShowItem")
      .item2-box
        .item2-rela(@click="confirm(activeFlag.SYN)")
          img.item2-rela-bg(src="~@/assets/img/active/item2_1.png")
          img.item2-rela-1(
            src="~@/assets/img/active/item2_1_.png",
            :class="startBounce ? 'bounceInDown' : ''"
          )
          .count {{ surplus.small_surplus }}
        .item2-rela(@click="confirm(activeFlag.ILL)")
          img.item2-rela-bg(src="~@/assets/img/active/item2_2.png")
          img.item2-rela-2(
            src="~@/assets/img/active/item2_2_.png",
            :class="startBounceBig ? 'bounceInDown' : ''"
          )
          .count {{ surplus.big_surplus }}
      .rule-tag(@click="goHistory") 历史记录
    //- 盲盒
    .top-item3(v-if="isShowItem")
      div(@click="startClickAnimate")
        img(
          src="~@/assets/img/active/active_box_item.png",
          :class="showAnimate ? animateName[animateIndex] : ''"
        )
      .item3-intro 开启时间 10/7-10/8
      van-button.btn(round, block, type="info", @click="goTiger") 查看详情
    //- 结束
    .top-item4(v-if="!isShowItem")
      img.item4-icon1(src="~@/assets/img/active/active_item4_icon1.png")
      img.item4-icon2(src="~@/assets/img/active/active_item4_icon2.png")
      img.item4-icon3(src="~@/assets/img/active/active_item4_icon3.png")
      .item4-intro1 活动期间您一共执行{{ countData.task_num }}个任务
      .item4-intro2 活动期间共获得小红旗{{ countData.flag_small }}面、大红旗{{ countData.flag_big }}面
      .item4-intro3 活动期间获得的任务金额:{{ countData.money }}元
  dialogSmall(
    :show="show",
    :surplus="surplus",
    @close="closeModal",
    @updataCount="getData"
  )
</template>
<script>
import mixins from "../mixins";
import { mapActions } from "vuex";
import { areaDatas, activeFlag, dialogTip } from "../data";
import { flagInfo, flagCount } from "@/api/components/active";
import dialogSmall from "./modules/dialogSmall.vue";
import { isIphone, isAndroid, callhandler } from "@/libs/util";
import _ from "lodash";
export default {
  mixins: [mixins],
  components: {
    dialogSmall,
  },
  data() {
    return {
      activeFlag,
      dialogTip,
      areaDatas,
      show: false,
      showAnimate: false,
      animateName: ["rubberBand", "shake"],
      animateIndex: 0,
      surplus: {
        small_surplus: 0,
        big_surplus: 0,
      },
      countData: {
        flag_big: 0,
        flag_small: 0,
        money: 0,
        task_num: 0,
      },
    };
  },
  computed: {
    num() {
      return this.$store.getters.city_number;
    },
    id() {
      return this.num - 1;
    },
    isShowItem() {
      return this.surplus.scene !== 3;
    },
    startBounce() {
      return this.surplus.small_surplus > 0;
    },
    startBounceBig() {
      return this.surplus.big_surplus > 0;
    },
    allowFlag() {
      let allowFlag = null;
      if (this.num < 7 && this.surplus.big_surplus > 0) {
        allowFlag = this.num;
      }
      return allowFlag;
    },
  },
  async created() {
    this.getToken().then(() => {
      this.getData();
    });
  },
  methods: {
    ...mapActions(["updataCityNum"]),
    async getData() {
      // 基本信息
      const { data } = await flagInfo();
      const num = data.data.city_number;
      this.surplus = data.data;
      setTimeout(() => {
        this.num < 7 && this.updataCityNum(num);
      }, 500);
      // 统计数据
      this.getCount();
    },
    async getCount() {
      const { data } = await flagCount();
      this.countData = data.data;
    },
    // 合成和点亮
    confirm: _.debounce(function (e) {
      if (e === this.activeFlag.ILL && this.surplus.big_surplus === 0) {
        this.$toast("数量不足");
        return;
      }
      this.nextConfirm(e);
    }, 250),
    nextConfirm(e) {
      if (e === this.activeFlag.SYN) this.show = true;
      e === this.activeFlag.ILL && this.goIntro(this.num);
    },
    // 动画
    startClickAnimate() {
      if (!this.showAnimate) {
        this.showAnimate = true;
        setTimeout(() => {
          if (this.animateIndex) this.animateIndex = 0;
          else this.animateIndex = 1;
          this.showAnimate = false;
        }, 1000);
      }
    },
    // 开启盲盒
    goTiger: _.debounce(function () {
      if (this.surplus.scene === 2) {
        this.$router.push("/activity/tiger");
      } else {
        this.$toast("还未开启");
      }
    }, 250),
    goRule() {
      this.$router.push("/activity/rules?city_number=" + this.num);
    },
    goHistory() {
      this.$router.push("/activity/history");
    },
    // 点亮
    goIntro(e) {
      let extra = "";
      if (e === this.allowFlag) extra = "&light=1";
      this.$router.push("/activity/intro?id=" + e + extra);
    },
    closeModal() {
      this.show = false;
    },
    goBackApp() {
      if (isIphone) {
        callhandler("finish", function () {});
      } else if (isAndroid) {
        window.APP.finish();
      }
    },
  },
};
</script>
<style lang="less">
.activity {
  background: #f9b467;

  padding-bottom: 30px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 20px;
  .top-content {
    margin-top: -340px;
  }
  .backs {
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 20px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
  }
  .top-bg {
    width: 375px;
    height: 673px;
    background-image: url("~@/assets/img/active/active_main_bg.png");
    background-size: 100% 100%;
  }
  .top-item2 {
    background: url("~@/assets/img/active/active_main_item1.png");
    background-size: 100% 100%;
    width: 344px;
    height: 291px;
    margin-top: 15px;
    margin-left: 13.5px;
    position: relative;
    .item2-box {
      display: flex;
      justify-content: space-between;
      padding-top: 83px;
      padding-left: 38px;
      padding-right: 38px;
      .item2-rela {
        position: relative;
        overflow: hidden;
        .count {
          position: absolute;
          left: 0;
          top: 0px;
          font-size: 15px;
          color: #ffffff;
          font-weight: bold;
          width: 40px;
          height: 35px;
          line-height: 35px;
          text-align: center;
        }
      }
      .item2-rela-bg {
        width: 117px;
        height: 162px;
      }
      .item2-rela-1 {
        height: 90px;
        width: 44px;
        position: absolute;
        left: 48px;
        bottom: 44px;
        opacity: 0;
      }
      .item2-rela-2 {
        height: 97px;
        width: 60px;
        position: absolute;
        left: 47.5px;
        bottom: 44px;
        opacity: 0;
      }
    }
  }
  .top-item3 {
    background: url("~@/assets/img/active/active_main_item2.png");
    background-size: 100% 100%;
    width: 344px;
    height: 199px;
    margin-left: 13.5px;
    margin-top: 15px;
    text-align: center;
    img {
      width: 85px;
      height: 78px;
      margin-top: 48px;
    }
    .item3-intro {
      font-size: 15px;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 1px;
      font-weight: bold;
    }
    .btn {
      width: 81px;
      height: 23px;
      background: linear-gradient(180deg, #fef5dd, #f8ce9a);
      border: none;
      display: inline-block;
      font-size: 11px;
      color: #f15332;
      font-weight: bold;
    }
  }
  .top-item4 {
    background: url("~@/assets/img/active/active_end_item.png");
    background-size: 100% 100%;
    width: 331px;
    height: 493px;
    margin-left: 22px;
    position: relative;
    font-size: 12px;
    color: #bd2e32;
    font-weight: bold;
    text-align: justify;
    .item4-intro1 {
      padding-top: 110px;
      width: 87px;
      padding-left: 190px;
    }
    .item4-intro2 {
      padding-top: 110px;
      width: 90px;
      padding-left: 50px;
    }
    .item4-intro3 {
      padding-top: 90px;
      width: 112px;
      padding-left: 180px;
    }
    .item4-icon1 {
      height: 42px;
      width: 36px;
      position: absolute;
      top: 5px;
      right: 80px;
    }
    .item4-icon2 {
      height: 63px;
      width: 47px;
      position: absolute;
      top: 260px;
      left: -10px;
    }
    .item4-icon3 {
      height: 48px;
      width: 48px;
      position: absolute;
      bottom: 20px;
      right: -15px;
    }
  }
  .rule-tag {
    background: #f3e2be;
    width: 64px;
    height: 25px;
    color: #bd2e32;
    font-size: 10px;
    line-height: 25px;
    font-weight: bold;
    border-radius: 12.5px 0 0 12.5px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 30px;
  }
  .top-item {
    background: url("~@/assets/img/active/active_main_item.png");
    background-size: 100% 100%;
    width: 344px;
    height: 360px;
    position: relative;
    margin-left: 13.5px;

    .img-air {
      position: absolute;
      z-index: 1;
      transition: all 1s ease-in-out;
      img {
        width: 69px;
        height: 45px;
      }
    }

    .air-pos1 {
      right: 62px;
      top: 65px;
    }
    .air-pos2 {
      right: 27px;
      top: 135px;
    }
    .air-pos3 {
      right: 75px;
      top: 200px;
    }
    .air-pos4 {
      right: 145px;
      top: 160px;
    }
    .air-pos5 {
      right: 210px;
      top: 205px;
    }
    .air-pos6 {
      right: 260px;
      top: 150px;
    }
    .air-pos7 {
      right: 210px;
      top: 80px;
    }
    .img-box {
      img {
        height: 50px;
        width: 50px;
        transition: all 0.2s ease-in-out;
      }
      .city {
        text-align: center;
      }
      position: absolute;
      z-index: 1;
      &:nth-of-type(1) {
        right: 62px;
        top: 65px;
      }
      &:nth-of-type(2) {
        right: 27px;
        top: 135px;
      }
      &:nth-of-type(3) {
        right: 75px;
        top: 200px;
      }
      &:nth-of-type(4) {
        right: 145px;
        top: 160px;
      }
      &:nth-of-type(5) {
        right: 210px;
        top: 205px;
      }
      &:nth-of-type(6) {
        right: 260px;
        top: 150px;
      }
      &:nth-of-type(7) {
        right: 210px;
        top: 80px;
      }
    }
  }

  .air-animate {
    animation: air 2s linear infinite;
  }

  @keyframes air {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .grayscale {
    filter: grayscale(100%);
  }
  .bounceInDown {
    animation: bounceInDown 3s ease-in-out forwards;
  }
  @keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px);
    }

    60% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  .rubberBand {
    animation: 1s rubberBand ease-in-out;
  }
  @keyframes rubberBand {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    30% {
      -webkit-transform: scaleX(1.25) scaleY(0.75);
      -ms-transform: scaleX(1.25) scaleY(0.75);
      transform: scaleX(1.25) scaleY(0.75);
    }

    40% {
      -webkit-transform: scaleX(0.75) scaleY(1.25);
      -ms-transform: scaleX(0.75) scaleY(1.25);
      transform: scaleX(0.75) scaleY(1.25);
    }

    60% {
      -webkit-transform: scaleX(1.15) scaleY(0.85);
      -ms-transform: scaleX(1.15) scaleY(0.85);
      transform: scaleX(1.15) scaleY(0.85);
    }

    100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
  .shake {
    animation: 1s shake ease-in-out;
  }
  @keyframes shake {
    0%,
    100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
}
</style>
