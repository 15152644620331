<template>
  <div class="share">
    <img src="~@/assets/img/invite_friends/img_1@2x.png" class="share_img" />
    <img src="~@/assets/img/invite_friends/img2@2x.png" class="share_img" />
    <img src="~@/assets/img/invite_friends/img_3@2x.png" class="share_img" />
  </div>
</template>
<script>
import { URL } from "@/api/http";
import { isIphone, isAndroid, callhandler } from "@/libs/util";
export default {
  data() {
    return {};
  },
  mounted() {
    window.handleShare = this.handleShare;
  },
  methods: {
    /**
     * 分享
     */
    handleShare() {
      if (isIphone) {
        callhandler("configBGViewColor", {
          //头部颜色
          title: "小v招新",
          introduction: "",
          imageUrl: "",
          url: URL+"newbie_guide",
        });
      } else {
        window.APP.share("小v招新", "", "", URL+"newbie_guide");
      }
    },
  },
};
</script>
<style>
.share_img {
  width: 100vw;
  display: block;
}
</style>
