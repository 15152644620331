<template>
  <div class="down_all">
    <div
      class="down_box"
      style="
        width: 100vw;
        height: 90vh;
        background-size: contain;
        background-position: center top;
      "
    >
      <div class="down">
        <div class="down_div">
          <Button @click="handleBtn" class="down_btn">点击下载APP</Button>
        </div>
        <div class="down_span">成为通明小V，每月多一笔零花钱！</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleBtn() {
      const isIphone = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
      const isAndroid = /(Android)/i.test(navigator.userAgent);
      if (isIphone) {
        window.location.href =
          "https://itunes.apple.com/app/apple-store/id1461688953?ct=web&mt=8";

      } else if (isAndroid) {
        window.location.href = "http://apk.tongmingmedia.com/sv_2.4.0.apk";
      }
    },
  },
};
</script>
<style>
.down_all {
  background: #fff3e6;
  min-height: 100vh;
  display: block;
}
.down_box {
  background: url("~@/assets/img/invite_friends/down1.png") no-repeat;
}
.down_img {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.down {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.down_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.down_btn {
  background-color: #e84b5b;
  padding: 10px 30px;
  border-radius: 30px;
  color: #ffe9d3;
  font-weight: 600;
  font-size: 3.8vw;
  border: 0;
  width: 90vw;
  outline: none;
}
.down_span {
  color: #e1374a;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-weight: bold;
  font-size: 13px;
}
</style>
