<template lang="pug">
.jigsaw-forms.fadeInAnimate
  van-nav-bar(
    title="V仔的双十一购物狂欢节",
    left-arrow,
    :border="false",
    @click-left="onClickLeft"
  )
  .detail-box
    div(style="margin-top: -10px")
      .detail-item 微博会员：需填写微博昵称，其他信息无需填写
      .detail-item 腾讯视频会员：需填写登录腾讯视频的QQ号，其他信息无需填写
      .detail-item 爱奇艺、芒果tv、B站会员：需填写登录视频平台的手机账号，其他信息无需填写
      .detail-item 季度会员大礼包：包含微博/腾讯/爱奇艺/芒果TV/优酷/B站 六平台会员
      .detail-item.detail-item-strong *请确保信息填写正确，一旦确定不可修改更换

  van-form.forms-box(@submit="onSubmit")
    .form-title {{!isFinish?'请完善以下领奖信息':'领奖信息'}}
    van-field(
      v-model="form.qq",
      label="QQ号",
      type="number",
      placeholder="请输入QQ号",
      :disabled="isFinish"
    )
    van-field(
      v-model="form.mobile",
      label="手机号",
      placeholder="请输入手机号",
      :disabled="isFinish"
    )
    van-field(
      v-model="form.nickname",
      label="微博昵称",
      placeholder="请输入微博昵称",
      :disabled="isFinish"
    )
    van-button.btn(v-if="!isFinish" round, block, type="info", native-type="submit") 保存
</template>
<script>
import { activityJigsawInformation } from "@/api/components/jigsaw";
import mixins from "../mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      form: {
        qq: "",
        mobile: "",
        nickname: "",
      },
      status: 2
    };
  },
  mounted() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#f79e64'
    if(this.$route.query.mobile) {
      this.form.qq = this.$route.query.qq
      this.form.mobile = this.$route.query.mobile
      this.form.nickname = this.$route.query.nickname
      this.status = this.$route.query.status
    }
  },
  computed: {
    isFinish() {
      return this.$route.query.mobile
    }
  },
  methods: {
    onSubmit() {
      this.submitForm();
    },
    async submitForm() {
      this.form.id = Number(this.$route.query.id) || 0
      await activityJigsawInformation(this.form);
      this.$toast("提交成功");
      this.onClickLeft();
    },
    close() {
      this.show = false;
    },
    validator(val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        val
      );
    },
  },
};
</script>
<style lang="less">
.jigsaw-forms {
  background-image: url("~@/assets/img/jigsaw/rules_bg.png");
  background-size: 100% auto;
  padding-bottom: 30px;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  .van-nav-bar {
    background: none;
  }
  .btn {
    position: absolute;
    left: 50%;
    bottom: 50px;
    width: 130px;
    height: 40px;
    margin-left: -65px;
    background: linear-gradient(180deg, #fef5dd, #f8ce9a);
    border: none;
    color: #f15332;
    font-weight: bold;
  }
  .van-field__error-message {
    padding-left: 5px;
  }
  textarea,
  input {
    padding-left: 5px;
  }
  textarea {
    border: 1px solid #fde5e0;
  }
  .van-field__label {
    color: #f15332;
    font-weight: bold;
  }
  .van-cell::after {
    border-bottom-color: #fde5e0;
  }
  /* CSS Working Draft */
  input::placeholder,
  textarea::placeholder {
    color: #fcd6ce;
  }
  .van-cell__right-icon {
    color: #f15332;
  }
  .detail-box {
    position: relative;
    background: url("~@/assets/img/jigsaw/form_intro.png");
    margin-top: 20px;
    background-size: 100% 100%;
    width: 375px;
    height: 215px;
    padding:35px 40px 20px;
    box-sizing: border-box;
    text-align: justify;
    .detail-item-strong {
      color: #55231d !important;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .detail-item {
      font-size: 14px;
      line-height: 22px;
      color: #55231d;
    }
  }
  .forms-box {
    position: relative;
    background: url("~@/assets/img/jigsaw/form_item.png");
    background-size: 100% 100%;
    height: 375px;
    width: 375px;
    margin-top: 5px;
    padding: 20px 40px;
    box-sizing: border-box;
    text-align: justify;
    .form-title {
      width: 181px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin: 20px auto 30px;
      background: #FFE0C2;
      color: #D22E28;
      border-radius: 6px;
    }
  }
}
</style>
