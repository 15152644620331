
import {
    get
} from '@/api/http'


export function getBageInfo(params) {
    return get({
        url: "/badge/info",
        params,
    })
}
export function getBadgeRand(params) {
    return get({
        url: "/badge/rand",
        params,
    })
}


export function getDrawInfo(params) {
    return get({
        url: "/badge/draw_info",
        params,
    })
}
export function getDraw(params) {
    return get({
        url: "/badge/draw",
        params,
    })
}
export function getDadgelog(params) {
    return get({
        url: "/badge/log",
        params,
    })
}

export function getBadgeStatistics(params) {
    return get({
        url: "/badge/statistics",
        params,
    })
}