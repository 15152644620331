<template lang="pug">
.jigsaw-rules(class="fadeInAnimate")
  van-nav-bar(
    title="规则说明",
    left-arrow,
    :border="false",
    @click-left="onClickLeft"
  )
  .content-box
    .rules-box
      .left-title
        img(src="~@/assets/img/jigsaw/rules_title.png")
        .left-name 活动时间：
      .right-bg
        .nomal-item 2021/11/01-2021/11/15 (集碎片)
    .rules-box
      .left-title
        img(src="~@/assets/img/jigsaw/rules_title.png")
        .left-name 大奖抽奖时间：
      .right-bg
        .nomal-item 2021/11/15-2021/11/16 (得大奖)
    .rules-box
      .left-title
        img(src="~@/assets/img/jigsaw/rules_title.png")
        .left-name 活动对象：
      .right-bg
        .nomal-item 所有已通过考核的正式小V
    .rules-box.block
      .left-title
        img(src="~@/assets/img/jigsaw/rules_title.png")
        .left-name 活动介绍：
      .bottom-intro
        .nomal-item.mt5 拼图是V仔最喜欢的活动之一啦~通过做任务帮V仔拾取碎片，顺利凑齐碎片拼成拼图的小V可获得V仔送出的抽大奖机会
    .rules-box.block
      .left-title
        img(src="~@/assets/img/jigsaw/rules_title.png")
        .left-name 碎片获取：
      .bottom-intro
        .big-item 碎片的3种获取途径：
        .nomal-item 1. 在平台执行完成任意任务有机会获得碎片
        .nomal-item 2. 每日执行完成任务数量超过5个，将有机会获得碎片
        .nomal-item 3. 每日执行完成的第一个微博直发任务可获得1个碎片
        .big-item 奖励说明:
        .nomal-item 1. 获得碎片的同时也会获得相应积分奖励
        .nomal-item 2. 集齐所有拼图碎片完成拼图，可有机会抽取最终大奖
        .nomal-item 3. 最终大奖的得奖率为100%！
        .big-item 奖品:
        .nomal-item 最终大奖包括但不限于大额积分、双11购物车现金补贴、平台会员大礼包，还会有一位超级锦鲤获得神秘大礼~
</template>
<script>
import mixins from "../mixins";
export default {
  mixins: [mixins],
  data() {
    return {};
  },
  mounted() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#f79e64'
  },
  methods: {},
};
</script>
<style lang="less">
.jigsaw-rules {
  background: #f25833;
  background-image: url("~@/assets/img/jigsaw/rules_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-bottom: 30px;
  min-height: 100vh;
  box-sizing: border-box;
  .van-nav-bar {
    background: none;
  }
  .block {
    display: block !important;
  }
  .content-box {
    position: relative;
    background: url("~@/assets/img/jigsaw/rules_item.png");
    background-size: 100% 100%;
    padding-top: 45px;
    height: 709px;
    width: 375px;
    .rules-box {
      display: flex;
      padding-left: 3px;
      margin-bottom: 11px;
      text-align: justify;
      align-items: center;
      .mt5 {
        margin-top: 6px;
      }
      .bottom-intro {
        width: 300px;
        margin-left: 35px;
        font-size: 14px;
        .big-item {
          font-size: 16px;
          font-weight: bold;
          margin-top: 6px;
        }
      }
      .right-bg {
        background: #ffe0c2;
        line-height: 32px;
        height: 32px;
        width: 233px;
        margin-left: -20px;
        border-radius: 6px;
        padding-left: 40px;
        box-sizing: border-box;
        font-weight: bold;
      }
      .left-title {
        position: relative;
        left: 6px;
        height: 44px;
        width: 115px;
        .left-name {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          line-height: 44px;
        }
        img {
          height: 44px;
          width: 115px;
        }
      }
    }
  }
}
</style>
