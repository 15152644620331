<template lang="pug">
.jigsaw.fadeInAnimate
  .top-bg
  .backs(@click="goBackApp")
    van-icon(name="arrow-left")
  .rule-tag(@click="goRule")
    van-icon(name="warning-o" size="16")
    div 规则说明
  .top-content
    .top-item
      .top-item-box(v-if="!isGet")
        .top-item-list(v-for="(item, i) in jigsawList" :key="item.name")
          img(:src="item.number?item.icon_:item.icon")
          .top-item-count(v-if="item.number" :class="'item' + i") {{item.number}}
      .top-item-box(v-else)
        img(class="end" src="~@/assets/img/jigsaw/end.png")
      .comfirm(@click="confirm" :class="status===2?'':'gray'") {{statusBtnName[status]}}
    .record-item(@click="goHistory")
      img(src="~@/assets/img/jigsaw/jigsaw_main_record.png")
    .mid-item(v-if="status === 3 && stage !== 3")
      .mid-item-list(v-if="reward")
        .name {{reward.reward}}
        .op(v-if="reward.information === 1" @click="goForm") 信息备注
    finish(v-if="stage === 3" :finishInfo="finishInfo")
  scratchable(
    :show="showScratchable",
    @close="closeModal",
    @updata="updataData"
  )
</template>
<script>
import mixins from "../mixins";
import {
  activityJigsaw,
  activityJigsawReward,
  activityJigsawCount,
} from "@/api/components/jigsaw";
import finish from "./modules/finishi.vue";
import scratchable from "./modules/scratchable.vue";
import { isIphone, isAndroid, callhandler } from "@/libs/util";
import { jigsawList } from "../data";
const statusBtnName = {
  1: "未集齐",
  2: "刮一刮",
  3: "已领取",
};
import _ from "lodash";
export default {
  mixins: [mixins],
  components: {
    scratchable,
    finish,
  },
  data() {
    return {
      jigsawList,
      statusBtnName,
      status: 1,
      list: [],
      reward: null,
      showScratchable: false,
      param: null,
      stage: 1,
      finishInfo: {
        task_num: 20, //任务数量
        number: 0, //碎片数量
        money: 2.1, //金额
        reward: "这是奖品名称",
      },
    };
  },
  computed: {
    isGet() {
      return this.status === 3;
    },
  },
  async created() {
    document.getElementsByTagName("body")[0].style.backgroundColor = "#3453bc";
    this.getToken().then(() => {
      this.getData();
      this.getCount();
    });
  },
  methods: {
    async getData() {
      // 基本信息
      const { data } = await activityJigsaw();
      this.status = data.data.status;
      this.stage = data.data.stage;

      this.jigsawList.map((item, i) => {
        this.jigsawList[i] = {
          ...item,
          ...data.data.list[i],
        };
      });
      this.$forceUpdate();
      this.getReward();
      this.getCount();
    },
    async getCount() {
      if (this.stage === 3) {
        const { data } = await activityJigsawCount();
        this.finishInfo = data.data;
      }
    },
    async getReward() {
      if (this.status === 3 && this.stage !== 3) {
        const { data } = await activityJigsawReward();
        this.reward = data.data;
      }
    },
    updataData() {
      this.getData();
    },
    // 合成和点亮
    confirm: _.debounce(function() {
      if (this.status === 2) {
        if (this.stage === 2) {
          window.scrollTo(0,0);
          this.showScratchable = true;
        } else {
          this.$toast("11.15-11.16开启");
        }
      }
    }, 250),
    goRule() {
      this.$router.push("/jigsaw/rules");
    },
    goForm() {
      let params = "";
      const data = this.reward.param;
      if (this.reward.param) {
        params = `&mobile=${data.mobile}&nickname=${data.nickname}&qq=${data.qq}`;
      }
      this.$router.push("/jigsaw/form?id=" + this.reward.id + params);
    },
    goHistory() {
      this.$router.push("/jigsaw/history");
    },
    closeModal() {
      this.showScratchable = false;
    },
    goBackApp() {
      if (isIphone) {
        callhandler("finish", function() {});
      } else if (isAndroid) {
        window.APP.finish();
      }
    },
  },
};
</script>
<style lang="less">
.jigsaw {
  background: #ff7336;
  background-image: url("~@/assets/img/jigsaw/jigsaw_main_air.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  padding-bottom: 30px;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 70px;
  .gray {
    filter: grayscale(1);
  }
  .top-item-box {
    width: 260px;
    height: 273px;
    position: relative;
    top: 66px;
    left: 60px;
    .end {
      height: 100%;
      width: 100%;
    }
  }
  .top-item-list {
    img {
      width: 100%;
    }
    display: inline-block;
    position: absolute;
    .top-item-count {
      position: absolute;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #f6b380;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
    }
    .item0 {
      right: 5px;
      bottom: 5px;
    }
    .item1 {
      right: 28px;
      bottom: 28px;
    }
    .item2 {
      right: 5px;
      bottom: 5px;
    }
    .item3 {
      left: 67px;
      bottom: 5px;
    }
    .item4 {
      left: 66px;
      bottom: 30px;
    }
    .item5 {
      right: 5px;
      bottom: 5px;
    }
    .item6 {
      left: 67px;
      bottom: 5px;
    }
    .item7 {
      right: 5px;
      bottom: 5px;
    }
    .item8 {
      right: 5px;
      bottom: 5px;
    }
    &:nth-of-type(1) {
      width: 87px;
      height: 91px;
      left: 0;
      top: 0;
    }
    &:nth-of-type(2) {
      width: 134px;
      height: 115px;
      top: 0;
      left: 63px;
    }
    &:nth-of-type(3) {
      width: 87px;
      height: 91px;
      top: 0;
      right: 0;
    }
    &:nth-of-type(4) {
      width: 112px;
      height: 116px;
      left: 0;
      top: 66px;
    }
    &:nth-of-type(5) {
      width: 87px;
      height: 116px;
      left: 88px;
      top: 91px;
    }
    &:nth-of-type(6) {
      width: 110px;
      height: 116px;
      right: 0;
      top: 66px;
    }
    &:nth-of-type(7) {
      width: 112px;
      height: 116px;
      bottom: 0;
      left: 0;
    }
    &:nth-of-type(8) {
      width: 88px;
      height: 92px;
      right: 85px;
      bottom: 0;
    }
    &:nth-of-type(9) {
      width: 109px;
      height: 116px;
      right: 0;
      bottom: 0;
    }
  }
  .backs {
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 20px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
  }
  .top-bg {
    width: 375px;
    height: 337.5px;
    background-image: url("~@/assets/img/jigsaw/jigsaw_main_bg.png");
    background-size: 100% 100%;
    margin-top: -1px;
  }
  .record-item {
    width: 85px;
    height: 32px;
    margin-left: 145px;
    img {
      width: 100%;
    }
  }

  .rule-tag {
    background: #e57249;
    height: 30px;
    color: #ffffff;
    font-size: 10px;
    line-height: 30px;
    font-weight: bold;
    border-radius: 15px 0 0 15px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 30px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    .van-icon {
      height: 16px;
      width: 16px;
    }
    div {
      margin-left: 3px;
      margin-top: 2px;
      display: block;
    }
  }
  .top-item {
    background: url("~@/assets/img/jigsaw/jigsaw_main_box.png");
    background-size: 100% 100%;
    width: 375px;
    height: 431.5px;
    position: relative;
    .comfirm {
      width: 152px;
      line-height: 38px;
      box-sizing: border-box;
      border-radius: 19px;
      border-bottom: 5.5px solid #fa922d;
      font-size: 16px;
      color: #ff2f14;
      position: absolute;
      bottom: 15px;
      left: 111.5px;
      text-align: center;
      background-image: linear-gradient(to bottom, #fff0d8, #fec868);
    }
  }
  .mid-item {
    background: url("~@/assets/img/jigsaw/jigsaw_main_list.png");
    background-size: 100% 100%;
    margin-left: 30px;
    margin-top: 20px;
    width: 315px;
    height: 95px;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 58px;
    box-sizing: border-box;
    .mid-item-list {
      display: flex;
      padding-bottom: 10px;
      justify-content: space-between;
      line-height: 16px;
      font-size: 16px;
      .name {
        color: #ff7435;
        flex: 1;
      }
      .op {
        color: #3565ff;
        width: 70px;
      }
    }
  }
  .grayscale {
    filter: grayscale(100%);
  }
}
</style>
