
import {
    get
} from '@/api/http'

export function growTaskInfo(params) {
    return get({
        url: "/grow_task/info",
        params,
    })
}

export function growTaskDraw(params) {
    return get({
        url: "/grow_task/draw",
        params,
    })
}

export function growTaskList(params) {
    return get({
        url: "/grow_task/list",
        params,
    })
}


export function growAccountInfo(params) {
    return get({
        url: "/grow_account/info",
        params,
    })
}

export function growAccountDraw(params) {
    return get({
        url: "/grow_account/draw",
        params,
    })
}
