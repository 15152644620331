export const routerAllowConfig = {
    allowTitlePath: ['training', 'achievement'], // 允许使用原生导航路由
    default: ['privacy_agreement', 'invite_login', 'newbie_guide', 'invite_down', 'reflectThat'], // 不使用原生导航
    navColorSelf: ['intro', 'form', 'rules', 'history'], // 活动自定义导航设置
    navColorSelf2: ['active', 'tiger'], // 活动自定义导航设置
    navColorSelf3: ['jigsawActive'],  //双十一首页
    navColorSelf4: ['jigsawRules', 'jigsawHistory', 'jigsawForm'],  //双十一其他
    colorList: {
        default: '#ffffff',
        navColorSelf: '#f9b467',
        navColorSelf2: '#fc673e',
        navColorSelf3: '#3453bc',
        navColorSelf4: '#f79e64'
    }
}

export const enums = {
    DEFAULT: 'default',
    NAV_COLOR_SELF: 'navColorSelf',
    NAV_COLOR_SELF2: 'navColorSelf2',
    NAV_COLOR_SELF3: 'navColorSelf3',
    NAV_COLOR_SELF4: 'navColorSelf4'
}

export const app_Fn = {
    IS_HIDDEN_TITLE: 'isHiddenTitle',
    CONFIG_BG_VIEW_COLOR: 'configBGViewColor'
}