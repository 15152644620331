<template>
  <div class="back_box">
    <div class="search_header">
      <mt-header title="邀请得红包">
        <router-link to="/invite_friends" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="face_box">
      <div class="box_div" style="width: 177px; height: 177px">
        <div ref="qrCodeDiv"></div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { URL } from "@/api/http";
export default {
  data() {
    return {
      invitation_code: "",
    };
  },
  created() {
    this.invitation_code = this.$route.query.invitation_code;
  },
  mounted() {
    this.bindQRCode();
  },
  methods: {
    bindQRCode() {
      new QRCode(this.$refs.qrCodeDiv, {
        text: URL+"invite_login?code=" + this.invitation_code,
        width: 163,
        height: 163,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
    },
  },
};
</script>
<style lang="less" scope>
.back_box {
  overflow: hidden;
}
.face_box {
  background: url(~@/assets/img/face_invite/bg_ma.png) #e12947 no-repeat;
  width: 100vw;
  text-align: center;
  margin: 1.2rem auto;
  position: relative;
  background-position: center top;
  height: 100%;
  background-size: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .box_div {
    display: flex;
    justify-content: center;
    margin-top: 195px;
    text-align: center;
    align-items: center;
    background: #fff;
  }
}
</style>
