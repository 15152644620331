// top 等级标
import account_level1_top from '@/assets/img/achievement2/account_level1_top.png'
import account_level2_top from '@/assets/img/achievement2/account_level2_top.png'
import account_level3_top from '@/assets/img/achievement2/account_level3_top.png'
export const account_level = [account_level1_top, account_level2_top, account_level3_top]

// 小图标
import icon5_0 from '@/assets/img/achievement/achievement_icon5_0.png'
import icon5_1 from '@/assets/img/achievement/achievement_icon5_1.png'
import icon5_2 from '@/assets/img/achievement/achievement_icon5_2.png'
import icon5_3 from '@/assets/img/achievement/achievement_icon5_3.png'
export const icon5 = [
    icon5_0, icon5_1, icon5_2, icon5_3
]
// 中图标
import icon5_0_mid from '@/assets/img/achievement/achievement_icon5_0_mid.png'
import icon5_1_mid from '@/assets/img/achievement/achievement_icon5_1_mid.png'
import icon5_2_mid from '@/assets/img/achievement/achievement_icon5_2_mid.png'
import icon5_3_mid from '@/assets/img/achievement/achievement_icon5_3_mid.png'
export const icon5_mid = [
    icon5_0_mid, icon5_1_mid, icon5_2_mid, icon5_3_mid
]
// 大图标
import icon5_0_big from '@/assets/img/achievement/achievement_icon5_0_big.png'
import icon5_1_big from '@/assets/img/achievement/achievement_icon5_1_big.png'
import icon5_2_big from '@/assets/img/achievement/achievement_icon5_2_big.png'
import icon5_3_big from '@/assets/img/achievement/achievement_icon5_3_big.png'
export const icon5_big = [
    icon5_0_big, icon5_1_big, icon5_2_big, icon5_3_big
]

// 账号类 大进度条样式
export const progress_Style = [
    '#B76D51', '#862802', '#43038F'
]

import icon7_0 from '@/assets/img/achievement/achievement_icon7_0.png'
import icon7_1 from '@/assets/img/achievement/achievement_icon7_1.png'
import icon7_2 from '@/assets/img/achievement/achievement_icon7_2.png'
import icon7_3 from '@/assets/img/achievement/achievement_icon7_3.png'
export const icon7 = [
    icon7_0, icon7_1, icon7_2, icon7_3
]


import icon8_0 from '@/assets/img/achievement/achievement_icon8_0.png'
import icon8_1 from '@/assets/img/achievement/achievement_icon8_1.png'
import icon8_2 from '@/assets/img/achievement/achievement_icon8_2.png'
import icon8_3 from '@/assets/img/achievement/achievement_icon8_3.png'
export const icon8 = [
    icon8_0, icon8_1, icon8_2, icon8_3
]


// 账号类
// 小图标
import account_1_1 from '@/assets/img/achievement2/achievement_icon2_1.png'
import account_1_2 from '@/assets/img/achievement2/achievement_icon2_2.png'
import account_1_3 from '@/assets/img/achievement2/achievement_icon2_3.png'
export const account_1 = [
    account_1_1, account_1_2, account_1_3
]

// 小图标
import account_2_1 from '@/assets/img/achievement2/achievement_icon2_1_.png'
import account_2_2 from '@/assets/img/achievement2/achievement_icon2_2_.png'
import account_2_3 from '@/assets/img/achievement2/achievement_icon2_3_.png'
export const account_2 = [
    account_2_1, account_2_2, account_2_3
]

export const progressMedal = [{
        percentage: 25,
        name: "任务小将",
    },
    {
        percentage: 50,
        name: "任务大师",
    },
    {
        percentage: 75,
        name: "任务宗师",
    },
    {
        percentage: 100,
        name: "任务王者",
    },
];

export const accountMedal = [{
        percentage: 33,
        name: "账号大师",
    },
    {
        percentage: 66,
        name: "账号宗师",
    },
    {
        percentage: 100,
        name: "账号王者",
    },
];


// 小图标
import weibo from '@/assets/img/achievement2/weibo.png'
import xiaohongshu from '@/assets/img/achievement2/xiaohongshu.png'
import douban from '@/assets/img/achievement2/douban.png'
import douyin from '@/assets/img/achievement2/douyin.png'
export const acountType = {
    1: weibo,
    20: douyin,
    23: xiaohongshu,
    40: douban
}
export const acountName = {
    1: "微博",
    20: "抖音",
    23: "小红书",
    40: "豆瓣"
}
