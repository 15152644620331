import Vue from 'vue'
import App from './App.vue'
import router from './router';
import 'lib-flexible/flexible';
import './libs/vant'
import 'mint-ui/lib/style.css';
import './global/global.less'
import 'swiper/swiper-bundle.css'
import MintUI from 'mint-ui';
import LuckDraw from 'vue-luck-draw'
import { store } from './store'
import {
  routerAllowConfig,
  enums,
  app_Fn
} from './config'
import qs from 'qs';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScroller from 'vue-scroller'
import vueWechatTitle from "vue-wechat-title";
import moment from 'moment' //导入文件
import LightTimeline from 'vue-light-timeline';
import {
  isIphone,
  isAndroid,
  callhandler
} from "@/libs/util";
import {
  Switch,
  CellSwipe,
  Button,
  Header,
  MessageBox,
  Spinner,
  Swipe,
  SwipeItem,
  Picker,
  Checklist,
  Field,
  Navbar,
  TabItem,
  Search,
  Indicator,
  Progress
} from 'mint-ui';



Vue.use(LuckDraw)
Vue.use(VueAwesomeSwiper)
Vue.use(vueWechatTitle);
Vue.use(VueScroller)
Vue.use(LightTimeline);
Vue.use(MintUI);
Vue.use(qs)
Vue.prototype.$moment = moment; //赋值使用
// 老版本 用的 mint ui 暂时不移除 新版本使用 vant 详见 libs/vant

Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$Indicator = Indicator;
Vue.prototype.$qs = qs;
Vue.prototype.$store = store
Vue.component(Progress.name, Progress, Switch.name, Switch, Search.name, Search, Navbar.name, Navbar, TabItem.name, TabItem, Checklist.name, Checklist, Field.name, Field, Picker.name, Picker, CellSwipe.name, Swipe.name, Swipe, SwipeItem.name, SwipeItem, CellSwipe, Button.name, Button, Spinner.name, Spinner, Header.name, Header);

Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {
  if (routerAllowConfig.allowTitlePath.indexOf(to.name) === -1) {
    let bgColor = null
    Object.keys(routerAllowConfig.colorList).forEach((item, i) => {
      if(routerAllowConfig[item].indexOf(to.name) > -1) bgColor = routerAllowConfig.colorList[enums[Object.keys(enums)[i]]]
    })
    if (isIphone) {
      if (routerAllowConfig.default.indexOf(to.name) === -1) {
        callhandler(app_Fn.IS_HIDDEN_TITLE); //是否隐藏ios的头部
        callhandler(app_Fn.CONFIG_BG_VIEW_COLOR, { //头部颜色
          backgroundColor: bgColor || routerAllowConfig.colorList[enums.DEFAULT]
        })
      }
    } else if (isAndroid) {
      if (routerAllowConfig.default.indexOf(to.name) === -1) {
        window.APP.configBGViewColor(
          bgColor || routerAllowConfig.colorList[enums.DEFAULT]
        )
      }
    }
  }

  next()
})
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')