import {
    setToken,
    setUid,
    isIphone,
    isAndroid,
    callhandler
} from "@/libs/util";

export default {
    methods: {
        getToken() {
            return new Promise((resolve) => {
                // setToken("109cVLBps1yjl16kGGaIY4BcZbDhAVr9l6C%2B46kchVeo");
                // setUid("2613");
                // setToken("380bD8DmAxgn70SPnyuvBIIEtKWZYZ2u%2B3gTdb8hFyVNYQ");
                // setUid("10136");
                // resolve()
                if (isIphone) {
                    callhandler("getToken", "", (data) => {
                        callhandler("getUserId", "", (uid) => {
                            this.uid = uid;
                            this.token = data;
                            setToken(this.token);
                            setUid(this.uid);
                            resolve()
                        });
                    });
                } else if (isAndroid) {
                    this.token = window.APP.getToken();
                    this.uid = window.APP.getUserId();
                    setToken(this.token);
                    setUid(this.uid);
                    resolve()
                }
            })


        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}