
import {
    get
} from '@/api/http'

export function flagRemarks(params) {
    return get({
        url: "/flag/remarks",
        params,
    })
}

export function flagLog(params) {
    return get({
        url: "/flag/log",
        params,
    })
}

export function flagSynthetic(params) {
    return get({
        url: "/flag/synthetic",
        params,
    })
}

export function flagIllume(params) {
    return get({
        url: "/flag/illume",
        params,
    })
}


export function flagInfo(params) {
    return get({
        url: "/flag/info",
        params,
    })
}


export function flagBoxInfo(params) {
    return get({
        url: "/flag/box_info",
        params,
    })
}


export function flagRand(params) {
    return get({
        url: "/flag/rand",
        params,
    })
}

export function flagCount(params) {
    return get({
        url: "/flag/count",
        params,
    })
}
