<template>
  <div class="invite">
    <div class="search_header" style="z-index: 2000">
      <mt-header title="邀请好友得红包">
        <div slot="left">
          <mt-button icon="back" @click="handleBack"></mt-button>
        </div>
      </mt-header>
    </div>
    <div style="padding-top: 46px">
      <!---第一部分---->
      <inviteOne :recommendInfo="recommendInfo"></inviteOne>
      <!---第二部分-->
      <inviteTwo :recommendInfo="recommendInfo"></inviteTwo>
      <!---第三部分-->
      <inviteThree></inviteThree>
      <!---第四部分-->
      <inviteFour :recommendInfo="recommendInfo"></inviteFour>
      <!---第七部分-->
      <inviteSeven :recommendInfo="recommendInfo"></inviteSeven>
      <!---第五部分-->
      <inviteFive></inviteFive>
      <!---第六部分-->
      <inviteSix :dataList="dataList" :count="count" @updata="fetchData"></inviteSix>
    </div>
    <div
      class="back_sixe"
      style="
        position: fixed;
        bottom: -50px;
        width: 100vw;
        background: #e12947;
        z-index: 1002;
      "
      @click="handleImmediately"
    >
      <img
        src="~@/assets/img/invite_friends/botton_xidi.png"
        class="img_sixe"
      />
    </div>
  </div>
</template>
<script>
import {
  isIphone,
  isAndroid,
  setToken,
  setUid,
  callhandler,
} from "@/libs/util";

import { getRecommendinfo, getRanking } from "@/api/components/recommend";
import { URL } from "@/api/http";
export default {
  data() {
    return {
      recommendInfo: {},
      dataList: null,
      count: "0",
    };
  },
  created() {
    if (isIphone) {
      callhandler("getToken", "", (data) => {
        callhandler("getUserId", "", (uid) => {
          this.uid = uid;
          this.token = data;
          setToken(this.token);
          setUid(this.uid);
          if (this.token && this.uid) {
            this.handleRecommendinfo();
          }
        });
      });
    } else if (isAndroid) {
      this.token = window.APP.getToken();
      this.uid = window.APP.getUserId();
      setToken(this.token);
      setUid(this.uid);
      if (this.token && this.uid) {
        this.handleRecommendinfo();
      }
    }
    // this.setBaseToken()
  },
  methods: {
    setBaseToken() {
      setToken("d7e30VvP0iPyN1HF4XbTmFqYxSi2t4QMdvj4DqGUG9Yl");
      setUid("2613");
      this.handleRecommendinfo();
    },
    handleImmediately() {
      if (isIphone) {
        callhandler("share", {
          //头部颜色
          title: "小v招新",
          introduction: "",
          imageUrl: "",
          url: URL + "invite_login?code=" + this.recommendInfo.code,
        });
      } else {
        window.APP.share(
          "小v招新",
          "",
          "",
          URL + "invite_login?code=" + this.recommendInfo.code
        );
      }
    },
    handleBack() {
      if (isIphone) {
        callhandler("finish", function () {});
      } else if (isAndroid) {
        window.APP.finish();
      }
    },
    handleRecommendinfo() {
      getRecommendinfo().then((res) => {
        let { data } = res.data;
        this.recommendInfo = data;
        let dataObj = Object.assign({}, this.recommendInfo);
        this.recommendInfo.num =
          dataObj.number == 0 ? dataObj.number : dataObj.number + 1;
      });
      this.getData(1)
    },
    fetchData(e) {
      this.getData(e)
    },
    getData(e) {
      getRanking({
        time: e,
      }).then((res) => {
        let arr = [];
        if (res.data.data.list.length < 3) {
          const params = {
            headpic: "http://pic.tommmd.com/smallv-user.png",
            mobile: "",
            realname: "虚位以待",
            total: 0,
            success: null,
          };
          arr = new Array(3 - res.data.data.list.length).fill(params);
        }
        this.dataList = res.data.data.list.concat(arr);
        this.count = res.data.data.total;
      });
    },
  },
  components: {
    inviteOne: () => import("./invite_one"),
    inviteTwo: () => import("./invite_two"),
    inviteThree: () => import("./invite_three"),
    inviteFour: () => import("./invite_four"),
    inviteFive: () => import("./invite_five"),
    inviteSix: () => import("./invite_six"),
    inviteSeven: () => import("./invite_seven"),
  },
};
</script>
<style lang="less">
.invite {
  padding-bottom: 100px;
  background: #e12947;
}
.back_one {
  background: url(~@/assets/img/invite_friends/img_zhu.png) no-repeat;
  width: 100vw;
  height: 507px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-position: center top;
  .rules_div {
    padding-top: 28px;
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    .img_guize {
      width: 78px;
      height: 27px;
    }
  }
  .one_div {
    padding: 0 20px;
    padding-top: 330px;
    display: flex;
    .one_half {
      width: 50vw;
      .img_half {
        width: 149.1px;
      }
    }
  }
}
.back_two {
  padding: 0 0.4rem 0;
  margin-top: -24px;
  position: relative;
  .img {
    width: 345px;
  }
  .rules_div {
    position: absolute;
    top: 24px;
    right: 15px;
    .img_mingxi {
      width: 81.5px;
    }
  }
  .two_div {
    display: flex;
    padding: 35px 20px;
    top: 40px;
    position: absolute;
    width: 82%;
    .two_half {
      text-align: center;
      width: 50%;
    }
  }
}
.back_three {
  padding: 5px 15px 0;
  .img_three {
    width: 345px;
  }
}

.back_four {
  padding: 5px 0.4rem 0;
  position: relative;
  .img_four {
    width: 345px;
  }
  .four_div {
    position: absolute;
    top: 65px;
    display: flex;
    flex-wrap: wrap;
    width: 92vw;
    align-items: flex-end;
    .four_wid:last-child {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .img_four {
        width: 149px;
        display: block;
      }
      .four_box {
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 1000;
        top: 0;
        .box_top {
          padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff3e43;
        }
        .box_top1 {
          padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fffcea;
        }
        .box_top2 {
          padding-top: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff3e43;
        }

        .box_span1 {
          font-size: 36px;
          font-weight: bold;
        }
        .box_span2 {
          font-size: 18px;
        }
      }
      .span_receive1 {
        color: #fffcea;
        font-size: 18px;
        padding-top: 45px;
        font-weight: bold;
      }
      .span_receive {
        color: #fffcea;
        font-size: 18px;
        font-weight: bold;
        padding-top: 65px;
      }
      .four_top {
        padding-top: 10px;
      }
      .four_btn {
        background: #c70004;
        border: none;
        color: #ff9696;
        border-radius: 30px;
        font-size: 16px;
        font-weight: bold;
        padding: 5px 20px;
        outline: none;
      }
      .four_btn1 {
        background: #ffdc98;
        border: none;
        color: #ff3f43;
        border-radius: 30px;
        font-size: 16px;
        font-weight: bold;
        padding: 5px 20px;
        outline: none;
      }
    }
    .four_wid {
      width: 33.3%;
      position: relative;
      .four_box {
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 1000;
        top: 0;
        .box_top {
          padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff3e43;
        }
        .box_top1 {
          padding-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fffcea;
        }
        .box_top2 {
          padding-top: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff3e43;
        }
        .box_span1 {
          font-size: 24px;
          font-weight: bold;
        }
        .box_span2 {
          font-size: 12px;
        }
      }
      .span_receive1 {
        color: #fffcea;
        font-size: 12px;
        font-weight: bold;
        padding-top: 20px;
      }
      .span_receive {
        color: #fffcea;
        font-size: 12px;
        font-weight: bold;
        padding-top: 40px;
      }
      .four_top {
        padding-top: 8px;
      }
      .four_btn {
        background: #c70004;
        border: none;
        color: #ff9696;
        border-radius: 30px;
        font-size: 11px;
        font-weight: bold;
        padding: 0px 10px;
        outline: none;
      }
      .four_btn1 {
        background: #ffdc98;
        border: none;
        color: #ff3f43;
        border-radius: 30px;
        font-weight: bold;
        font-size: 11px;
        padding: 0px 10px;
        outline: none;
      }
    }
    .img_four {
      width: 92.5px;
      display: block;
    }
  }
}
.back_five {
  padding: 10px 15px 0;
  .img_three {
    width: 345px;
  }
}
.back_sixe {
  padding: 15px 15px 70px;
  .img_sixe {
    width: 345px;
  }
}
</style>
