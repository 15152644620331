import imgSrc0 from '@/assets/img/active/0.png'
import imgSrc1 from '@/assets/img/active/1.png'
import imgSrc2 from '@/assets/img/active/2.png'
import imgSrc3 from '@/assets/img/active/3.png'
import imgSrc4 from '@/assets/img/active/4.png'
import imgSrc5 from '@/assets/img/active/5.png'
import imgSrc6 from '@/assets/img/active/6.png'

import icon0 from '@/assets/img/active/city0.png'
import icon1 from '@/assets/img/active/city1.png'
import icon2 from '@/assets/img/active/city2.png'
import icon3 from '@/assets/img/active/city3.png'
import icon4 from '@/assets/img/active/city4.png'
import icon5 from '@/assets/img/active/city5.png'
import icon6 from '@/assets/img/active/city6.png'

import tiger0 from '@/assets/img/active/tiger0.png'
import tiger1 from '@/assets/img/active/tiger1.png'
import tiger2 from '@/assets/img/active/tiger2.png'
import tiger3 from '@/assets/img/active/tiger3.png'
import tiger4 from '@/assets/img/active/tiger4.png'

import detail0 from '@/assets/img/active/active_tiger_detail_icon1.png'
import detail1 from '@/assets/img/active/active_tiger_detail_icon2.png'
import detail2 from '@/assets/img/active/active_tiger_detail_icon3.png'
import detail3 from '@/assets/img/active/active_tiger_detail_icon4.png'
import detail4 from '@/assets/img/active/active_tiger_detail_icon5.png'
import detail5 from '@/assets/img/active/active_tiger_detail_icon6.png'


export const areaDatas = [{
        name: '北京-八达岭长城',
        city: '北京',
        image: imgSrc0,
        icon: icon0,
        intro: [
            '八达岭长城，位于北京市延庆区军都山关沟古道北口。是中国古代伟大的防御工程万里长城的重要组成部分，是明长城的一个隘口。八达岭长城为居庸关的重要前哨，古称“居庸之险不在关而在八达岭”',
            '明长城的八达岭段被称作“玉关天堑”，为明代居庸关八景之一。八达岭长城是明长城向游人开放最早的地段，八达岭景区以八达岭长城为主，兴建了八达岭饭店和由江泽民主席亲笔题名的中国长城博物馆等功能齐全的现代化旅游服务设施。',
            '八达岭景区是全国文明风景旅游区示范点，以其宏伟的景观、完善的设施和深厚的文化历史内涵而著称于世，是举世闻名的旅游胜地。---来源百度百科'
        ]
    },
    {
        name: '上海-东方明珠塔',
        city: '上海',
        image: imgSrc1,
        icon: icon1,
        intro: [
            '东方明珠广播电视塔，简称“东方明珠”，位于上海市浦东新区陆家嘴世纪大道1号，地处黄浦江畔，背拥陆家嘴地区现代化建筑楼群，与隔江的外滩万国建筑博览群交相辉映，始建于1994年10月1日，是集都市观光、时尚餐饮、购物娱乐、历史陈列、浦江游览、会展演出、广播电视发射等多功能于一体的上海市标志性建筑之一，为亚洲第一高塔、世界第三高塔。',
            '东方明珠广播电视塔主体为多筒结构，由3根斜撑、3根立柱及广场、塔座、下球体、5个小球体、上球体、太空舱、发射天线桅杆等构成，总高468米，总建筑面积达10万平方米。',
            '1995年，东方明珠广播电视塔被评为上海十大新景观之一。---来源百度百科'
        ]
    },
    {
        name: '杭州-西湖',
        city: '杭州',
        image: imgSrc2,
        icon: icon2,
        intro: [
            '西湖，位于浙江省杭州市西湖区龙井路1号，杭州市区西部，景区总面积49平方千米，汇水面积为21.22平方千米，湖面面积为6.38平方千米。',
            '西湖南、西、北三面环山，湖中白堤、苏堤、杨公堤、赵公堤将湖面分割成若干水面。西湖的湖体轮廓呈近椭圆形，湖底部较为平坦，湖泊平均水深为2.27米，最深约5米，最浅不到1米。湖泊天然地表水源是金沙涧、龙泓涧、赤山涧（慧因涧）、长桥溪四条溪流。西湖地处中国东南丘陵边缘和中亚热带北缘，年均太阳总辐射量在100—110千卡/平方厘米之间，日照时数1800—2100小时。 ',
            '西湖有100多处公园景点，有“西湖十景”、“新西湖十景”、“三评西湖十景”之说，有60多处国家、省、市级重点文物保护单位和20多座博物馆，有断桥、雷峰塔、钱王祠、净慈寺、苏小小墓等景点。',
            '2007年，杭州市西湖风景名胜区被评为“国家AAAAA级旅游景区”。2011年6月24日，“杭州西湖文化景观”正式被列入《世界遗产名录》。 ---来源百度百科'
        ]
    },
    {
        name: '福建-土楼',
        city: '福建',
        image: imgSrc3,
        icon: icon3,
        intro: [
            '土楼，是利用未经焙烧的按一定比例的沙质黏土和黏质沙土拌合而成的泥土，以夹墙板夯筑而成墙体（少数以土坯砖砌墙）、柱梁等构架全部采用木料的楼屋，简言之，就是以生土版筑墙作为承重系统的任何两层以上的房屋。根据《现代汉浯词典》的解释，所谓“楼”，就是“两层或两层以上的房子”。据此，一层的房子，即使以生土墙承重、以木料作为柱梁等构架，也不能称为土楼，只能称为土屋。土楼是世界独一无二的大型民居形式，被称为中国传统民居的瑰宝。',
            '土楼以生土为主要建筑材料、生土与木结构相结合，并不同程度地使用石材的大型民居建筑。它们是几次中国乃至东亚历史动荡和民众大迁徙的产物。其中分布最广、数量最多、品类最丰富、保存最完好的，是福建土楼。',
            '土楼已被严格确认的福建土楼建筑有3000余座，主要分布在福建省龙岩永定县、福建省漳州南靖县和华安县。土楼的兴建高潮是客家民系在中国动乱由中原向南方迁移之际。这些时期包含唐末黄巢之乱、南宋政权南移与明末清初。直至17世纪之后，不但让客家人最后定居于中国东南沿海，也让土楼分布地点以中国闽粤地区为大宗。出于抵御山林野兽、强盗的需要，并体现儒家思想下大家族共同生活的理想，乃建造此种形式特殊的建筑的。---来源百度百科'
        ]
    },
    {
        name: '广西-桂林',
        city: '广西',
        image: imgSrc4,
        icon: icon4,
        intro: [
            '桂林，简称桂，是世界著名风景游览城市、万年智慧圣地、全国重要高新技术产业基地，中国老工业基地 ，是国务院批复确定的中国对外开放国际旅游城市、全国旅游创新发展先行区和国际旅游综合交通枢纽，截至2019年，全市下辖6区10县、代管1个县级市、总面积2.78万平方公里，建成区面积162平方千米。根据第七次人口普查数据，截至2020年11月1日零时，桂林市常住人口为493万人。 ',
            '桂林地处中国华南，湘桂走廊南端，是中央军委桂林联勤保障中心驻地、国家可持续发展议程创新示范区、 中国旅游业态风向标，联合国世界旅游组织/亚太旅游协会旅游趋势与展望国际论坛永久举办地，是泛珠江三角洲经济区与东盟自由贸易区战略交汇的重要节点城市，是以新型工业为主的国际旅游胜地。',
            '桂林是首批国家历史文化名城，秦始皇统一岭南后属桂林郡。1201年，著名诗人王正功赋诗“桂林山水甲天下”。甑皮岩文化是史前中国多元一体进程的文化源流之一  ，甑皮岩发现的陶雏器填补世界陶器起源空白，是中国制陶技术重要的起源地之一。---来源百度百科'
        ]
    },
    {
        name: '云南-玉龙雪山',
        city: '云南',
        image: imgSrc5,
        icon: icon5,
        intro: [
            '玉龙雪山为云南省丽江市境内雪山群，东界丽（江）鸣（音）公路，西临虎跳峡涧、南起玉湖，北至大具下虎跳峡口，地处东经100°4′2″-100°16′30″、北纬27°3′2″～27°18′57″之间，在丽江北面约15千米处，全长75千米，是北半球最近赤道终年积雪的山脉，隔江西与中甸雪山、东与绵绵山相并列，北自三江口，向南延伸到北纬27°，如扇面向古城展开。全山的13座山峰由南向北纵向排列，主峰扇子陡最高处海拔5596米，终年积雪，雪线高度介于4800～5000米之间。发育有亚欧大陆距离赤道最近的温带海洋性冰川。',
            '玉龙雪山在纳西语中被称为“欧鲁”，意为“天山”。其十三座雪峰连绵不绝，宛若一条“巨龙”腾越飞舞，故称为“玉龙”。又因其岩性主要为石灰岩与玄武岩，黑白分明，所以又称为“黑白雪山”。是纳西人的神山，传说纳西族保护神“三朵”的化身。---来源百度百科',
        ]
    },
    {
        name: '西藏-布达拉宫',
        city: '西藏',
        image: imgSrc6,
        icon: icon6,
        intro: [
            '布达拉宫位于中国西藏自治区首府拉萨市区西北的玛布日山上，是一座宫堡式建筑群，最初是吐蕃王朝赞普松赞干布为迎娶文成公主而兴建。于17世纪重建后，成为历代达赖喇嘛的冬宫居所，为西藏政教合一的统治中心。1961年，布达拉宫成为了中华人民共和国国务院第一批全国重点文物保护单位之一。1994年，布达拉宫被列为世界文化遗产。布达拉宫的主体建筑为白宫和红宫两部分。',
            '整座宫殿具有藏式风格，高200余米，外观13层，实际只有9层。由于它起建于山腰，大面积的石壁又屹立如削壁，使建筑仿佛与山岗融为一体，气势雄伟。---来源百度百科',
        ]
    }
]

export const activeFlag = {
    SYN: 'synthetic',
    ILL: 'illume'
}

export const dialogTip = {
    synthetic: '是否合成大红旗',
    illume: '是否点亮地标'
}

export const tigerData = [{
        name: 'manghe',
        image: tiger4
    },
    {
        name: 'weibo',
        image: tiger3
    },
    {
        name: 'hongbao',
        image: tiger0
    },
    {
        name: 'mangguo',
        image: tiger1
    },
    {
        name: 'jifen',
        image: tiger2
    }
]

export const tigerDetailData = [{
        name: '101元红包',
        image: detail0
    },
    {
        name: '1010积分',
        image: detail1
    },
    {
        name: '微博会员12个月',
        image: detail2
    },
    {
        name: '双人电影票',
        image: detail3
    },
    {
        name: '芒果TV季度会员',
        image: detail4
    },
    {
        name: '更多好礼等你来得',
        image: detail5
    }
]