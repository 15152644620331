<template lang="pug">
van-popup(v-model="isShow", @closed="close")
  .dialog-small2(:class="isShow ? 'animate' : ''")
    img.dialog-icon1(
      src="~@/assets/img/achievement2/achievement_level_info.png"
    )
    .dialog-icon5(v-if="stage")
      img(:src="account_level[stage.stage-1]")
    .dialog-icon3(v-if="stage")
      div(v-for="item in list", :key="item.id") {{ acountName[item.account_type] }}:{{ item.now }}/{{ item.list[stage.stage-1].number }}
    .dialog-icon2(@click="submitFlag")
      img(src="~@/assets/img/achievement/achievement_icon11.png")
    .dialog-icon4(v-if="stage")
      div {{ stage.name}}
</template>
<script>
import { account_level, acountName } from "../../data";
import _ from "lodash";
export default {
  props: ["show", "levelInfo", "levelIndex", "list", 'stage'],
  data() {
    return {
      account_level,
      acountName,
      isShow: false,
    };
  },
  watch: {
    show(newData) {
      this.isShow = newData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitFlag: _.debounce(async function () {
      this.$emit("close");
    }, 250),
  },
};
</script>
<style lang="less" scoped>
.van-popup {
  background: none;
  overflow: visible;

  .dialog-small2 {
    position: relative;
    .dialog-icon1 {
      height: 354px;
      width: 289px;
      position: relative;
      z-index: 1;
    }
    .dialog-icon2 {
      height: 42px;
      width: 172px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 290px;
      margin-left: -86px;
      img {
        width: 100%;
      }
    }
    .dialog-icon3 {
      padding: 0 40px;
      box-sizing: border-box;
      width: 100%;
      position: absolute;
      left: 0;
      top: 160px;
      z-index: 2;
      font-size: 15px;
      color: #F37343;
      line-height: 40px;
      font-weight: bold;
      div {
        float: left;
        width: 50%;
        text-align: center;
      }
    }
    .dialog-icon4 {
      position: absolute;
      top: 100px;
      z-index: 2;
      width: 100%;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
    }
    .dialog-icon5 {
      position: absolute;
      top: -40px;
      z-index: 2;
      height: 146px;
      width: 100%;
      text-align: center;
      img {
        height: 100%;
      }
    }
  }
}
.animate {
  animation: flipInY 1s ease-in-out;
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
</style>
